import * as React from "react"

function SvgHomeIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 48 48"
      width="1em"
      height="1em"
      {...props}
    >
      <path
        d="M14.3 34.5v-16l10.2-8 9.8 8v16h-20z"
        fill="none"
        stroke="currentColor"
        strokeWidth={2.5}
        strokeMiterlimit={10}
      />
      <path
        d="M45.5 2.5v43h-43v-43h43M48 0H0v48h48V0z"
        stroke="currentColor"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgHomeIcon
