import React from "react"
import { withStyles, WithStyles, createStyles, Theme } from "@material-ui/core"

import { Status } from "../../entities/Status"
import LockIcon from "../../../common/components/icons/LockIcon"
import TickIcon from "../../../common/components/icons/TickIcon"
import PlayRoundIcon from "../../../common/components/icons/PlayRoundIcon"
import clsx from "clsx"

const styles = (theme: Theme) =>
  createStyles<ClassKey, {}>({
    thumbnail: {
      position: "relative",
      width: "100%",
      height: "100%",
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      transition: "all 0.3s",
      "&:hover": {
        shadow: "lg",
        cursor: "pointer",
        transform: "scale(1.05)",
      },
    },
    image: {
      position: "relative",
      maxHeight: "100%",
      width: "100%",
    },
    numb: {
      position: "absolute",
      fontFamily: "Tourney",
      color: theme.palette.primary.main,
      fontSize: "2.5rem",
      fontWeight: "bold",
      top: "-0.5rem",
      right: "1rem",
      zIndex: 1,
    },
    icon: {
      color: theme.palette.primary.main,
      position: "absolute",
      fontSize: "4rem",
    },
    disabled: {
      "&> *": {
        cursor: "default",
      },
      "&:hover": {
        cursor: "default",
        transform: "none",
        shadow: "none",
      },
    },
  })

type ClassKey = "thumbnail" | "numb" | "image" | "icon" | "disabled"

type IProps = {
  id: string
  status: Status
  image: string
}
type PropsType = IProps & WithStyles<ClassKey>

const VideoCard: React.FC<PropsType> = (props: PropsType) => {
  const { id, status, classes, image } = props
  return (
    <>
      <div
        className={clsx(
          classes.thumbnail,
          status !== "current" && classes.disabled,
        )}
      >
        <div className={classes.numb}>{id.substring(1)}</div>

        <img src={image} alt={id} className={classes.image}></img>
        {status === "current" ? (
          <PlayRoundIcon className={classes.icon} color="primary" />
        ) : status === "watched" ? (
          <TickIcon className={classes.icon} color="primary" />
        ) : (
          <LockIcon className={classes.icon} color="primary" />
        )}
      </div>
    </>
  )
}

export default withStyles(styles)(VideoCard)
