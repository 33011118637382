import { configureStore } from "@reduxjs/toolkit"
import UserSlice from "./user/UserSlice"
import LibrarySlice from "./library/LibrarySlice"
import GamesSlice from "./games/GameSlice"

const store = configureStore({
  reducer: {
    userState: UserSlice,
    libraryState: LibrarySlice,
    gamesState: GamesSlice,
  },
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {user: UserState}
export type AppDispatch = typeof store.dispatch

export default store
