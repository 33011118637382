import React, { useLayoutEffect, useRef } from "react"
import { withStyles, WithStyles, createStyles, Theme } from "@material-ui/core"
import pathStepStyle, {
  ClassKey as pathStepClassKey,
} from "./styles/pathStepStyle"
import {
  drawFutureInterview,
  drawLink,
  getDrawingNode,
  haloOffset,
  verticalSpacing,
} from "./utils/Draw"
import clsx from "clsx"

const styles = (theme: Theme) =>
  createStyles<ClassKey, {}>({
    passive: {
      cursor: "auto",
      "&:hover": {
        background: "none",
      },
    },
  })

type ClassKey = "passive"
interface IProps {
  idx: number
}
type PropsType = IProps & WithStyles<ClassKey | pathStepClassKey>

const FutureStep: React.FC<PropsType> = (props) => {
  const { classes, idx } = props
  const canvasRef = useRef<HTMLCanvasElement>(null)
  const node = getDrawingNode(idx)

  useLayoutEffect(() => {
    const draw = (ctx) => {
      const node = getDrawingNode(idx)
      ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height)
      drawLink(ctx, node, getDrawingNode(idx - 1), getDrawingNode(idx + 1))
      drawFutureInterview(ctx, node)
      // ctx.scale(window.devicePixelRatio, window.devicePixelRatio)
    }

    const canvas = canvasRef.current
    if (canvas !== null) {
      console.log("drawing")
      const ctx = canvas!.getContext("2d")!
      draw(ctx)
    }
  }, [idx])

  const getWidth = () =>
    node.x + node.r + haloOffset + window.devicePixelRatio + 10

  return (
    <div className={clsx(classes.root, classes.passive)}>
      <canvas
        ref={canvasRef}
        className={classes.drawing}
        style={{ width: getWidth() }}
        width={getWidth()}
        height={verticalSpacing}
      ></canvas>
    </div>
  )
}

export default withStyles((theme) => ({
  ...pathStepStyle(theme),
  ...styles(theme),
}))(FutureStep)
