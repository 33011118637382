import React from "react"
import { withStyles, WithStyles, createStyles, Theme } from "@material-ui/core"
import Unity, { UnityContent } from "react-unity-webgl"
import MobileDeadEnd from "../../fastTrack/components/MobileDeadEnd"
import { useHistory } from "react-router"

const styles = (theme: Theme) =>
  createStyles<ClassKey, {}>({
    root: {},
  })

type ClassKey = "root"
interface IProps {}
type PropsType = IProps & WithStyles<ClassKey>

const CoronaInvaders: React.FC<PropsType> = (props) => {
  const history = useHistory()
  const unityContent = new UnityContent(
    "games/corona-invaders/test.json",
    "games/corona-invaders/UnityLoader.js",
  )
  const isDesktop = window.innerWidth > 720
  console.log(isDesktop, window.innerWidth)

  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        backgroundColor: "black",
        position: "relative",
      }}
    >
      {isDesktop && (
        <div
          style={{
            width: "1024px",
            height: "768px",
            position: "absolute",
            top: "50vh",
            left: "50vw",
            transform: "translate(-50%, -50%)",
          }}
        >
          <Unity unityContent={unityContent} />
        </div>
      )}
      {!isDesktop && (
        <MobileDeadEnd
          onClose={() => history.push("/book-club")}
          overrideText="Corona Invasion game is not avaialbe on mobile yet. Please come back to this page from your desktop computer."
        />
      )}
    </div>
  )
}

export default withStyles(styles)(CoronaInvaders)
