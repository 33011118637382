import axios from "axios"
import config from "../../common/config"
import { Feedback } from "../entities/Feedback"

interface FeedbackResponse {
  Items: Feedback[]
}
export const getFeedback: () => Promise<FeedbackResponse> = async () => {
  const response = await axios.get<FeedbackResponse>(`${config.api}/feedback/`)
  return response.data
}
