export const TETROMINOS = {
  0: { shape: [[0]], color: "rgb(62,62,62)" },
  I: {
    shape: [
      [0, "I", 0, 0],
      [0, "I", 0, 0],
      [0, "I", 0, 0],
      [0, "I", 0, 0],
    ],
    color: "rgb(148, 6, 6)",
  },
  J: {
    shape: [
      [0, "J", 0],
      [0, "J", 0],
      ["J", "J", 0],
    ],
    color: "rgb(168, 46, 36)",
  },
  L: {
    shape: [
      [0, "L", 0],
      [0, "L", 0],
      [0, "L", "L"],
    ],
    color: "rgb(186, 73, 63)",
  },
  O: {
    shape: [
      ["O", "O"],
      ["O", "O"],
    ],
    color: "rgb(202, 99, 91)",
  },
  S: {
    shape: [
      [0, "S", "S"],
      ["S", "S", 0],
      [0, 0, 0],
    ],
    color: "rgb(217, 125, 119)",
  },
  T: {
    shape: [
      ["T", "T", "T"],
      [0, "T", 0],
      [0, 0, 0],
    ],
    color: "rgb(231, 151, 147)",
  },
  Z: {
    shape: [
      ["Z", "Z", 0],
      [0, "Z", "Z"],
      [0, 0, 0],
    ],
    color: "rgb(255, 203, 203)",
  },
}

export const randomTetromino = () => {
  const tetrominos = "IJLOSTZ"
  const randTetromino =
    tetrominos[Math.floor(Math.random() * tetrominos.length)]
  return TETROMINOS[randTetromino]
}
