import React, { useState, useEffect } from "react"
import { withStyles, WithStyles, createStyles, Theme } from "@material-ui/core"
import { RouteComponentProps, useHistory } from "react-router"

// Components
import BurstPlayer from "./BurstPlayer"
import TrackPlayer, {
  ReactPlayerProgress,
} from "../../../common/components/TrackPlayer/TrackPlayer"
import InterviewFeedback from "../SubmitFeedback/InterviewFeedback"
import ModalBox from "../../../common/components/ModalBox"

// Interfaces
import { IInterview } from "../../../redux/interfaces"

// State
import { useAppDispatch, useAppSelector } from "../../../redux/hooks"
import {
  refreshProgress,
  progressInterview,
} from "../../../redux/library/LibrarySlice"
import { Progress } from "../../../common/entities/Progress"

let styles = (theme: Theme) =>
  createStyles<ClassKey, {}>({
    root: {
      color: theme.palette.primary.main,
      background: "none",
      width: "100%",
      height: "100%",
      display: "flex",
      flexDirection: "column",
    },
  })

type ClassKey = "root"

type IMatchProps = {
  interviewId: string
}

type IProps = {}

type PropsType = RouteComponentProps<IMatchProps> &
  IProps &
  WithStyles<ClassKey>

type OverlayState = "feedback" | "burst" | "interview"

const Player: React.FC<PropsType> = (props: PropsType) => {
  const { classes } = props
  const interviewId = props.match.params.interviewId
  const dispatch = useAppDispatch()
  const [overlay, setOverlay] = useState<OverlayState>("interview")
  const history = useHistory()
  const [playing, setPlaying] = useState(false)
  const [thisProgress, setThisProgress] = useState<Progress>()

  useEffect(() => {
    dispatch(refreshProgress())
      .unwrap()
      .then((progressList) => {
        // Check if this is the first time this interview was selected
        const progressQuery: Progress | undefined = progressList.find(
          (p) => p.mediaId === interviewId,
        )
        if (progressQuery === undefined || progressQuery.order === undefined) {
          console.log("first time", interviewId, progressList)
          const newProgress: Progress = {
            mediaId: interviewId,
            progress: 0,
            order: progressList.length,
          }
          setThisProgress(newProgress)
          dispatch(progressInterview(newProgress))
        } else if (progressQuery) {
          setThisProgress(progressQuery)
        }
      })
  }, [dispatch, interviewId, thisProgress])

  const onProgress = (progress: ReactPlayerProgress) => {
    if (thisProgress && progress.played > interview.progress) {
      dispatch(
        progressInterview({
          mediaId: interview.id,
          progress: Math.max(progress.played, interview.progress),
          order: thisProgress.order,
        }),
      )
    }
  }

  useEffect(() => {
    document.title = `AGAM21 | Experiential | Audio | ${interviewId}`
  }, [interviewId, overlay])

  const interview: IInterview = useAppSelector(
    (store) =>
      store.libraryState.interviews.find((t) => t.id === interviewId) ||
      store.libraryState.interviews[0],
  )

  return (
    <div className={classes.root}>
      {interview.order !== undefined && (
        <TrackPlayer
          title={"AGAM21"}
          playing={playing}
          setPlaying={setPlaying}
          onProgress={onProgress}
          interview={interview}
          closeIconOnClick={() => history.push("/experiential")}
          actions={[
            {
              label: "Watch",
              onClick: () => {
                setPlaying(false)
                setOverlay("burst")
              },
            },
            { label: "feedback", onClick: () => setOverlay("feedback") },
          ]}
        />
      )}
      <ModalBox
        title={interview.title}
        subtitle={interview.author}
        open={overlay === "burst"}
        onClose={() => setOverlay("interview")}
      >
        {interview.order !== undefined && <BurstPlayer content={interview} />}
      </ModalBox>
      <ModalBox
        title={interview.title}
        subtitle={interview.author}
        open={overlay === "feedback"}
        onClose={() => setOverlay("interview")}
      >
        <InterviewFeedback
          mediaId={interview.id}
          onClose={() => setOverlay("interview")}
        />
      </ModalBox>
    </div>
  )
}

export default withStyles(styles)(Player)
