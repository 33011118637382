import React, { useState, useEffect, useCallback } from "react"
import {
  withStyles,
  WithStyles,
  createStyles,
  Theme,
  Button,
} from "@material-ui/core"
import KeyboardEventHandler from "react-keyboard-event-handler"

const styles = (theme: Theme) =>
  createStyles<ClassKey, {}>({
    root: {
      display: "flex",
      flexDirection: "column",
      fontFamily: "'Press Start 2P'",
      "& span": {
        fontFamily: "'Press Start 2P'",
      },
      alignItems: "center",
      background: "#111",
      border: `1px solid ${theme.palette.primary.main}`,
      padding: "2rem",
    },
    buttons: {
      display: "flex",
      justifyContent: "space-around",
      "& > *": {
        margin: "1em",
      },
    },
    message: {
      textAlign: "center",
      marginBottom: "1rem",
    },
  })

type ClassKey = "root" | "buttons" | "message"

interface IAction {
  label: string
  onClick: () => void
}

interface IProps {
  message: string
  actions: IAction[]
}

type PropsType = IProps & WithStyles<ClassKey>

const GameMessageBox = React.forwardRef<any, PropsType>(
  (props, forwardedRef) => {
    const { actions, message, classes } = props
    const [focusId, setFocusId] = useState(0)

    const handleKeyPress = useCallback(
      (key) => {
        const mod = (n, m) => ((n % m) + m) % m
        if (key === "left") {
          setFocusId((f) => mod(f - 1, actions.length))
        } else if (key === "right") {
          setFocusId((f) => mod(f + 1, actions.length))
        }
      },
      [actions.length],
    )

    useEffect(
      () => document.getElementById(`dialogAction${focusId}`)?.focus(),
      [focusId],
    )

    return (
      <div className={classes.root} ref={forwardedRef}>
        <KeyboardEventHandler
          handleKeys={["left", "right"]}
          onKeyEvent={(key, _) => handleKeyPress(key)}
          isExclusive={true}
        >
          <div className={classes.message}>{message}</div>
          <div className={classes.buttons}>
            {actions.map((a, i) => (
              <Button
                key={`dialogAction${i}`}
                id={`dialogAction${i}`}
                variant="outlined"
                color="primary"
                onClick={a.onClick}
                autoFocus={!i}
              >
                {a.label}
              </Button>
            ))}
          </div>
        </KeyboardEventHandler>
      </div>
    )
  },
)
GameMessageBox.displayName = "GameMessageBox"

export default withStyles(styles)(GameMessageBox)
