import i18next from "i18next"
import { initReactI18next } from "react-i18next"
import LanguageDetector from "i18next-browser-languagedetector"

const locales = ["nl-NL", "en-US"]
export const supportedLanguages = locales

const localeResources = {
  "en-US": {
    common: require("./locales/en-US/common.json"),
  },
  "nl-NL": {
    common: require("./locales/nl-NL/common.json"),
  },
}

const frozenLocales = Object.freeze(locales)
export function localesImmutable() {
  return frozenLocales
}

export const getCurrentLng = () =>
  i18n.language || window.localStorage.i18nextLng || fallbackLanguages.default

const fallbackLanguages = {
  nl: ["nl-NL"],
  default: ["en-US"],
}

const i18n = i18next
i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: localeResources,
    fallbackLng: fallbackLanguages.default,
    ns: "common",
    defaultNS: "common",
    react: { useSuspense: true },
    debug: false,
    cache: { enabled: true },
  })

export default i18n
