import * as React from "react"

function SvgAlertIcon(props) {
  return (
    <svg
      id="alertIcon_svg__Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 52 52"
      xmlSpace="preserve"
      width="1em"
      height="1em"
      {...props}
    >
      <style>
        {".alertIcon_svg__st1{fill:#1c1c1c}.alertIcon_svg__st2{fill:#fff}"}
      </style>
      <path
        fill="currentColor"
        d="M44 44v-8h-4v-8h-4v-8h-4v-8h-4V4h-4v8h-4v8h-4v8h-4v8H8v8H4v4h44v-4z"
      />
      <path
        className="alertIcon_svg__st1"
        d="M24 0h4v4h-4zM20 4h4v8h-4zM16 12h4v8h-4zM12 20h4v8h-4zM36 20h4v8h-4zM40 28h4v8h-4zM8 28h4v8H8zM4 36h4v8H4zM48 44v4H4v-4H0v8h52v-8zM44 36h4v8h-4zM28 4h4v8h-4zM32 12h4v8h-4z"
      />
      <path
        id="alertIcon_svg__Rect\xE1ngulo_325"
        className="alertIcon_svg__st2"
        d="M24 20h4v16h-4z"
      />
      <path
        id="alertIcon_svg__Rect\xE1ngulo_326"
        className="alertIcon_svg__st2"
        d="M24 40h4v4h-4z"
      />
    </svg>
  )
}

export default SvgAlertIcon
