import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

// Usecases
import { getAllProgress } from "../../common/usecases/GetAllProgress"
import { updateProgress } from "../../common/usecases/UpdateProgress"

// Interfaces
import { Progress } from "../../common/entities/Progress"
import { ILibrarySlice } from "../interfaces"
import { RootState } from "../store"

// Data
import trailer from "../data/trailer"
import interviews from "../data/interviews"
import videos from "../data/videos"
import { bookClubTrailer, books } from "../data/books"

const sliceInitialState: ILibrarySlice = {
  interviews,
  books,
  videos,
  trailer,
  bookClubTrailer,
}

export const refreshProgress = createAsyncThunk(
  "library/refreshProgress",
  async () => {
    return await getAllProgress()
  },
)

export const progressVideo = createAsyncThunk(
  "library/progressVideo",
  async (progress: Progress) => {
    return await updateProgress(progress)
  },
)

export const progressInterview = createAsyncThunk(
  "library/progressInterview",
  async (progress: Progress) => {
    return await updateProgress(progress)
  },
)

export const progressAllVideos = createAsyncThunk<
  Progress[],
  number,
  { state: RootState }
>("library/progressAllVideos", async (progress, thunkApi) => {
  const state = thunkApi.getState()
  const promises = state.libraryState.videos.map((v) =>
    updateProgress({ mediaId: v.id, progress: progress }),
  )
  return await Promise.all(promises)
})

const customSlice = createSlice({
  name: "library",
  initialState: sliceInitialState,
  reducers: {
    // selectInterview(state, action: PayloadAction<{ id: string }>) {
    //   let interview = state.interviews.find((t) => t.id === action.payload.id)
    //   if (interview && interview.order === undefined) {
    //     interview.order = state.interviews.filter(
    //       (t) => t.order !== undefined,
    //     ).length
    //   }
    //   return state
    // },
  },
  extraReducers: (builder) => {
    builder.addCase(refreshProgress.fulfilled, (state, action) => {
      state.interviews = state.interviews.map((t) => {
        const p = action.payload.find((p) => p.mediaId === t.id)
        return {
          ...t,
          progress: p?.progress || 0,
          order: p?.order,
        }
      })
      state.books = state.books.map((book) => {
        const p = action.payload.find((p) => p.mediaId === book.id)
        return {
          ...book,
          progress: p?.progress || 0,
          order: p?.order,
        }
      })
      state.videos = state.videos.map((v) => {
        const p = action.payload.find((p) => p.mediaId === v.id)
        return {
          ...v,
          progress: p?.progress || 0,
          order: p?.order,
        }
      })
      return state
    })
    builder.addCase(progressVideo.fulfilled, (state, action) => {
      let video = state.videos.find((b) => b.id === action.payload.mediaId)
      if (video) {
        video.progress = action.payload.progress
      }
      return state
    })
    builder.addCase(progressInterview.fulfilled, (state, action) => {
      let interview = state.interviews.find(
        (t) => t.id === action.payload.mediaId,
      )
      if (interview) {
        interview.progress = action.payload.progress
        if (action.payload.order !== undefined) {
          interview.order = action.payload.order
        }
      }
      return state
    })
    builder.addCase(progressAllVideos.fulfilled, (state, action) => {
      const progress = action.payload
      state.videos = state.videos.map((v) => {
        const p = progress.find((pi) => (pi.mediaId = v.id))!
        return { ...v, progress: p.progress }
      })
    })
  },
})

export const libraryActions = customSlice.actions
export default customSlice.reducer
