import React from "react"
import { Icon } from "@material-ui/core"
import Home from "./svgr/Home.jsx"

function HomeIcon(props) {
  return (
    <Icon {...props}>
      <Home />
    </Icon>
  )
}

export default HomeIcon
