import React, { useState } from "react"
import {
  withStyles,
  WithStyles,
  createStyles,
  Theme,
  Button,
  IconButton,
} from "@material-ui/core"

import emojis from "../../../common/entities/EmojiList"
import { Emoji } from "../../../common/entities/Emoji"
import EmojiPicker from "./EmojiPicker"
import { PostFeedback } from "../../../common/usecases/PostFeedback"
import clsx from "clsx"
import CloseIcon from "../../../common/components/icons/CloseIcon"

const styles = (theme: Theme) =>
  createStyles<ClassKey, {}>({
    root: {
      width: "auto",
      height: "100%",
      background: "#222",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-around",
      marginLeft: "10%",
      border: `3px solid ${theme.palette.primary.main}`,
      padding: ".4rem",
      [theme.breakpoints.up("lg")]: {
        padding: "1rem",
      },
    },
    closeIcon: {
      alignSelf: "flex-end",
      fontSize: "2rem",
      color: theme.palette.primary.main,
      cursor: "pointer",
      padding: 0,
    },
    feedbackList: {
      display: "flex",
      flexDirection: "column",
      flex: 1,
      justifyContent: "space-evenly",
    },
    feedbackItem: {
      fontFamily: "Roboto Mono, Consolas, Courier New",
      fontSize: "1.3vh",
      transition: "0.3s",
      "@media(min-height: 800px)": {
        fontSize: ".8rem",
      },
      "&:hover": {
        cursor: "pointer",
        color: "#ffff",
      },
    },
    selected: {
      color: "white",
    },
  })

type ClassKey =
  | "root"
  | "closeIcon"
  | "feedbackItem"
  | "feedbackList"
  | "selected"
type IProps = {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
}
type PropsType = IProps & WithStyles<ClassKey>

const Feedback: React.FC<PropsType> = (props: PropsType) => {
  const { classes } = props
  const [emoji, setEmoji] = useState<Emoji>()
  const [message, setMessage] = useState<string>()

  const redEmojis: Emoji[] = emojis.filter(
    (e) => e.name.substring(0, 8) === "positive",
  )

  const messageList: string[] = [
    // "I wish I have used wisely my time by listening to the bursts through the experiential route. ",
    "I have enjoyed the dark UX and I am a big fan of low cost airlines and gambling.",
    "Adverts and unsolicited distractions are my cup of team! Please give me more!",
    "Focus and attention are there to be disrupted! What a relaxing experience!",
    "Power to the dark side! I want a dark UX in every contract and report we issue! We design a darker world!",
    "When I eat, I like to watch cooking programmes and the light needs to be red.",
    'Terms and conditions, habit forming apps, the use of "like", the social network, I can now see the -lack of- light in all of this.',
    '"We grow accustomed to the Dark – When Light is put away – As when the Neighbor holds the Lamp - To witness her Good bye"  Emily Dickinson',
  ]

  function submit(e) {
    e.preventDefault()
    if (message !== undefined && emoji !== undefined)
      PostFeedback({
        message: message,
        emoji: emoji.name,
        mediaId: "fast-track",
      }).catch((err) => alert(err))
    props.setOpen(false)
  }

  return (
    <>
      <div className={classes.root}>
        <IconButton
          aria-label="back"
          onClick={() => props.setOpen(false)}
          className={classes.closeIcon}
        >
          <CloseIcon className={classes.closeIcon} />
        </IconButton>
        <div className={classes.feedbackList}>
          {messageList.map((m, i) => {
            return (
              <div
                key={i}
                className={clsx(
                  classes.feedbackItem,
                  m === message && classes.selected,
                )}
                onClick={() => setMessage(m)}
              >
                {m}
              </div>
            )
          })}
        </div>

        <EmojiPicker
          selectedEmoji={emoji}
          setSelectedEmoji={setEmoji}
          emojis={redEmojis}
        />

        <Button
          variant="outlined"
          color="primary"
          onClick={submit}
          disabled={!message || !emoji}
        >
          {"send"}
        </Button>
      </div>
    </>
  )
}

export default withStyles(styles)(Feedback)
