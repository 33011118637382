import { IInterview } from "../../../../../redux/interfaces"
import { theme } from "../../../../../common/mui"

interface IPathNode {
  x: number
  r: number
}
export const haloOffset = 6
export const verticalSpacing = 130
export const pathLayout = [
  { x: 80, r: 40 },
  { x: 140, r: 35 },
  { x: 80, r: 50 },
  { x: 60, r: 30 },
  { x: 120, r: 40 },
]

export const drawPlay = (
  ctx: CanvasRenderingContext2D,
  x: number,
  y: number,
  size: number,
  color: string,
  lineWidth?: number,
) => {
  ctx.fillStyle = color
  ctx.strokeStyle = color
  ctx.lineWidth = lineWidth || 2

  ctx.beginPath()
  ctx.moveTo(x - size / 3, y - size / 2)
  ctx.lineTo(x - size / 3, y + size / 2)
  ctx.lineTo(x + (2 * size) / 3, y)
  ctx.lineTo(x - size / 3, y - size / 2)
  ctx.stroke()
}

export const drawWatchedInterview = (
  ctx: CanvasRenderingContext2D,
  node: IPathNode,
  interview: IInterview,
  colorBubble: string,
  colorProgress: string,
) => {
  // Draw bubble
  ctx.fillStyle = colorBubble
  ctx.beginPath()
  ctx.arc(node.x, verticalSpacing / 2, node.r, 0, 2 * Math.PI)
  ctx.fill()

  // Draw progress
  ctx.strokeStyle = colorProgress
  ctx.lineWidth = 6
  ctx.beginPath()
  ctx.arc(
    node.x,
    verticalSpacing / 2,
    node.r + haloOffset,
    -0.5 * Math.PI,
    2 * Math.PI * interview.progress - 0.5 * Math.PI,
  )
  ctx.stroke()

  // Draw Play
  drawPlay(
    ctx,
    node.x,
    verticalSpacing / 2,
    0.6 * node.r,
    theme.palette.secondary.contrastText,
  )
}

export const drawNextInterview = (
  ctx: CanvasRenderingContext2D,
  node: IPathNode,
) => {
  // Draw bubble
  ctx.strokeStyle = theme.palette.primary.main
  ctx.fillStyle = theme.palette.primary.main
  ctx.lineWidth = 3
  ctx.beginPath()
  ctx.arc(node.x, verticalSpacing / 2, node.r, 0, 2 * Math.PI)
  ctx.fill()
  ctx.stroke()

  // Draw Play
  drawPlay(
    ctx,
    node.x,
    verticalSpacing / 2,
    0.6 * node.r,
    theme.palette.primary.contrastText,
  )
  ctx.fill()
}

export const drawFutureInterview = (
  ctx: CanvasRenderingContext2D,
  node: IPathNode,
) => {
  // Draw bubble
  ctx.fillStyle = "#ECECEC"
  ctx.beginPath()
  ctx.arc(node.x, verticalSpacing / 2, node.r, 0, 2 * Math.PI)
  ctx.fill()
}

export const drawTrailer = (
  ctx: CanvasRenderingContext2D,
  node: IPathNode,
  watched: boolean,
) => {
  const h = verticalSpacing * 0.6
  const [x0, y0] = [node.x - h / 2, verticalSpacing / 2]
  const [dx, dy] = [h / 5, h / 5]

  // Draw film
  ctx.lineWidth = 3
  ctx.strokeStyle = "black"
  ctx.fillStyle = "white"

  ctx.beginPath()
  for (let i = 0; i < 5; i++) {
    ctx.rect(x0 + i * dx, y0 - h / 2, dx, dy)
    ctx.rect(x0 + i * dx, y0 + h / 2, dx, -dy)
  }
  ctx.fill()
  ctx.stroke()
  ctx.closePath()
  ctx.beginPath()
  ctx.rect(x0, y0 - h / 2 + dy, h, h - 2 * dy)
  ctx.fillStyle = "#555"
  ctx.fill()
  ctx.stroke()
  ctx.closePath()

  // Draw label
  ctx.lineWidth = 1
  ctx.fillStyle = watched
    ? theme.palette.secondary.dark
    : theme.palette.primary.main

  drawPlay(ctx, x0 + h / 2, y0, h / 4, "white", 1)
  ctx.fill()
}

export const drawLink = (
  ctx: CanvasRenderingContext2D,
  curr: IPathNode,
  prev?: IPathNode,
  next?: IPathNode,
  colorPrev?: string,
  colorNext?: string,
) => {
  ctx.lineWidth = 3
  ctx.beginPath()

  if (prev !== undefined) {
    ctx.moveTo(prev.x, verticalSpacing * -0.5)
  } else {
    ctx.moveTo(curr.x, verticalSpacing * 0.5)
  }
  ctx.lineTo(curr.x, verticalSpacing * 0.5)
  ctx.strokeStyle = colorPrev || "#ddd"
  ctx.stroke()
  ctx.closePath()

  ctx.beginPath()
  ctx.moveTo(curr.x, verticalSpacing * 0.5)
  if (next !== undefined) {
    ctx.lineTo(next.x, verticalSpacing * 1.5)
  }
  ctx.strokeStyle = colorNext || "#ddd"
  ctx.stroke()
  ctx.closePath()
}

export const getDrawingNode = (idx: number): IPathNode => {
  return pathLayout[idx % pathLayout.length]
}
