import {
  createStyles, Theme,
  WithStyles,
  withStyles
} from "@material-ui/core"
import React, { useEffect } from "react"

import { useHistory } from "react-router"
import Header from "../../common/components/Header/Header"
import BookClubJourney from "./BookClubJourney"

const styles = (theme: Theme) =>
  createStyles<ClassKey, {}>({
    root: {
      height: "100%",
      overflow: "hidden",
      display: "flex",
      flexDirection: "column",
    },
    header: {
      display: "flex",
      margin: "1rem",
      alignItems: "center",
      justifyContent: "space-between",
      fontFamily: "Cooper Black, Tourney",
      fontSize: "3rem",
      [theme.breakpoints.down("xs")]: {
        fontSize: "2.5rem",
      },
      color: theme.palette.secondary.dark,
    },
    icon: {
      paddingLeft: 0,
      color: "black",
      fontSize: "3rem",
    },
  })

type ClassKey = "root" | "icon" | "header"
type IProps = {}
type PropsType = WithStyles<ClassKey> & IProps

const BookClubJourneyRoot: React.FC<PropsType> = (props: PropsType) => {
  const history = useHistory()
  useEffect(() => {
    document.title = `Book Club`
  })
  return (
    <>
      <div className={props.classes.root}>
        <Header
          icon={<></>}
          title="Book Club"
          backgroundColor="#FFF"
          color="black"
        />
        <BookClubJourney />
      </div>
    </>
  )
}

export default withStyles(styles)(BookClubJourneyRoot)
