import { createTheme } from "@material-ui/core/styles"

export const theme = createTheme({
  palette: {
    primary: {
      main: "#e61e28",
    },
    secondary: {
      main: "#2e2e2e",
    },
  },
  typography: {
    fontFamily: "Roboto",
    body2: {
      color: "#e61e28",
      fontSize: "0.9375rem",
      marginBottom: "1rem",
    },
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        "html, body": {
          height: "100%",
          width: "100%",
          display: "flex",
          flex: 1,
        },
        "#root": {
          display: "flex",
          flexDirection: "column",
          flex: 1,
          width: "100%",
        },
        "*": {
          // FIREFOX ONLY
          scrollbarColor: `#e61e28 #bbb`,
          scrollbarWidth: "thin",
          // WEBKIT ONLY
          "& ::-webkit-scrollbar": {
            width: 5,
            height: 5,
          },
          "& ::-webkit-scrollbar-track": {
            backgroundColor: "#bbb",
            borderRadius: 10,
          },
          "& ::-webkit-scrollbar-thumb": {
            backgroundColor: "#e61e28",
            borderRadius: 10,
          },
        },
      },
    },
    MuiButton: {
      outlined: {
        fontSize: "1rem",
        padding: "0.5rem 1.5rem",
        borderRadius: "0px",
        fontWeight: "bold",
        fontFamily: "Cooper Black, Tourney",
        textTransform: "none",
        textDecoration: "none",
        cursor: "pointer",
        boxShadow: "none",
      },
      outlinedPrimary: {
        fontFamily: "Tourney",
        backgroundColor: "#111",
        border: "2px solid #e61e28",
        color: "#e61e28",
        "@media (hover: hover)": {
          "&:hover,&:focus": {
            color: "#ffff",
            backgroundColor: "#e61e28",
            border: "2px solid #e61e28",
          },
        },
        "&.Mui-disabled": {
          border: "2px solid #e61e28",
          color: "#e61e28",
        },
      },
      outlinedSecondary: {
        backgroundColor: "white",
        border: "2px solid #111",
        color: "#111",
        "@media (hover: hover)": {
          "&:hover": {
            color: "#ddd",
            backgroundColor: "#111",
            border: "2px solid #111",
          },
        },
      },
      sizeLarge: {
        padding: "0.8rem 2rem",
        fontSize: "2rem",
      },
    },
    MuiIconButton: {
      colorPrimary: {
        "@media (hover: hover)": {
          "&:hover": {
            background: "rgba(255, 255, 255, 0.04)",
          },
        },
      },
    },
  },
})
