import { createStyles, Theme } from "@material-ui/core"
import { verticalSpacing } from "../utils/Draw"

export type ClassKey = "root" | "drawing" | "details"

const styles = (theme: Theme) =>
  createStyles<ClassKey, {}>({
    root: {
      display: "flex",
      height: verticalSpacing,
      cursor: "pointer",
      "&:hover": {
        background: "#eee",
      },
      [theme.breakpoints.up("md")]: {
        paddingLeft: "10%",
      },
    },
    drawing: {
      height: "100%",
      width: "20%", // to be overwritten
      marginRight: "1rem",
    },
    details: {
      display: "flex",
      margin: "-0.4rem",
      flexDirection: "column",
      justifyContent: "center",
      fontFamily: "Roboto Mono, Courier, Sans Serif",
      fontWeight: "bold",
      color: "#111",
      flex: 1,
    },
  })

export default styles
