import React from "react"
import {
  withStyles,
  WithStyles,
  createStyles,
  Theme,
  Container,
} from "@material-ui/core"
import { ReactNode } from "react"

type IProps = {
  children?: ReactNode
}
const styles = (theme: Theme) =>
  createStyles<ClassKey, {}>({
    root: {
      height: "100vh",
      overflow: "hidden",
      backgroundSize: "cover",
      backgroundColor: "rgb(30, 30, 30)",
      backgroundPosition: "center",
      backgroundImage: 'url("/images/background/frame.png")',
    },
    container: {
      height: "100%",
      padding: 0,
      overflow: "hidden",
      background: "rgba(30, 30, 30)",
      [theme.breakpoints.up("md")]: {
        borderRight: `${theme.palette.primary.main} 2px solid`,
        borderLeft: `${theme.palette.primary.main} 2px solid`,
      },
    },
  })

type ClassKey = "root" | "container"

type PropsType = IProps & WithStyles<ClassKey>

const Frame: React.FC<PropsType> = (props: PropsType) => {
  return (
    <div className={props.classes.root}>
      <Container className={props.classes.container} maxWidth="md">
        {props.children ? props.children : <></>}
      </Container>
    </div>
  )
}

export default withStyles(styles)(Frame)
