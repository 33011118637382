import { withStyles, WithStyles, createStyles, Theme } from "@material-ui/core"
import Button from "@material-ui/core/Button"
import React, { useEffect, useState } from "react"
import { isAuthenticated, loginRedirectAndSetLocal } from "../../auth"
import { useAppDispatch } from "../../../redux/hooks"
import { userActions } from "../../../redux/user/UserSlice"
interface IProps {}

const styles = (theme: Theme) =>
  createStyles<ClassKey, {}>({
    root: {
      width: "100vw",
      height: "100vh",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  })

type ClassKey = "root"

type PropsType = IProps & WithStyles<ClassKey>

const Login: React.FC<PropsType> = (props) => {
  const [isAuthorizing, setIsAuthorizing] = useState(false)
  const [isAuth, setIsAuth] = useState(false)
  const dispatch = useAppDispatch()

  const authorize = () => {
    setIsAuthorizing(true)
    loginRedirectAndSetLocal().then(() => {
      const idToken = localStorage.getItem("idToken")
      if (idToken) {
        dispatch(userActions.login(idToken))
      }
      setIsAuth(isAuthenticated())
      setIsAuthorizing(false)
    })
  }

  useEffect(authorize, [dispatch])

  if (isAuth) {
    return <>{props.children}</>
  } else if (isAuthorizing) {
    return <div>Please, wait while we log you in...</div>
  } else {
    return (
      <div className={props.classes.root}>
        <Button onClick={() => authorize()} variant="contained" color="default">
          Log In
        </Button>
      </div>
    )
  }
}

export default withStyles(styles)(Login)
