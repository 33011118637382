import React, { useEffect, useState } from "react"
import { RouteComponentProps, useHistory } from "react-router"

import {
  createStyles,
  Theme,
  WithStyles,
  withStyles,
  Button,
} from "@material-ui/core"
import TrackPlayer, {
  ReactPlayerProgress,
} from "../../common/components/TrackPlayer/TrackPlayer"
import { IBook } from "../../redux/interfaces"
import { useAppDispatch, useAppSelector } from "../../redux/hooks"
import ModalBox from "../../common/components/ModalBox"
import InterviewFeedback from "../../experiential/components/SubmitFeedback/InterviewFeedback"
import {
  refreshProgress,
  progressInterview,
} from "../../redux/library/LibrarySlice"
import { Progress } from "../../common/entities/Progress"

const styles = (theme: Theme) =>
  createStyles<ClassKey, {}>({
    root: {
      height: "100%",
      overflow: "hidden",
      display: "flex",
      flexDirection: "column",
    },
    modalBody: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      padding: "1.5rem",
      flex: 1,
      "& > *": {
        marginBottom: "2rem",
      },
    },
    buttonOverride: {
      width: "100%",
      "&:hover": { color: "white" },
    },
    anchor: {
      textDecorationLine: "none",
      color: "inherit",
    },
  })

type IProps = {}
type IMatchProps = {
  interviewId: string
}
type ClassKey = "root" | "modalBody" | "anchor" | "buttonOverride"
type PropsType = WithStyles<ClassKey> &
  IProps &
  RouteComponentProps<IMatchProps>

// TODO: refactor this and Experiental Journey to extract common code.
const BookPlayer: React.FC<PropsType> = (props: PropsType) => {
  const interviewId = props.match.params.interviewId
  const [playing, setPlaying] = useState(false)
  const [thisProgress, setThisProgress] = useState<Progress>()
  const history = useHistory()

  const interview: IBook = useAppSelector(
    (store) =>
      store.libraryState.books.find((t) => t.id === interviewId) ||
      store.libraryState.books[0],
  )

  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(refreshProgress())
      .unwrap()
      .then((progressList) => {
        // Check if this is the first time this interview was selected
        const progressQuery: Progress | undefined = progressList.find(
          (p) => p.mediaId === interviewId,
        )
        if (progressQuery === undefined || progressQuery.order === undefined) {
          console.log("first time", interviewId, progressList)
          const newProgress: Progress = {
            mediaId: interviewId,
            progress: 0,
            order: progressList.length,
          }
          setThisProgress(newProgress)
          dispatch(progressInterview(newProgress))
        } else if (progressQuery) {
          setThisProgress(progressQuery)
        }
      })
  }, [dispatch, interviewId])

  const onProgress = (progress: ReactPlayerProgress) => {
    if (thisProgress && progress.played > interview.progress) {
      dispatch(
        progressInterview({
          mediaId: interview.id,
          progress: Math.max(progress.played, interview.progress),
          order: thisProgress.order,
        }),
      )
    }
  }

  const [overlay, setOverlay] = useState<"none" | "feedback" | "request">(
    "none",
  )

  return (
    <>
      <div className={props.classes.root}>
        <TrackPlayer
          title={"Book Club"}
          playing={playing}
          setPlaying={setPlaying}
          onProgress={onProgress}
          interview={interview}
          closeIconOnClick={() => history.push("/book-club")}
          actions={[
            { label: "request", onClick: () => setOverlay("request") },
            { label: "feedback", onClick: () => setOverlay("feedback") },
          ]}
        />
        <ModalBox
          title={interview.title}
          subtitle={interview.author}
          open={overlay === "request"}
          onClose={() => setOverlay("none")}
        >
          <div className={props.classes.modalBody}>
            <a
              href={interview.libraryPath}
              className={props.classes.anchor}
              target="_blank"
              rel="noopener noreferrer"
            >
              {" "}
              <Button
                variant="outlined"
                color="secondary"
                className={props.classes.buttonOverride}
              >
                Send mail to the library
              </Button>
            </a>
          </div>
        </ModalBox>
        <ModalBox
          title={interview.title}
          subtitle={interview.author}
          open={overlay === "feedback"}
          onClose={() => setOverlay("none")}
        >
          <InterviewFeedback
            mediaId={interview.id}
            onClose={() => setOverlay("none")}
          />
        </ModalBox>
      </div>
    </>
  )
}

export default withStyles(styles)(BookPlayer)
