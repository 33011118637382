import React, { useEffect } from "react"
import {
  withStyles,
  WithStyles,
  createStyles,
  Theme,
  Button,
} from "@material-ui/core"

import { useHistory } from "react-router"

const styles = (theme: Theme) =>
  createStyles<ClassKey, {}>({
    root: {
      minHeight: "100%",
      flexDirection: "column",
      padding: "4rem 4rem 8rem 4rem",
      background: "#111",
      backgroundSize: "cover",
      display: "flex",
      justifyContent: "start",
    },
    text: {
      fontFamily: "Sans-serif",
      fontWeight: "bold",
      color: theme.palette.primary.main,
      fontSize: "2rem",
    },
    button: {
      marginTop: "2.8rem",
      fontFamily: "Sans-serif",
      fontSize: "1.5rem",
    },
  })

interface IProps {
  onClose: () => void
  overrideText?: string
}
type ClassKey = "root" | "text" | "button"
type PropsType = IProps & WithStyles<ClassKey>

const MobileDeadEnd: React.FC<PropsType> = (props) => {
  const { classes } = props
  const history = useHistory()
  useEffect(() => {
    document.title = `AGAM21 | Mobile Dead End`
  })
  return (
    <div className={classes.root}>
      <div className={classes.text}>
        {props.overrideText
          ? props.overrideText
          : '"Fortunately" the Fast Track route is best in the office. Please come back to this page from your desktop computer.'}
      </div>
      <Button
        variant="outlined"
        color="primary"
        onClick={() => history.push("/")}
        className={classes.button}
      >
        {"Thank you"}
      </Button>
    </div>
  )
}

export default withStyles(styles)(MobileDeadEnd)
