import React, { useRef } from "react"
import {
  withStyles,
  WithStyles,
  createStyles,
  Theme,
  IconButton,
  Button,
  Box,
} from "@material-ui/core"
import ReactPlayer from "react-player"
import { useState } from "react"

// Interfaces
import { IInterview } from "../../../redux/interfaces"

// Components
import CloseIcon from "../icons/CloseIcon"
import PlayBoxIcon from "../icons/PlayBoxIcon"
import PauseIcon from "../icons/PauseIcon"
import Header from "../Header/Header"

let styles = (theme: Theme) =>
  createStyles<ClassKey, {}>({
    root: {
      color: theme.palette.secondary.main,
      height: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
    },
    icon: {
      padding: 0,
      color: theme.palette.secondary.main,
      fontSize: "2.5rem",
    },
    image: {
      height: 0,
      minWidth: "100%",
      maxHeight: "100%",
      flex: "1 1",
      objectFit: "cover",
      cursor: "pointer",
    },
    player: {
      display: "flex",
      alignItems: "center",
      padding: "0 1.5rem",
    },
    title: {
      paddingLeft: "1rem",
      fontFamily: "Roboto Mono, Consolas, Courier New",
      color: theme.palette.secondary.main,
      marginBottom: 0,
      [theme.breakpoints.down("sm")]: {
        fontSize: ".8rem",
      },
    },
    author: {
      paddingLeft: "1rem",
      fontFamily: "Roboto Mono, Consolas, Courier New",
      color: theme.palette.secondary.light,
      marginTop: 0,
      [theme.breakpoints.down("sm")]: {
        fontSize: ".8rem",
      },
    },
    progressBar: {
      width: "100%",
      padding: "0 1.5rem",
    },
    track: {
      width: "100%",
      height: "5px",
      background: "#aaa",
    },
    progress: {
      height: "5px",
      background: theme.palette.primary.main,
    },
    actions: {
      display: "flex",
      padding: "1.5rem",
      width: "100%",
      justifyContent: "space-between",
      [theme.breakpoints.up("sm")]: {
        justifyContent: "flex-end",
        "& *": {
          margin: "0 2rem",
        },
      },
    },
    spinner: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flex: 1,
      fontSize: "1.25rem",
      fontFamily: "Cooper Black, Tourney",
      // color: theme.palette.secondary.main,
      fontWeight: "normal",
      animation: "$blinking 1.2s infinite",
    },
    "@keyframes blinking": {
      "0%": { color: theme.palette.secondary.main },
      "49%": { color: theme.palette.secondary.main },
      "60%": { color: theme.palette.primary.main },
      "99%": { color: theme.palette.primary.main },
      "100%": { color: theme.palette.secondary.main },
    },
  })

type ClassKey =
  | "root"
  | "image"
  | "player"
  | "icon"
  | "title"
  | "author"
  | "progressBar"
  | "track"
  | "progress"
  | "actions"
  | "spinner"
  | "@keyframes blinking"

type IProps = {
  title: string
  interview: IInterview
  actions: { onClick: () => void; label: string }[]
  closeIconOnClick: () => void
  playing: boolean
  setPlaying: React.Dispatch<React.SetStateAction<boolean>>
  onProgress: (progress: ReactPlayerProgress) => void
}

export interface ReactPlayerProgress {
  played: number
  playedSeconds: number
}

type PropsType = IProps & WithStyles<ClassKey>

const TrackPlayer: React.FC<PropsType> = (props: PropsType) => {
  const { classes, interview } = props
  const [localProgress, setLocalProgress] = useState(0)
  const [interviewReady, setInterviewReady] = useState(false)
  const [ambientReady, setAmbientReady] = useState(false)
  const [hasEnded, setHasEnded] = useState(false)
  const interviewRef = useRef<ReactPlayer>(null)
  const progressBar = useRef<HTMLDivElement>(null)

  // Update local & store progress (more often)
  const onProgress = (progress: ReactPlayerProgress) => {
    setLocalProgress(progress.played)
    props.onProgress(progress)
  }

  const handleProgressBarClick = (e: React.MouseEvent<HTMLDivElement>) => {
    const left = progressBar.current!.offsetLeft
    const width = progressBar.current!.offsetWidth
    const pc = (e.clientX - left) / width
    if (interviewRef && interviewRef.current) {
      interviewRef.current.seekTo(pc)
    }
  }

  const playOrPause = () => props.setPlaying((playing) => !playing)
  console.log(interviewReady, ambientReady, interview)
  return (
    <div className={classes.root}>
      <Header
        icon={
          <IconButton
            aria-label="back"
            onClick={props.closeIconOnClick}
            style={{ padding: 0 }}
          >
            <CloseIcon className={classes.icon} />
          </IconButton>
        }
        title={props.title}
        small={true}
        backgroundColor="white"
        color="black"
      />
      {interviewReady && ambientReady ? (
        <img
          className={classes.image}
          alt="Ambient"
          src={`/images/background/${interview.ambient.backgroundSrc}`}
          // style={!talk.playing() ? { filter: "grayscale(1)" } : {}}
          onClick={playOrPause}
        ></img>
      ) : (
        <Box className={classes.spinner}>
          Loading audio...
          {/* <CircularProgress color="primary" /> */}
        </Box>
      )}

      <div className={classes.player}>
        {props.playing ? (
          <IconButton
            aria-label="pause"
            className={classes.icon}
            onClick={playOrPause}
          >
            <PauseIcon className={classes.icon} />
          </IconButton>
        ) : (
          <IconButton
            aria-label="play"
            className={classes.icon}
            onClick={playOrPause}
          >
            <PlayBoxIcon className={classes.icon} />
          </IconButton>
        )}
        <div>
          <h2 className={classes.title}> {interview?.title} </h2>
          <h3 className={classes.author}> {interview?.author} </h3>
        </div>
      </div>
      <div className={classes.progressBar}>
        <div
          className={classes.track}
          ref={progressBar}
          onClick={handleProgressBarClick}
        >
          <div
            className={classes.progress}
            style={{
              width: `${localProgress * 100}%`,
            }}
          ></div>
        </div>
      </div>
      <div className={classes.actions}>
        {props.actions &&
          props.actions.map((action, index) => (
            <Button
              key={index}
              variant="outlined"
              color="secondary"
              onClick={action.onClick}
            >
              {action.label}
            </Button>
          ))}
      </div>
      <div>
        <ReactPlayer
          url={interview.src}
          ref={interviewRef}
          playing={props.playing}
          height={0}
          width={0}
          volume={interview.volume}
          onReady={() => setInterviewReady(true)}
          onProgress={onProgress}
          onEnded={() => setHasEnded(true)}
          config={{
            file: {
              forceAudio: true,
              hlsOptions: {
                xhrSetup: function (xhr, url) {
                  xhr.setRequestHeader(
                    "Authorization",
                    `Bearer ${localStorage.getItem("accessToken")}`,
                  )
                },
              },
            },
          }}
        />
        <ReactPlayer
          url={interview.ambient.audioSrc}
          playing={hasEnded ? false : props.playing}
          height={0}
          width={0}
          volume={interview.ambient.volume}
          onReady={() => setAmbientReady(true)}
          onSeek={(s) => console.log(`seeking ${s}`)}
          config={{
            file: {
              forceAudio: true,
              hlsOptions: {
                xhrSetup: function (xhr, url) {
                  xhr.setRequestHeader(
                    "Authorization",
                    `Bearer ${localStorage.getItem("accessToken")}`,
                  )
                },
              },
            },
          }}
        />
      </div>
    </div>
  )
}

export default withStyles(styles)(TrackPlayer)
