import React, { useLayoutEffect, useRef } from "react"
import { withStyles, WithStyles, createStyles, Theme } from "@material-ui/core"
import { IInterview } from "../../../../redux/interfaces"
import pathStepStyle, {
  ClassKey as pathStepClassKey,
} from "./styles/pathStepStyle"
import {
  drawLink,
  drawWatchedInterview,
  getDrawingNode,
  haloOffset,
  verticalSpacing,
} from "./utils/Draw"
import { color_01 } from "./Colors"

const styles = (theme: Theme) =>
  createStyles<ClassKey, {}>({
    title: {
      fontWeight: "bold",
      [theme.breakpoints.down("xs")]: {
        fontSize: "0.8rem",
      },
    },
    author: {
      fontWeight: "lighter",
      [theme.breakpoints.down("xs")]: {
        fontSize: "0.8rem",
      },
    },
  })

type ClassKey = "title" | "author"
interface IProps {
  idx: number
  interview: IInterview
  onClick: (interviewId: string) => void
  colorBubble: string
  colorProgress: string
}
type PropsType = IProps & WithStyles<ClassKey | pathStepClassKey>

const InterviewStep: React.FC<PropsType> = (props) => {
  const { classes, idx, interview, onClick } = props
  const canvasRef = useRef<HTMLCanvasElement>(null)
  const node = getDrawingNode(idx)

  useLayoutEffect(() => {
    const draw = (ctx) => {
      const node = getDrawingNode(idx)
      ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height)

      drawLink(
        ctx,
        node,
        getDrawingNode(idx - 1),
        getDrawingNode(idx + 1),
        color_01,
        color_01,
      )

      drawWatchedInterview(
        ctx,
        node,
        interview,
        props.colorBubble,
        props.colorProgress,
      )
      // ctx.scale(window.devicePixelRatio, window.devicePixelRatio)
    }

    const canvas = canvasRef.current
    if (canvas !== null) {
      console.log("drawing")
      const ctx = canvas!.getContext("2d")!
      draw(ctx)
    }
  }, [idx, interview, props])

  const getWidth = () =>
    node.x + node.r + haloOffset + window.devicePixelRatio + 10

  return (
    <div className={classes.root} onClick={() => onClick(interview.id)}>
      <canvas
        ref={canvasRef}
        className={classes.drawing}
        style={{ width: getWidth() }}
        width={getWidth()}
        height={verticalSpacing}
      ></canvas>
      <div className={classes.details}>
        <span className={classes.title}>{interview.title}</span>
        <span className={classes.author}>{interview.author}</span>
      </div>
    </div>
  )
}

export default withStyles((theme) => ({
  ...pathStepStyle(theme),
  ...styles(theme),
}))(InterviewStep)
