import * as Msal from "@azure/msal-browser"
import config from "./config"
import { isAfter } from "date-fns"
import Cookies from "universal-cookie"

const cookies = new Cookies()

const initMsal = () => {
  const msalConfig = {
    auth: {
      clientId: config.activeDirectory.appId,
      authority: "https://login.microsoftonline.com/arup.onmicrosoft.com",
      tenant: "arup.onmicrosoft.com",
      redirectUri: config.activeDirectory.redirectUri,
    },
    cache: {
      cacheLocation: "localStorage",
      storeAuthStateInCookie: false,
    },
  }

  const msal = new Msal.PublicClientApplication(msalConfig as any)

  return msal
}

export const msal = initMsal()

const loginRequest = {
  scopes: [`api://${config.activeDirectory.appId}/Files.Read`],
}

export const loginRedirectAndSetLocal = async () => {
  const tokenResponse = await msal.handleRedirectPromise()

  if (!tokenResponse) {
    const accounts = msal.getAllAccounts()
    if (accounts.length === 0) {
      // No user signed in
      msal.loginRedirect(loginRequest)
    } else {
      try {
        await acquireTokenSilentAndSetLocal()
      } catch {
        msal.loginRedirect(loginRequest)
      }
    }
  } else {
    localStorage.setItem("accessToken", tokenResponse.accessToken)
    localStorage.setItem("idToken", tokenResponse.idToken)
    localStorage.setItem(
      "expiration",
      (tokenResponse.expiresOn as Date).toString(),
    )
    cookies.set("token", tokenResponse.accessToken, {
      maxAge: 604800,
      path: "*",
      sameSite: "none",
      secure: true,
    })
  }
  return tokenResponse
}

export const getloginPopupResponse = async () =>
  await msal.loginPopup(loginRequest)

export const getAccount = () => msal.getAllAccounts()[0]

export const logout = () => {
  localStorage.removeItem("accessToken")
  localStorage.removeItem("idToken")
  localStorage.removeItem("expiration")
  msal.logout()
}

export const acquireTokenSilentAndSetLocal = async () => {
  const accountInfo = getAccount()
  if (!accountInfo) {
    console.log("No user logged in")
    return
  }
  const tokenRequest = {
    account: accountInfo,
    scopes: loginRequest.scopes,
    // loginHint: accountInfo?.idTokenClaims?.preffered_username,
  }
  const res = await msal.acquireTokenSilent(tokenRequest)
  localStorage.setItem("accessToken", res.accessToken)
  localStorage.setItem("idToken", res.idToken)
  localStorage.setItem("expiration", (res.expiresOn as Date).toString())
  cookies.set("token", res.accessToken, {
    maxAge: 604800,
    path: "/",
    sameSite: "none",
    secure: true,
  })
}

export const isAuthenticated = () => {
  const accessToken = localStorage.getItem("accessToken")
  const idToken = localStorage.getItem("idToken")
  const expiration = localStorage.getItem("expiration")

  const expiryDate = expiration ? new Date(expiration) : new Date(0)
  const now = new Date()

  return !!(accessToken && idToken && isAfter(expiryDate, now))
}
