import React, { useEffect } from "react"
import { createStyles, Theme, WithStyles, withStyles } from "@material-ui/core"
import { useHistory } from "react-router"

const styles = (theme: Theme) =>
  createStyles<ClassKey, {}>({
    // TODO: refactor and extract common classes to a separate
    root: {
      height: "100%",
      overflow: "hidden",
      display: "flex",
      flexDirection: "column",
      backgroundColor: "#5E5E5E",
    },
    list: {
      display: "flex",
      flexDirection: "column",
      maxHeight: "calc(100%-7rem)",
      overflowY: "auto",
      padding: "1rem",
    },
    itemList: {
      backgroundColor: "black",
      borderTop: "0.25rem solid white",
      borderBottom: "0.25rem solid white",
      display: "flex",
      marginBottom: "0.33rem",
      paddingLeft: "1rem",
      transition: "background-color 0.2s",
      "&:hover": { backgroundColor: "#F20023", cursor: "pointer" },
    },
    column: {},
    icon: {
      paddingLeft: 0,
      color: "black",
      fontSize: "3rem",
    },
    row: { display: "flex", flexDirection: "column", width: "100%" },
    itemText: {
      fontFamily: "Cooper Black, Tourney",
      fontSize: "2rem",
      margin: "0px",
      color: "black",
    },
    itemAnchor: {
      color: "white",
      fontWeight: "bold",
      textDecorationLine: "none",
    },
    footer: {
      height: "6rem",
      position: "fixed",
      fontFamily: "Consolas",
      left: 0,
      bottom: 0,
      width: "100%",
      backgroundColor: "#5E5E5E",
      color: "white",
      paddingLeft: "1rem",
      textAlign: "left",
    },
  })

type ClassKey =
  | "root"
  | "itemList"
  | "itemAnchor"
  | "itemText"
  | "list"
  | "icon"
  | "row"
  | "column"
  | "footer"

type IProps = {}
type PropsType = WithStyles<ClassKey> & IProps

const portals = [
  {
    title: "Book Club",
    description: "description",
    route: "/book-club",
    external: false,
  },
  {
    title: "AGAM 21",
    description: "description",
    route: "/experiential",
    external: false,
  },
  {
    title: "Modern Times",
    description: "description",
    route: "https://media.arup.com/playlist/dedicated/1_jauflpt3/",
    external: true,
  },
  {
    title: "Find Workshops",
    description: "description",
    route:
      "https://moodle.arup.com/local/search/index.php?search=digital+disruption&context=2&page=search&showcatid=-1",
    external: true,
  },
  {
    title: "Corona Invasion",
    description: "description",
    route: "/corona-invasion",
    external: false,
  },
  {
    title: "Project Charlotte",
    description: "description",
    route: "/project-charlotte",
    external: false,
  },
]

const ArupExperienceLandingRoot: React.FC<PropsType> = (props: PropsType) => {
  const history = useHistory()
  useEffect(() => {
    document.title = `Arup Experience`
  })
  return (
    <>
      <div className={props.classes.root}>
        <div>
          <p></p>
        </div>
        <div className={props.classes.list}>
          {portals &&
            portals.map((it, index) => (
              <div
                key={index}
                className={props.classes.itemList}
                onClick={() => {
                  if (it.external) {
                    window.location.href = it.route
                  } else {
                    history.push(it.route)
                  }
                }}
              >
                <p className={props.classes.itemText}>
                  <a className={props.classes.itemAnchor} href={it.route}>
                    {it.title}
                  </a>
                </p>
              </div>
            ))}
        </div>
        <div className={props.classes.footer}>
          <p>Update 2022.1.2</p>
          <p>Digital Disruption Experimental</p>
        </div>
      </div>
    </>
  )
}

export default withStyles(styles)(ArupExperienceLandingRoot)
