import React, { ReactNode } from "react"
import {
  withStyles,
  WithStyles,
  createStyles,
  Theme,
  IconButton,
  Modal,
} from "@material-ui/core"
import CloseIcon from "../../../common/components/icons/CloseIcon"

const styles = (theme: Theme) =>
  createStyles<ClassKey, {}>({
    root: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100%",
    },
    box: {
      display: "flex",
      flexDirection: "column",
      background: "white",
      height: "100%",
      width: "100%",
      maxHeight: "80vh",
      border: "3px solid black",
      [theme.breakpoints.up("md")]: {
        height: "auto",
        width: "50%",
      },
    },
    header: {
      padding: "0.5rem 2rem",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",

      color: "black",
      borderBottom: "3px solid black",
    },
    titles: {
      fontFamily: "Roboto Mono, Consolas, Courier New",
      fontWeight: "bold",
      display: "flex",
      flexDirection: "column",
    },
    subtitle: {
      fontWeight: "normal",
      color: "grey",
    },
    container: {
      padding: 0,
      position: "relative",
      overflow: "auto",
      height: "100%",
    },
    icon: {
      color: theme.palette.secondary.dark,
      fontSize: "2rem",
    },
  })

type ClassKey =
  | "root"
  | "box"
  | "header"
  | "titles"
  | "subtitle"
  | "container"
  | "icon"

interface IProps {
  title: string
  subtitle?: string
  open: boolean
  onClose: () => void
  children: ReactNode
}

type PropsType = IProps & WithStyles<ClassKey>

const ModalBox: React.FC<PropsType> = (props) => {
  const { classes, title, subtitle, open, onClose, children } = props
  return (
    <Modal open={open} onClose={onClose}>
      <div className={classes.root}>
        <div className={classes.box}>
          <div className={classes.header}>
            <div className={classes.titles}>
              <span>{title}</span>
              {subtitle && <span className={classes.subtitle}>{subtitle}</span>}
            </div>
            <IconButton onClick={onClose}>
              <CloseIcon className={classes.icon} />
            </IconButton>
          </div>
          <div className={classes.container}>{children}</div>
        </div>
      </div>
    </Modal>
  )
}

export default withStyles(styles)(ModalBox)
