import React from "react"
import { withStyles, WithStyles, createStyles, Theme } from "@material-ui/core"

import cslx from "clsx"
import { Emoji } from "../../../common/entities/Emoji"

let styles = (theme: Theme) =>
  createStyles<ClassKey, {}>({
    root: {
      height: "3rem",
      color: theme.palette.primary.main,
      background: "none",
      display: "flex",
      justifyContent: "space-around",
      border: "none",
      padding: 0,
      margin: ".7rem",
      marginBottom: "7%",
    },
    image: {
      padding: "10%",
      cursor: "pointer",
      transitionProperty: "width, max-width, min-width, margin",
      transitionDuration: "0.1s",
      transitionTimingFunction: "ease",
      filter: "grayscale(1) brightness(0.85)",
      "&:hover": {
        filter: "none",
      },
    },
    selected: {
      filter: "none",
      color: "white",
    },
    radio: {
      position: "absolute",
      width: "1px",
      height: "1px",
      overflow: "hidden",
      clip: "rect(0,0,0,0)",
      border: "0",
    },
    wrapper: {
      flex: "1 1",
    },
    label: {
      display: "flex",
      alignItems: "center",
      height: "100%",
    },
  })

type ClassKey = "root" | "image" | "radio" | "selected" | "wrapper" | "label"

type IProps = {
  selectedEmoji?: Emoji
  setSelectedEmoji: React.Dispatch<React.SetStateAction<Emoji | undefined>>
  emojis: Emoji[]
}

type PropsType = IProps & WithStyles<ClassKey>

const EmojiPicker: React.FC<PropsType> = (props: PropsType) => {
  const { classes, selectedEmoji, setSelectedEmoji, emojis } = props

  return (
    <div className={classes.root}>
      {emojis.map((it, index) => {
        const isSelected: boolean = selectedEmoji === it
        const strIndex = JSON.stringify(index)
        const path = "/images/emoji/" + it.fileName
        return (
          <div key={"div" + strIndex} className={classes.wrapper}>
            <input
              key={"input" + strIndex}
              type="radio"
              name="emoji-name"
              className={classes.radio}
              id={it.name}
              value={it.name}
            ></input>
            <label
              key={"label" + strIndex}
              htmlFor={it.name}
              onClick={() => setSelectedEmoji(it)}
              className={classes.label}
            >
              <img
                key={"img" + strIndex}
                src={path}
                alt={it.name}
                className={cslx(classes.image, isSelected && classes.selected)}
              ></img>
            </label>
          </div>
        )
      })}
    </div>
  )
}

export default withStyles(styles)(EmojiPicker)
