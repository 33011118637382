import * as React from "react"

function SvgPauseIcon(props) {
  return (
    <svg
      id="pauseIcon_svg__Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 48 48"
      xmlSpace="preserve"
      width="1em"
      height="1em"
      {...props}
    >
      <style>
        {
          ".pauseIcon_svg__st0{fill:none;stroke:currentColor;stroke-width:2.5;stroke-miterlimit:10}"
        }
      </style>
      <path
        id="pauseIcon_svg__L\xEDnea_1_00000021099469673541957990000005152559087664833439_"
        className="pauseIcon_svg__st0"
        d="M18 12v24"
      />
      <path
        id="pauseIcon_svg__L\xEDnea_1_00000034795823813473978740000002823008036519189172_"
        className="pauseIcon_svg__st0"
        d="M30 12v24"
      />
      <g>
        <path
          d="M45.5 2.5v43h-43v-43h43M48 0H0v48h48V0z"
          stroke="currentColor"
          fill="currentColor"
        />
      </g>
    </svg>
  )
}

export default SvgPauseIcon
