import React, { useCallback, useState } from "react"
import { createStyles, Theme, WithStyles, withStyles } from "@material-ui/core"

import { useAppSelector } from "../../redux/hooks"
import { IBook } from "../../redux/interfaces"
import { useHistory } from "react-router"
import Journey from "../../common/components/Journey"
import {
  color_01,
  color_02,
} from "../../experiential/components/ExperientialJourney/pathSteps/Colors"

const styles = (theme: Theme) =>
  createStyles<ClassKey, {}>({
    root: {
      height: "100%",
      overflow: "hidden",
      display: "flex",
      flexDirection: "column",
    },
    path: {},
  })

type ClassKey = "root" | "path"
type PropsType = WithStyles<ClassKey>

const BookClubJourney: React.FC<PropsType> = (props: PropsType) => {
  const [overlay, setOverlay] = useState<{
    type: "none" | "select" | "trailer"
  }>({ type: "none" })
  const books: IBook[] = useAppSelector((store) => store.libraryState.books)
  const trailer = useAppSelector((state) => state.libraryState.bookClubTrailer)
  console.log(trailer)

  const history = useHistory()
  const playInterview = useCallback(
    (interviewId: string) => {
      history.push(`/book-club/${interviewId}`)
    },
    [history],
  )
  const playTrailer = useCallback(() => {
    setOverlay({ type: "trailer" })
  }, [])

  return (
    <>
      <Journey
        interviews={books}
        overlay={overlay}
        setOverlay={setOverlay}
        playInterview={playInterview}
        playTrailer={playTrailer}
        trailer={trailer}
        colorBubble={color_01}
        colorProgress={color_02}
        progressIdShouldInclude="b"
      />
    </>
  )
}

export default withStyles(styles)(BookClubJourney)
