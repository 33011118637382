import React, { useEffect } from "react"
import ReactDOM from "react-dom"
import { ThemeProvider, CssBaseline } from "@material-ui/core"
import { Router, Route, Switch } from "react-router"
import { createBrowserHistory } from "history"
import { I18nextProvider } from "react-i18next"
import { isMobile } from "react-device-detect"

import i18n from "./common/i18n"
import * as serviceWorker from "./serviceWorker"
import Frame from "./common/components/Frame/Frame"
import Login from "./common/components/Login/Login"
import axios from "axios"
import store from "./redux/store"
import { Provider } from "react-redux"
import config from "./common/config"
import { theme } from "./common/mui"

import { acquireTokenSilentAndSetLocal, isAuthenticated } from "./common/auth"

import FastTrackCatalogue from "./fastTrack/components/FastTrackCatalogue"
import ExperientialJourney from "./experiential/components/ExperientialJourney"
import FeedbackPortal from "./landing/components/FeedbackPortal"
import InterviewPlayer from "./experiential/components/Player"
import FastTrackPlayer from "./fastTrack/components/FastTrackPlayer"
import MobileDeadEnd from "./fastTrack/components/MobileDeadEnd"
import Tetris from "./games/tetris/Tetris"
import SlotMachine from "./games/slotMachine/SlotMachine"
import BookClubJourneyRoot from "./bookClub/components/BookClubJourneyRoot"
import BookPlayer from "./bookClub/components/BookPlayer"
import ArupExperienceLandingRoot from "./arupExperienceLanding/components/ArupExperienceLandingRoot"
import CoronaInvaders from "./games/coronaInvaders/CoronaInvaders"
import ProjectCharlotteRoot from "./projectCharlotte/ProjectCharlotteRoot"

const App = () => {
  const browserHistory = createBrowserHistory()

  // Check that user is logged in before any API Calls
  axios.interceptors.request.use(async (request) => {
    if (request.url?.includes(config.api)) {
      if (!isAuthenticated()) {
        console.log("Silently requesting new token...")
        await acquireTokenSilentAndSetLocal()
      }
      request.headers["Authorization"] = `Bearer ${localStorage.getItem(
        "accessToken",
      )}`
    }
    return request
  })

  useEffect(() => {
    document.title = `Arup Experience`
  })

  // Try again on 403, after retrieving new token
  // axios.interceptors.response.use(undefined, (err) => {
  //   const status = err.response ? err.response.status : null
  // TODO: This should only retry ONCE (or maybe exponential backoff)
  //   console.log(err)
  //   if (status === 403) {
  //     return acquireTokenSilentAndSetLocal().then((_) => {
  //       err.config.headers["Authorization"] = `Bearer ${localStorage.getItem(
  //         "accessToken",
  //       )}`
  //       console.log(err.config.headers["Authorization"])
  //       return axios.request(err.config)
  //     })
  //   }
  //   return Promise.reject(err)
  // })

  // For all vars available to config Material-UI theme:
  // https://v3.material-ui.com/customization/themes/
  return (
    <Provider store={store}>
      <Login>
        <I18nextProvider i18n={i18n}>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <Router history={browserHistory}>
              <Switch>
                <Route
                  path="/experiential/interview/:interviewId"
                  component={InterviewPlayer}
                ></Route>
                <Route
                  path="/experiential/"
                  component={() => <ExperientialJourney />}
                ></Route>
                <Route
                  path="/fast-track/video/:videoId"
                  component={FastTrackPlayer}
                />
                <Route path="/fast-track/tetris">
                  <Frame>
                    <Tetris />
                  </Frame>
                </Route>
                <Route path="/fast-track/slotmachine">
                  <Frame>
                    <SlotMachine />
                  </Frame>
                </Route>
                <Route path="/corona-invasion">
                  <CoronaInvaders />
                </Route>
                <Route
                  path="/fast-track/"
                  component={isMobile ? MobileDeadEnd : FastTrackCatalogue}
                />
                <Route
                  path="/experimental/"
                  component={() => (
                    <Frame>
                      <FeedbackPortal />
                    </Frame>
                  )}
                />
                <Route path="/landing" component={ArupExperienceLandingRoot} />
                <Route path="/book-club/:interviewId" component={BookPlayer} />
                <Route path="/book-club" component={BookClubJourneyRoot} />
                <Route
                  path="/project-charlotte"
                  component={ProjectCharlotteRoot}
                />
                <Route
                  path="/feedback"
                  component={() => (
                    <Frame>
                      <FeedbackPortal />
                    </Frame>
                  )}
                />
                <Route path="/" component={ArupExperienceLandingRoot} />
              </Switch>
            </Router>
          </ThemeProvider>
        </I18nextProvider>
      </Login>
    </Provider>
  )
}
export default App

ReactDOM.render(<App />, document.getElementById("root"))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
