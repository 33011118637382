import { useHistory } from "react-router"
import { useAppDispatch } from "../../redux/hooks"
import { progressVideo } from "../../redux/library/LibrarySlice"

export interface IGameOutcome {
  onWin: () => void
  onLoose: () => void
}

export type GameOutcomeIdType = "v3" | "v4" | "v6"

const useOutcome = (
  outcomeId?: GameOutcomeIdType,
): IGameOutcome | undefined => {
  // const dispatch = useAppDispatch()
  const dispatch = useAppDispatch()
  const history = useHistory()

  const outcomes = {
    v3: {
      onWin: () =>
        dispatch(progressVideo({ mediaId: "v3", progress: 1 })).then(() =>
          history.push("/fast-track/"),
        ),
      onLoose: () =>
        dispatch(progressVideo({ mediaId: "v3", progress: 0 })).then(() =>
          history.push("/fast-track/video/v3/"),
        ),
    },
    v4: {
      onWin: () => {
        dispatch(progressVideo({ mediaId: "v4", progress: 0.99 })).then(() => {
          history.push("/fast-track/video/v4/")
        })
      },
      onLoose: () => {
        dispatch(progressVideo({ mediaId: "v4", progress: 0.99 })).then(() => {
          history.push("/fast-track/video/v4/")
        })
      },
    },
    v6: {
      onWin: () => {
        dispatch(progressVideo({ mediaId: "v6", progress: 103 / 149 })).then(
          (_) => {
            history.push("/fast-track/video/v6/")
          },
        )
      },
      onLoose: () => {
        dispatch(progressVideo({ mediaId: "v6", progress: 103 / 149 })).then(
          () => {
            history.push("/fast-track/video/v6/")
          },
        )
      },
    },
  }
  return outcomeId !== undefined ? outcomes[outcomeId] : undefined
}

export default useOutcome
