import * as React from "react"

function SvgPlayRoundIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 48 48"
      width="1em"
      height="1em"
      {...props}
    >
      <path
        fill="none"
        stroke="currentColor"
        strokeWidth={2.5}
        d="M34.5 24L16.2 13.4v21.2L34.5 24 16.2 13.4v21.2z"
      />
      <path
        d="M45.5 2.5v43h-43v-43h43M48 0H0v48h48V0z"
        stroke="currentColor"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgPlayRoundIcon
