import React from "react"
import { withStyles, WithStyles, createStyles, Theme } from "@material-ui/core"
import { ReactNode } from "react"
import clsx from "clsx"

type IProps = {
  title: string
  icon: ReactNode
  iconOptional?: ReactNode
  children?: ReactNode
  small?: boolean
}
const styles = (theme: Theme) =>
  createStyles<ClassKey, {}>({
    header: {
      display: "flex",
      padding: "1rem",
      alignItems: "center",
      justifyContent: "space-between",
      fontFamily: "Cooper Black, Tourney",
      fontSize: "3rem",
      [theme.breakpoints.down("xs")]: {
        fontSize: "2.5rem",
      },
      color: theme.palette.secondary.dark,
    },
    headerSmall: {
      fontSize: "2.5rem",
      fontWeight: 700,
    },
  })

type ClassKey = "header" | "headerSmall"

type PropsType = IProps &
  WithStyles<ClassKey> & { backgroundColor: string; color: string }

const Header: React.FC<PropsType> = (props: PropsType) => {
  return (
    <div
      className={
        props.small
          ? clsx(props.classes.header, props.classes.headerSmall)
          : props.classes.header
      }
      style={{ backgroundColor: props.backgroundColor, color: props.color }}
    >
      <span> {props.title} </span>
      <div>
        {props.iconOptional}
        {props.icon}
      </div>
    </div>
  )
}

export default withStyles(styles)(Header)
