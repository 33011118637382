import React, { useLayoutEffect, useRef } from "react"
import { withStyles, WithStyles, Theme, createStyles } from "@material-ui/core"
import pathStepStyle, {
  ClassKey as pathStepClassKey,
} from "./styles/pathStepStyle"
import {
  drawLink,
  drawTrailer,
  getDrawingNode,
  haloOffset,
  verticalSpacing,
} from "./utils/Draw"
import { color_01 } from "./Colors"

const styles = (theme: Theme) =>
  createStyles<ClassKey, {}>({
    title: {
      fontWeight: "bold",
      [theme.breakpoints.down("xs")]: {
        fontSize: "0.8rem",
      },
    },
    author: {
      fontWeight: "lighter",
      [theme.breakpoints.down("xs")]: {
        fontSize: "0.8rem",
      },
    },
  })
type ClassKey = "title" | "author"

interface IProps {
  idx: number
  watched: boolean
  onClick: () => void
  title: string
  author: string
}
type PropsType = IProps & WithStyles<ClassKey | pathStepClassKey>

const TrailerStep: React.FC<PropsType> = (props) => {
  const { classes, idx, watched, onClick } = props
  const canvasRef = useRef<HTMLCanvasElement>(null)
  const node = getDrawingNode(idx)

  useLayoutEffect(() => {
    const draw = (ctx) => {
      const node = getDrawingNode(idx)
      ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height)
      drawLink(
        ctx,
        node,
        undefined,
        getDrawingNode(idx + 1),
        undefined,
        color_01,
      )

      drawTrailer(ctx, node, watched)
      // ctx.scale(window.devicePixelRatio, window.devicePixelRatio)
    }

    const canvas = canvasRef.current
    if (canvas !== null) {
      console.log("drawing")
      const ctx = canvas!.getContext("2d")!
      draw(ctx)
    }
  }, [idx, watched])

  const getWidth = () =>
    node.x + node.r + haloOffset + window.devicePixelRatio + 10

  return (
    <div className={classes.root} onClick={onClick}>
      <canvas
        ref={canvasRef}
        className={classes.drawing}
        style={{ width: getWidth() }}
        width={getWidth()}
        height={verticalSpacing}
      ></canvas>
      <div className={classes.details}>
        <span className={classes.title}>{props.title}</span>
        <span className={classes.author}>{props.author}</span>
      </div>
    </div>
  )
}

export default withStyles((theme) => ({
  ...pathStepStyle(theme),
  ...styles(theme),
}))(TrailerStep)
