import React, { useState } from "react"

const useRoll = (): [
  number[],
  React.Dispatch<React.SetStateAction<number>>[],
] => {
  const [column1, setColumn1] = useState(0)
  const [column2, setColumn2] = useState(0)
  const [column3, setColumn3] = useState(0)
  return [
    [column1, column2, column3],
    [setColumn1, setColumn2, setColumn3],
  ]
}

export default useRoll
