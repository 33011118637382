import { IInterview } from "../interfaces"
import { shuffle } from "./shuffle"
import ambients from "./ambients"

const shuffledAmbients = shuffle([...ambients])
const placeholder =
  "https://" +
  document.location.hostname +
  "/out/v1/f7e1b2d675d745078682311477cb6647/edc109de588040c897f881ef0f45784b/a3e52c84444e4aa6a2101a1114f86873/index.m3u8"

const interviews: IInterview[] = [
  {
    id: "t1",
    title: "They started with a totally different scheme",
    author: "Naeem Hussain with David Farnsworth",
    src: "https://" + document.location.hostname + "/hls/tracks/t1.m3u8",
    thumbnailSrc:
      "https://" +
      document.location.hostname +
      "/13595bf8-e4ee-493e-bd2e-2b873ec60706/thumbnails/video_thumb.0000002.jpg",
    burstSrc:
      new Date() < new Date(Date.UTC(2021, 8, 29, 9, 30, 0))
        ? placeholder
        : "https://" +
          document.location.hostname +
          "/out/v1/1956cf41f5394e71ae83be1dde30e254/edc109de588040c897f881ef0f45784b/a3e52c84444e4aa6a2101a1114f86873/index.m3u8",
    ambient: shuffledAmbients[0 % shuffledAmbients.length],
    progress: 0,
    volume: 1,
  },
  {
    id: "t2",
    title: "And my uncle’s name played",
    author: "Malcolm Smith with Xena Petkanas, Helen Searle",
    src: "https://" + document.location.hostname + "/hls/tracks/t2.m3u8",
    thumbnailSrc:
      "https://" +
      document.location.hostname +
      "/13595bf8-e4ee-493e-bd2e-2b873ec60706/thumbnails/video_thumb.0000002.jpg",
    burstSrc:
      new Date() < new Date(Date.UTC(2021, 8, 29, 9, 30, 0))
        ? placeholder
        : "https://" +
          document.location.hostname +
          "/out/v1/a9b5b8317e4741fcbea75c2ecfc23c3a/edc109de588040c897f881ef0f45784b/a3e52c84444e4aa6a2101a1114f86873/index.m3u8",
    ambient: shuffledAmbients[1 % shuffledAmbients.length],
    progress: 0,
    volume: 1,
  },
  {
    id: "t3",
    title: "Influence went both ways",
    author: "Rudi Scheuermann with Raphael Sperry",
    src: "https://" + document.location.hostname + "/hls/tracks/t3.m3u8",
    thumbnailSrc:
      "https://" +
      document.location.hostname +
      "/13595bf8-e4ee-493e-bd2e-2b873ec60706/thumbnails/video_thumb.0000002.jpg",
    burstSrc:
      new Date() < new Date(Date.UTC(2021, 8, 30, 11, 35, 0))
        ? placeholder
        : "https://" +
          document.location.hostname +
          "/out/v1/338e888e7af6411ebfb224c37a6e02dd/edc109de588040c897f881ef0f45784b/a3e52c84444e4aa6a2101a1114f86873/index.m3u8",
    ambient: shuffledAmbients[2 % shuffledAmbients.length],
    progress: 0,
    volume: 1,
  },
  {
    id: "t4",
    title: "A city is more than a place in space, it is a drama in time",
    author: "Malcolm Smith with Xavier Nuttall",
    src: "https://" + document.location.hostname + "/hls/tracks/t4.m3u8",
    thumbnailSrc:
      "https://" +
      document.location.hostname +
      "/13595bf8-e4ee-493e-bd2e-2b873ec60706/thumbnails/video_thumb.0000002.jpg",
    burstSrc:
      new Date() < new Date(Date.UTC(2021, 8, 29, 9, 30, 0))
        ? placeholder
        : "https://" +
          document.location.hostname +
          "/out/v1/083949eaba2146d5bee9705e5594d515/edc109de588040c897f881ef0f45784b/a3e52c84444e4aa6a2101a1114f86873/index.m3u8",
    ambient: shuffledAmbients[3 % shuffledAmbients.length],
    progress: 0,
    volume: 1,
  },
  {
    id: "t5",
    title: "It did open up doors",
    author: "Rudi Scheuermann with Chris Field",
    src: "https://" + document.location.hostname + "/hls/tracks/t5.m3u8",
    thumbnailSrc:
      "https://" +
      document.location.hostname +
      "/13595bf8-e4ee-493e-bd2e-2b873ec60706/thumbnails/video_thumb.0000002.jpg",
    burstSrc:
      new Date() < new Date(Date.UTC(2021, 8, 30, 11, 35, 0))
        ? placeholder
        : "https://" +
          document.location.hostname +
          "/out/v1/75784ee3b0b54abe992b925c19391bc7/edc109de588040c897f881ef0f45784b/a3e52c84444e4aa6a2101a1114f86873/index.m3u8",
    ambient: shuffledAmbients[4 % shuffledAmbients.length],
    progress: 0,
    volume: 1,
  },
  {
    id: "t6",
    title: "A gap in the government’s agenda",
    author: "Vincent Cheng with Michael Chadney",
    src: "https://" + document.location.hostname + "/hls/tracks/t6.m3u8",
    thumbnailSrc:
      "https://" +
      document.location.hostname +
      "/13595bf8-e4ee-493e-bd2e-2b873ec60706/thumbnails/video_thumb.0000002.jpg",
    burstSrc:
      new Date() < new Date(Date.UTC(2021, 8, 29, 9, 30, 0))
        ? placeholder
        : "https://" +
          document.location.hostname +
          "/out/v1/1b57bd5fd6fb406fa86fad6db3f23b6c/edc109de588040c897f881ef0f45784b/a3e52c84444e4aa6a2101a1114f86873/index.m3u8",
    ambient: shuffledAmbients[5 % shuffledAmbients.length],
    progress: 0,
    volume: 1,
  },
  {
    id: "t7",
    title: "A more transparent and participatory approach",
    author: "Vincent Cheng with Lily Yu",
    src: "https://" + document.location.hostname + "/hls/tracks/t7.m3u8",
    thumbnailSrc:
      "https://" +
      document.location.hostname +
      "/13595bf8-e4ee-493e-bd2e-2b873ec60706/thumbnails/video_thumb.0000002.jpg",
    burstSrc:
      new Date() < new Date(Date.UTC(2021, 8, 30, 11, 35, 0))
        ? placeholder
        : "https://" +
          document.location.hostname +
          "/out/v1/ba6a368a0e45484986c3cb2f00d3a085/edc109de588040c897f881ef0f45784b/a3e52c84444e4aa6a2101a1114f86873/index.m3u8",
    ambient: shuffledAmbients[6 % shuffledAmbients.length],
    progress: 0,
    volume: 1,
  },
  {
    id: "t8",
    title: "Innovation and creative thinking",
    author: "Vincent Cheng with Jacky Chan",
    src: "https://" + document.location.hostname + "/hls/tracks/t8.m3u8",
    thumbnailSrc:
      "https://" +
      document.location.hostname +
      "/13595bf8-e4ee-493e-bd2e-2b873ec60706/thumbnails/video_thumb.0000002.jpg",
    burstSrc:
      new Date() < new Date(Date.UTC(2021, 8, 29, 9, 30, 0))
        ? placeholder
        : "https://" +
          document.location.hostname +
          "/out/v1/f748733b5fbe4c2f86b017689f06405c/edc109de588040c897f881ef0f45784b/a3e52c84444e4aa6a2101a1114f86873/index.m3u8",
    ambient: shuffledAmbients[7 % shuffledAmbients.length],
    progress: 0,
    volume: 1,
  },
  {
    id: "t9",
    title: "It can be done, even if we have never done it before",
    author: "Malcolm Smith with Mathew Vola",
    src: "https://" + document.location.hostname + "/hls/tracks/t9.m3u8",
    thumbnailSrc:
      "https://" +
      document.location.hostname +
      "/13595bf8-e4ee-493e-bd2e-2b873ec60706/thumbnails/video_thumb.0000002.jpg",
    burstSrc:
      new Date() < new Date(Date.UTC(2021, 8, 29, 9, 30, 0))
        ? placeholder
        : "https://" +
          document.location.hostname +
          "/out/v1/869236a8cc5c4e83a500e80f85ce3791/edc109de588040c897f881ef0f45784b/a3e52c84444e4aa6a2101a1114f86873/index.m3u8",
    ambient: shuffledAmbients[8 % shuffledAmbients.length],
    progress: 0,
    volume: 1,
  },
  {
    id: "t10",
    title: "It provoked an emotional response",
    author:
      "Alison Norrish with Léan Doody, Orla O'Halloran, Alannah McCartney, Rebecca Chau",
    src: "https://" + document.location.hostname + "/hls/tracks/t10.m3u8",
    thumbnailSrc:
      "https://" +
      document.location.hostname +
      "/13595bf8-e4ee-493e-bd2e-2b873ec60706/thumbnails/video_thumb.0000002.jpg",
    burstSrc:
      new Date() < new Date(Date.UTC(2021, 8, 30, 11, 35, 0))
        ? placeholder
        : "https://" +
          document.location.hostname +
          "/out/v1/2a85b789422943d0966c8ac25afcd51a/edc109de588040c897f881ef0f45784b/a3e52c84444e4aa6a2101a1114f86873/index.m3u8",
    ambient: shuffledAmbients[9 % shuffledAmbients.length],
    progress: 0,
    volume: 1,
  },
  {
    id: "t11",
    title: "That is pressure",
    author: "Alison Norrish with Javier Galán, Ana Navas, Alejandro García",
    src: "https://" + document.location.hostname + "/hls/tracks/t11.m3u8",
    thumbnailSrc:
      "https://" +
      document.location.hostname +
      "/13595bf8-e4ee-493e-bd2e-2b873ec60706/thumbnails/video_thumb.0000002.jpg",
    burstSrc:
      new Date() < new Date(Date.UTC(2021, 8, 29, 9, 30, 0))
        ? placeholder
        : "https://" +
          document.location.hostname +
          "/out/v1/6916901fa5ac4eb9869d0254eafefb18/edc109de588040c897f881ef0f45784b/a3e52c84444e4aa6a2101a1114f86873/index.m3u8",
    ambient: shuffledAmbients[10 % shuffledAmbients.length],
    progress: 0,
    volume: 1,
  },
  {
    id: "t12",
    title: "Playing Jazz",
    author: "Alison Norrish with Lauren Doughty, Kim Quazi, Abigaile Bromfield",
    src: "https://" + document.location.hostname + "/hls/tracks/t12.m3u8",
    thumbnailSrc:
      "https://" +
      document.location.hostname +
      "/13595bf8-e4ee-493e-bd2e-2b873ec60706/thumbnails/video_thumb.0000002.jpg",
    burstSrc:
      new Date() < new Date(Date.UTC(2021, 8, 29, 9, 30, 0))
        ? placeholder
        : "https://" +
          document.location.hostname +
          "/out/v1/7ff044b456904f77bd9bfe67f6a0dd7d/edc109de588040c897f881ef0f45784b/a3e52c84444e4aa6a2101a1114f86873/index.m3u8",
    ambient: shuffledAmbients[11 % shuffledAmbients.length],
    progress: 0,
    volume: 1,
  },
  {
    id: "t13",
    title: "It shows global expertise and global reach",
    author: "Alison Norrish with Susan Lamont",
    src: "https://" + document.location.hostname + "/hls/tracks/t13.m3u8",
    thumbnailSrc:
      "https://" +
      document.location.hostname +
      "/13595bf8-e4ee-493e-bd2e-2b873ec60706/thumbnails/video_thumb.0000002.jpg",
    burstSrc:
      new Date() < new Date(Date.UTC(2021, 8, 29, 9, 30, 0))
        ? placeholder
        : "https://" +
          document.location.hostname +
          "/out/v1/81dd3442fec54bb5ab59cf084655f3d1/edc109de588040c897f881ef0f45784b/a3e52c84444e4aa6a2101a1114f86873/index.m3u8",
    ambient: shuffledAmbients[12 % shuffledAmbients.length],
    progress: 0,
    volume: 1,
  },
  {
    id: "t14",
    title: "It was very positive and not expected",
    author: "Naeem Hussain with Hanna Grimsdale",
    src: "https://" + document.location.hostname + "/hls/tracks/t14.m3u8",
    thumbnailSrc:
      "https://" +
      document.location.hostname +
      "/13595bf8-e4ee-493e-bd2e-2b873ec60706/thumbnails/video_thumb.0000002.jpg",
    burstSrc:
      new Date() < new Date(Date.UTC(2021, 8, 30, 11, 35, 0))
        ? placeholder
        : "https://" +
          document.location.hostname +
          "/out/v1/76e4ac2340b44c098ac49c9ec58b7ebe/edc109de588040c897f881ef0f45784b/a3e52c84444e4aa6a2101a1114f86873/index.m3u8",
    ambient: shuffledAmbients[13 % shuffledAmbients.length],
    progress: 0,
    volume: 1,
  },
  {
    id: "t15",
    title: "Where is the glamour in a tunnel?",
    author: "Naeem Hussain with Yung Loo",
    src: "https://" + document.location.hostname + "/hls/tracks/t15.m3u8",
    thumbnailSrc:
      "https://" +
      document.location.hostname +
      "/13595bf8-e4ee-493e-bd2e-2b873ec60706/thumbnails/video_thumb.0000002.jpg",
    burstSrc:
      new Date() < new Date(Date.UTC(2021, 8, 29, 9, 30, 0))
        ? placeholder
        : "https://" +
          document.location.hostname +
          "/out/v1/e5d522f3546842cbb12a8ebdc62c9e1d/edc109de588040c897f881ef0f45784b/a3e52c84444e4aa6a2101a1114f86873/index.m3u8",
    ambient: shuffledAmbients[14 % shuffledAmbients.length],
    progress: 0,
    volume: 1,
  },
  {
    id: "t16",
    title: "Innovation from a Hackathon",
    author: "Graham Dodd with Grace Campbell",
    src: "https://" + document.location.hostname + "/hls/tracks/t16.m3u8",
    thumbnailSrc:
      "https://" +
      document.location.hostname +
      "/13595bf8-e4ee-493e-bd2e-2b873ec60706/thumbnails/video_thumb.0000002.jpg",
    burstSrc:
      new Date() < new Date(Date.UTC(2021, 8, 29, 9, 30, 0))
        ? placeholder
        : "https://" +
          document.location.hostname +
          "/out/v1/066dfba4d19648cda5a936adf1617e83/edc109de588040c897f881ef0f45784b/a3e52c84444e4aa6a2101a1114f86873/index.m3u8",
    ambient: shuffledAmbients[15 % shuffledAmbients.length],
    progress: 0,
    volume: 1,
  },
  {
    id: "t17",
    title: "They were about to scrap the whole project",
    author: "Jo da Silva with Will Whitby, Hugh Pidduck",
    src: "https://" + document.location.hostname + "/hls/tracks/t17.m3u8",
    thumbnailSrc:
      "https://" +
      document.location.hostname +
      "/13595bf8-e4ee-493e-bd2e-2b873ec60706/thumbnails/video_thumb.0000002.jpg",
    burstSrc:
      new Date() < new Date(Date.UTC(2021, 8, 30, 11, 35, 0))
        ? placeholder
        : "https://" +
          document.location.hostname +
          "/out/v1/923a0d1c5f2c4bff95900c44d65396ea/edc109de588040c897f881ef0f45784b/a3e52c84444e4aa6a2101a1114f86873/index.m3u8",
    ambient: shuffledAmbients[16 % shuffledAmbients.length],
    progress: 0,
    volume: 1,
  },
  {
    id: "t18",
    title: "Add value through data",
    author: "Rudi Scheuermann with Lucy Anderson, Catrina Cassie, Tom Somers",
    src: "https://" + document.location.hostname + "/hls/tracks/t18.m3u8",
    thumbnailSrc:
      "https://" +
      document.location.hostname +
      "/13595bf8-e4ee-493e-bd2e-2b873ec60706/thumbnails/video_thumb.0000002.jpg",
    burstSrc:
      new Date() < new Date(Date.UTC(2021, 8, 29, 9, 30, 0))
        ? placeholder
        : "https://" +
          document.location.hostname +
          "/out/v1/8eeefe25accd401397b2c5a19ab4e693/edc109de588040c897f881ef0f45784b/a3e52c84444e4aa6a2101a1114f86873/index.m3u8",
    ambient: shuffledAmbients[17 % shuffledAmbients.length],
    progress: 0,
    volume: 1,
  },
  {
    id: "t19",
    title: "You don't have to tie yourself to the computer",
    author: "Alison Norrish with Melissa Mak, Rachel Ganz, Giulio Antonutto",
    src: "https://" + document.location.hostname + "/hls/tracks/t19.m3u8",
    thumbnailSrc:
      "https://" +
      document.location.hostname +
      "/13595bf8-e4ee-493e-bd2e-2b873ec60706/thumbnails/video_thumb.0000002.jpg",
    burstSrc:
      new Date() < new Date(Date.UTC(2021, 8, 22, 15, 0, 0))
        ? placeholder
        : "https://" +
          document.location.hostname +
          "/out/v1/8f2b22e7d5f1451fb6e49e9f3fc1743a/edc109de588040c897f881ef0f45784b/a3e52c84444e4aa6a2101a1114f86873/index.m3u8",
    ambient: shuffledAmbients[18 % shuffledAmbients.length],
    progress: 0,
    volume: 1,
  },
]

export default interviews
