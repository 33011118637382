import React, { useCallback, useEffect, useRef, useState } from "react"
import {
  withStyles,
  WithStyles,
  createStyles,
  Theme,
  IconButton,
} from "@material-ui/core"
import { useHistory } from "react-router"

// State
import { useAppSelector } from "../../../redux/hooks"
import { RootState } from "../../../redux/store"

// Utils
import CloseIcon from "../../../common/components/icons/CloseIcon"
import Header from "../../../common/components/Header/Header"
import Journey from "../../../common/components/Journey"
import { color_01, color_02 } from "./pathSteps/Colors"
import InvisibleIcon from "../../../common/components/icons/InvisibleIcon"

const styles = (theme: Theme) =>
  createStyles<ClassKey, {}>({
    root: {
      height: "100%",
      overflow: "hidden",
      display: "flex",
      flexDirection: "column",
    },
    path: {
      overflow: "auto",
      flex: 1,
    },
    icon: {
      color: "black",
      fontSize: "3rem",
    },
  })

type ClassKey = "root" | "path" | "icon"
type PropsType = WithStyles<ClassKey>

type Overlay = {
  type: "hidden" | "select" | "trailer"
}

const ExperientialJourney: React.FC<PropsType> = (props: PropsType) => {
  const [overlay, setOverlay] = useState<Overlay>({ type: "hidden" })
  const history = useHistory()
  const trailer = useAppSelector((state) => state.libraryState.trailer)
  const pathRef = useRef<HTMLDivElement>(null)

  const playTrailer = useCallback(() => {
    setOverlay({ type: "trailer" })
  }, [])

  const playInterview = useCallback(
    (interviewId: string) => {
      history.push(`/experiential/interview/${interviewId}`)
    },
    [history],
  )

  const interviews = useAppSelector(
    (state: RootState) => state.libraryState.interviews,
  )

  useEffect(() => {
    if (pathRef.current !== null) {
      pathRef.current.scrollBy({
        top: pathRef.current.scrollHeight,
        behavior: "smooth",
      })
    }
  })

  useEffect(() => {
    document.title = "AGAM21 | Experiential"
  })

  return (
    <>
      <div className={props.classes.root}>
        <Header
          icon={
            <IconButton
              aria-label="close"
              style={{ justifySelf: "end" }}
              onClick={() => history.push("/landing/")}
            >
              <CloseIcon className={props.classes.icon} />
            </IconButton>
          }
          iconOptional={
            <IconButton
              aria-label="close"
              style={{ justifySelf: "end" }}
              onClick={() => history.push("/feedback/")}
            >
              <InvisibleIcon className={props.classes.icon} />
            </IconButton>
          }
          title="AGAM21"
          backgroundColor="white"
          color="black"
        />
        <Journey
          interviews={interviews}
          overlay={overlay}
          setOverlay={setOverlay}
          playInterview={playInterview}
          playTrailer={playTrailer}
          trailer={trailer}
          colorBubble={color_01}
          colorProgress={color_02}
          progressIdShouldInclude="t"
        />
      </div>
    </>
  )
}

export default withStyles(styles)(ExperientialJourney)
