import React from "react"
import { Icon } from "@material-ui/core"
import Pause from "./svgr/Pause"

function PauseIcon(props) {
  return (
    <Icon {...props}>
      <Pause />
    </Icon>
  )
}

export default PauseIcon
