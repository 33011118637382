import React from "react"
import { useSelector } from "react-redux"
import { RootState } from "../../redux/store"
import { ILibrarySlice } from "../../redux/interfaces"
import { withStyles, WithStyles, createStyles, Theme } from "@material-ui/core"
import { Feedback } from "../../common/entities/Feedback"

export const maxFeedbackItemWidth = 250

const styles = (theme: Theme) =>
  createStyles<ClassKey, {}>({
    root: {
      width: "100%",
      maxWidth: maxFeedbackItemWidth,
      color: "black",
      display: "flex",
      flexDirection: "column",
      alignItems: "start",
    },
    title: {
      fontWeight: "bold",
      color: theme.palette.primary.main,
      fontFamily: "Roboto Mono, Consolas, Courier New",
      fontSize: "1rem",
      margin: 0,
    },
    author: {
      color: "#aaa",
      fontFamily: "Roboto Mono, Consolas, Courier New",
      fontSize: ".8rem",
      margin: 0,
      fontWeight: 300,
    },
    message: {
      marginTop: 0,
      fontFamily: "Roboto Mono, Consolas, Courier New",
      fontSize: ".8rem",
      color: "white",
      fontWeight: 300,
    },
    emoji: {
      alignSelf: "center",
      width: "30%",
    },
  })

type IProps = {
  feedback: Feedback
}
type ClassKey = "root" | "title" | "author" | "message" | "emoji"
type PropsType = IProps & WithStyles<ClassKey>

const FeedbackItem: React.FC<PropsType> = (props: PropsType) => {
  const state: ILibrarySlice = useSelector(
    (state: RootState) => state.libraryState,
  )
  const { classes, feedback } = props

  function findTitleForMediaId(mediaId: string) {
    const titleFromBook = state.books.find((it) => it.id === mediaId)?.title
    const titleFromInterview = state.interviews.find((it) => it.id === mediaId)
      ?.title
    return titleFromBook || titleFromInterview
  }

  return (
    <div className={classes.root}>
      <h1 className={classes.title}>
        {findTitleForMediaId(feedback.mediaId) || "Dark UX Experience"}
      </h1>
      <p className={classes.author}>{feedback.user}</p>
      <p className={classes.message}> {feedback.message} </p>
      <img
        className={classes.emoji}
        src={`/images/emoji/${props.feedback.emoji}.svg`}
        alt={`emoji ${props.feedback.emoji}`}
      ></img>
    </div>
  )
}

export default withStyles(styles)(FeedbackItem)
