import React from "react"
import { Icon } from "@material-ui/core"
import { SvgNothingIcon } from "./svgr/Nothing"

function InvisibleIcon(props) {
  return (
    <Icon {...props}>
      <SvgNothingIcon />
    </Icon>
  )
}

export default InvisibleIcon
