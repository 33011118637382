import { Emoji } from "./Emoji"

const emojis: Emoji[] = [
  { name: "negative_1", fileName: "negative_1.svg" },
  { name: "negative_2", fileName: "negative_2.svg" },
  { name: "negative_3", fileName: "negative_3.svg" },
  { name: "negative_4", fileName: "negative_4.svg" },
  { name: "negative_5", fileName: "negative_5.svg" },
  { name: "negative_6", fileName: "negative_6.svg" },
  { name: "positive_1", fileName: "positive_1.svg" },
  { name: "positive_2", fileName: "positive_2.svg" },
  { name: "positive_3", fileName: "positive_3.svg" },
  { name: "positive_4", fileName: "positive_4.svg" },
  { name: "positive_5", fileName: "positive_5.svg" },
  { name: "positive_6", fileName: "positive_6.svg" },
  { name: "red_1", fileName: "red_1.svg" },
  { name: "red_2", fileName: "red_2.svg" },
  { name: "red_3", fileName: "red_3.svg" },
  { name: "red_4", fileName: "red_4.svg" },
  { name: "red_5", fileName: "red_5.svg" },
  { name: "red_6", fileName: "red_6.svg" },
  { name: "white_1", fileName: "white_1.svg" },
  { name: "white_2", fileName: "white_2.svg" },
  { name: "white_3", fileName: "white_3.svg" },
  { name: "white_4", fileName: "white_4.svg" },
  { name: "white_5", fileName: "white_5.svg" },
  { name: "white_6", fileName: "white_6.svg" },
]

export default emojis
