import axios from "axios"
import config from "../config"
import { Progress } from "../entities/Progress"
import QueryOutput from "../entities/QueryOutput"

export const getAllProgress = (): Promise<Progress[]> => {
  return axios
    .get<QueryOutput<Progress>>(`${config.api}/progress/`)
    .then((response) => response.data.Items || [])
}
