import { IVideo } from "../interfaces"

const videos: IVideo[] = [
  {
    id: "v1",
    title: "Episode 1",
    author: "Getting Started",
    progress: 0,
    src:
      "https://" +
      document.location.hostname +
      "/out/v1/7ca74bbf02b246ef93b52e8c49ed0528/edc109de588040c897f881ef0f45784b/a3e52c84444e4aa6a2101a1114f86873/index.m3u8",
    thumbnail:
      "https://" +
      document.location.hostname +
      "/ce26490d-731d-415f-ad98-4798614fb4de/thumbnails/video%201_thumb.0000001.jpg",
  },
  {
    id: "v2",
    title: "Episode 2",
    author: "Importance of Context",
    progress: 0,
    src:
      "https://" +
      document.location.hostname +
      "/out/v1/f709ade5733649d98ce3dcce9c654505/edc109de588040c897f881ef0f45784b/a3e52c84444e4aa6a2101a1114f86873/index.m3u8",
    thumbnail:
      "https://" +
      document.location.hostname +
      "/e9966785-7338-497c-b961-bbb8659e90b0/thumbnails/video%202_thumb.0000001.jpg",
  },
  {
    id: "v3",
    title: "Episode 3",
    author: "Increasing Pressure",
    progress: 0,
    src:
      "https://" +
      document.location.hostname +
      "/out/v1/c491b8502b3b497ea4e6b1bf89abcc55/edc109de588040c897f881ef0f45784b/a3e52c84444e4aa6a2101a1114f86873/index.m3u8",
    thumbnail:
      "https://" +
      document.location.hostname +
      "/acfd9903-daf9-4dcd-8b42-672f3d7e08b2/thumbnails/video%203_thumb.0000001.jpg",
  },
  {
    id: "v4",
    title: "Episode 4",
    author: "Mis-selling",
    progress: 0,
    src:
      "https://" +
      document.location.hostname +
      "/out/v1/6b5b64893fd34360a7a09b77a62cae21/edc109de588040c897f881ef0f45784b/a3e52c84444e4aa6a2101a1114f86873/index.m3u8",
    thumbnail:
      "https://" +
      document.location.hostname +
      "/7296c8c4-e795-4125-8fbe-7ce5a182e86f/thumbnails/video%204_thumb.0000001.jpg",
  },
  {
    id: "v5",
    title: "Episode 5",
    author: "The Consequences",
    progress: 0,
    src:
      "https://" +
      document.location.hostname +
      "/out/v1/f876c159f97940b9a60eed97978a1e30/edc109de588040c897f881ef0f45784b/a3e52c84444e4aa6a2101a1114f86873/index.m3u8",
    thumbnail:
      "https://" +
      document.location.hostname +
      "/45c1bbeb-6685-41a8-b7a6-435ecc16aa2e/thumbnails/video%205_thumb.0000001.jpg",
  },
  {
    id: "v6",
    title: "Episode 6",
    author: "Engineered Addiction",
    progress: 0,
    src:
      "https://" +
      document.location.hostname +
      "/out/v1/710d1ab3e3fa4983b3bbc9abdda6b4f9/edc109de588040c897f881ef0f45784b/a3e52c84444e4aa6a2101a1114f86873/index.m3u8",
    thumbnail:
      "https://" +
      document.location.hostname +
      "/b23f8be0-058a-42bb-9e96-ecaa4b6e9a13/thumbnails/video 6_thumb.0000001.jpg",
  },
]

export default videos
