import React from "react"
import Cell from "./Cell"
import { withStyles, WithStyles, createStyles, Theme } from "@material-ui/core"

const styles = (theme: Theme) =>
  createStyles<ClassKey, {}>({
    root: {
      border: "3px solid white",
      height: "60vh",
      width: "calc(60vh * 0.5)",
      display: "grid",
      gridTemplateColumns: "repeat(10, 1fr)",
      gridTemplateRows: " repeat(20, 1fr)",
      outlineStyle: "none",
      background: theme.palette.secondary.light,
    },
  })

type ClassKey = "root"
interface IProps {
  stage: any[][]
}
type PropsType = IProps & WithStyles<ClassKey>

const Stage: React.FC<PropsType> = (props) => {
  const { stage, classes } = props
  return (
    <div className={classes.root}>
      {stage.map((row) =>
        row.map((cell, x) => {
          return <Cell key={x} type={cell[0]} />
        }),
      )}
    </div>
  )
}

export default withStyles(styles)(Stage)
