import React, { useCallback } from "react"
import {
  Box,
  withStyles,
  WithStyles,
  createStyles,
  Theme,
} from "@material-ui/core"
import { useHistory } from "react-router"

import { Status } from "../../entities/Status"
import VideoThumbnail from "./VideoThumbnail"
import { IVideo } from "../../../redux/interfaces"

const styles = (theme: Theme) =>
  createStyles<ClassKey, {}>({
    root: {
      margin: 0,
      cursor: "default",
    },
    thumbnail: {
      width: "100%",
      backgroundColor: theme.palette.secondary.dark,
    },
    title: { color: theme.palette.primary.main, margin: "0" },
    speaker: { color: "#aaa", margin: "0" },
  })

type ClassKey = "root" | "title" | "thumbnail" | "speaker"

type IProps = {
  video: IVideo
  current: boolean
}
type PropsType = IProps & WithStyles<ClassKey>

const VideoCard: React.FC<PropsType> = (props: PropsType) => {
  const { video, current } = props
  const classes = props.classes

  let history = useHistory()

  const getStatus = useCallback((): Status => {
    if (current) return "current"
    return video.progress === 1 ? "watched" : "blocked"
  }, [video, current])

  function pushRoute() {
    history.push(`/fast-track/video/${video.id}`)
  }

  return (
    <Box
      className={classes.root}
      onClick={() => {
        if (current) pushRoute()
      }}
    >
      <VideoThumbnail
        id={video.id}
        status={getStatus()}
        image={video.thumbnail}
      />
    </Box>
  )
}

export default withStyles(styles)(VideoCard)
