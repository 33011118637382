import * as React from "react"

function SvgCloseIcon(props) {
  return (
    <svg
      id="closeIcon_svg__Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 48 48"
      xmlSpace="preserve"
      width="1em"
      height="1em"
      {...props}
    >
      <style>
        {".closeIcon_svg__st0{fill:none;stroke:currentColor;stroke-width:2.5}"}
      </style>
      <g
        id="closeIcon_svg__Grupo_14_00000139981696326563822300000006414467689720907917_"
        transform="translate(-1265.743 -49.743)"
      >
        <path
          id="closeIcon_svg__L\xEDnea_1_00000038377909348208666220000000584755215110238117_"
          className="closeIcon_svg__st0"
          d="M1279.9 63.5l20 20"
        />
        <path
          id="closeIcon_svg__L\xEDnea_2_00000024708237059959979820000009806664548003667868_"
          className="closeIcon_svg__st0"
          d="M1299.9 63.5l-20 20"
        />
      </g>
      <path
        d="M45.5 2.5v43h-43v-43h43M48 0H0v48h48V0z"
        stroke="currentColor"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgCloseIcon
