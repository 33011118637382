import Cookies from "universal-cookie"

const cookies = new Cookies()

export const ALLOWANCE = 20 * 60 // seconds

export const setEnd = (end?: number) => {
  if (end === undefined) {
    cookies.set("end", new Date().getTime() + ALLOWANCE * 1e3, { path: "/" })
  } else {
    cookies.set("end", end, { path: "/" })
  }
}

export const clearEnd = () => cookies.remove("end", { path: "/" })

export const getEnd = () => cookies.get("end")
