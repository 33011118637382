import React, { useState, useEffect } from "react"
import {
  withStyles,
  WithStyles,
  createStyles,
  Theme,
  Modal,
  IconButton,
} from "@material-ui/core"
import KeyboardEventHandler from "react-keyboard-event-handler"
import { createStage, checkCollision } from "./utils/gameHelpers"

// Hooks
import { usePlayer } from "./hooks/usePlayer"
import { useStage } from "./hooks/useStage"
import { useInterval } from "./hooks/useInterval"
import { useGameStatus } from "./hooks/useGameStatus"

// Components
import Stage from "./components/Stage"
import GameMessageBox from "../components/GameMessageBox"
import CloseIcon from "../../common/components/icons/CloseIcon"
import { useHistory } from "react-router"

// App state
import { RootState } from "../../redux/store"
import { useAppSelector, useAppDispatch } from "../../redux/hooks"
import { progressGame, refreshGameProgress } from "../../redux/games/GameSlice"
import useOutcome from "../hooks/useOutcome"

const styles = (theme: Theme) =>
  createStyles<ClassKey, {}>({
    root: {
      display: "flex",
      fontFamily: "'Press Start 2P'",
      background: theme.palette.secondary.dark,
      backgroundImage: 'url("/images/background/broken_red.png")',
      width: "100%",
      height: "100%",
      flexDirection: "column",
    },
    modal: {
      display: "flex",
      width: "100%",
      height: "100%",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      background: "transparent",
    },
    close: {
      marginLeft: "auto",
      "& *": {
        color: theme.palette.primary.main,
        fontSize: "3rem",
      },
    },
    container: {
      height: "calc(60vh + 130px)",
      width: "50vh",
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      display: "grid",
      gridTemplateRows: "45px 60vh 45px",
      rowGap: "25px",
    },
    header: {
      border: "3px solid white",
      backgroundColor: theme.palette.primary.main,
      display: "flex",
      justifyContent: "space-around",
      alignItems: "center",
      color: "white",
      fontSize: "1em",
      width: "100%",
      justifySelf: "center",
    },
    stage: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  })

interface IProps {}
type ClassKey = "root" | "modal" | "close" | "container" | "header" | "stage"
type PropsType = IProps & WithStyles<ClassKey>

const Tetris: React.FC<PropsType> = (props) => {
  const { classes } = props
  const [dropTime, setDropTime] = useState<number | null>(null)
  const [gameOver, setGameOver] = useState(false)
  const [isPlaying, setIsPlaying] = useState(false)
  const { target, best, outcomeId } = useAppSelector(
    (state: RootState) => state.gamesState.tetris,
  )
  const history = useHistory()
  const dispatch = useAppDispatch()
  const outcome = useOutcome(outcomeId)

  const [player, updatePlayerPos, resetPlayer, playerRotate] = usePlayer()
  const [stage, setStage, rowsCleared] = useStage(player, resetPlayer)
  const [score, setScore, rows, setRows, level, setLevel] = useGameStatus(
    rowsCleared,
  )

  const movePlayer = (dir) => {
    if (!checkCollision(player, stage, { x: dir, y: 0 })) {
      updatePlayerPos({ x: dir, y: 0 })
    }
  }

  const restartGame = () => {
    // Reset Game
    setStage(createStage())
    setDropTime(300)
    resetPlayer()
    setGameOver(false)
    setScore(0)
    setRows(0)
    setLevel(0)
    setIsPlaying(true)
  }

  const drop = () => {
    if (rows > (level + 1) * 3) {
      setLevel((prev) => prev + 1)
      setDropTime(300 / (level + 1) + 200)
    }
    if (!checkCollision(player, stage, { x: 0, y: 1 })) {
      updatePlayerPos({ x: 0, y: 1, collided: false })
    } else {
      if (player.pos.y < 1) {
        setGameOver(true)
        setDropTime(null)
      }
      updatePlayerPos({ x: 0, y: 0, collided: true })
    }
  }

  const keyUp = () => {
    if (!gameOver) {
      setDropTime(300 / (level + 1) + 200)
    }
  }

  const dropPlayer = () => {
    setDropTime(null)
    drop()
  }

  const move = (keyCode) => {
    if (!gameOver) {
      if (keyCode === "left") {
        movePlayer(-1)
        keyUp()
      } else if (keyCode === "right") {
        movePlayer(1)
        keyUp()
      } else if (keyCode === "down") {
        dropPlayer()
        keyUp()
      } else if (keyCode === "up") {
        playerRotate(stage, 1)
        keyUp()
      }
    }
  }

  useInterval(() => {
    drop()
  }, dropTime)

  useEffect(() => {
    dispatch(refreshGameProgress("tetris"))
  }, [dispatch])

  useEffect(() => {
    if (score > best) dispatch(progressGame({ game: "tetris", score }))
  }, [score, best, dispatch])

  const onClose = () => {
    history.push("/fast-track/")
  }

  const renderResultDialog = () => {
    if (target && score >= target) {
      return (
        <GameMessageBox
          message="WELL DONE"
          actions={[
            {
              label: "THANKS!",
              onClick: () => {
                if (outcome) outcome.onWin()
                else onClose()
              },
            },
            {
              label: "KEEP PLAYING",
              onClick: () => {
                if (outcome) outcome.onWin()
                restartGame()
              },
            },
          ]}
        />
      )
    } else if (score > best)
      return (
        <GameMessageBox
          message="NEW TOP SCORE!"
          actions={[{ label: "I CAN DO BETTER", onClick: restartGame }]}
        />
      )
    else
      return (
        <GameMessageBox
          message="GAME OVER!"
          actions={[
            {
              label: "TRY AGAIN",
              onClick: restartGame,
            },
            {
              label: "EXIT",
              onClick: () => {
                if (outcome?.onLoose !== undefined) outcome.onLoose()
                onClose()
              },
            },
          ]}
        />
      )
  }

  return (
    <div className={classes.root}>
      <IconButton
        aria-label="close"
        onClick={() => {
          if (outcome !== undefined) outcome.onLoose()
          onClose()
        }}
        className={classes.close}
      >
        <CloseIcon />
      </IconButton>
      <KeyboardEventHandler
        handleKeys={["left", "right", "up", "down"]}
        onKeyEvent={(key, _) => move(key)}
      />
      <div className={classes.container}>
        <div className={classes.header}>
          <span>TOP SCORE: {best}</span>
          {!!target && <span>TARGET: {target}</span>}
        </div>
        <div className={classes.stage}>
          <Stage stage={stage} />
        </div>
        <div className={classes.header}>
          <span>LEVEL: {level}</span>
          <span>SCORE: {score}</span>
        </div>
      </div>
      <Modal open={isPlaying && gameOver} className={classes.modal}>
        {renderResultDialog()}
      </Modal>
      <Modal open={!isPlaying} className={classes.modal}>
        <GameMessageBox
          message="Up for a challenge?"
          actions={[{ label: "START", onClick: restartGame }]}
        />
      </Modal>
    </div>
  )
}

export default withStyles(styles)(Tetris)
