import React from "react"
import { withStyles, WithStyles, createStyles, Theme } from "@material-ui/core"
import AlertIcon from "../../common/components/icons/AlertIcon"
import IAction from "../entities/Action"

const styles = (theme: Theme) =>
  createStyles<ClassKey, {}>({
    root: {
      position: "absolute",
      left: 0,
      top: 0,
      height: "100vh",
      width: "100vw",
      maxWidth: theme.breakpoints.values.md,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      [theme.breakpoints.up("md")]: {
        margin: `0 calc((100% - ${theme.breakpoints.values.md}px) / 2)`,
      },
    },
    box: {
      display: "flex",
      justifyContent: "space-around",
      alignItems: "center",
      flexDirection: "column",
      background: "#111",
      minHeight: "30%",
      width: "50%",
      border: `2px ${theme.palette.primary.main} solid`,
      boxShadow: "4px 4px red",
      padding: "1rem 3rem",
      [theme.breakpoints.down("sm")]: {
        width: "80%",
        padding: "0.25rem 0.5rem",
      },
    },
    content: {
      color: theme.palette.primary.contrastText,
      fontFamily: "Roboto Mono, Consolas, Courier New",
      textAlign: "center",
      paddingTop: "1rem",
      paddingBottom: "1rem",
    },
    button: {
      // fontFamily: "Roboto Mono, Consolas, Courier New",
      fontFamily: "Roboto Mono, Consolas, Courier New",
      fontWeight: "bold",
      border: `2px ${theme.palette.primary.main} solid`,
      boxShadow: `4px 4px ${theme.palette.primary.main}`,
      padding: "0.5rem 1rem",
      cursor: "pointer",
      "&:hover": {
        color: theme.palette.secondary.contrastText,
        border: `2px ${theme.palette.primary.contrastText} solid`,
        background: theme.palette.primary.main,
      },
      [theme.breakpoints.down("sm")]: {
        padding: "0.25rem 0.5rem",
      },
    },
    buttons: {
      display: "flex",
      width: "100%",
      justifyContent: "space-around",
    },
    icon: {
      fontSize: "4rem",
      [theme.breakpoints.down("sm")]: {
        fontSize: "2rem",
      },
    },
  })

interface IProps {
  title?: string
  textStyle?: React.CSSProperties
  actions: IAction[]
}
type ClassKey = "root" | "box" | "content" | "button" | "buttons" | "icon"
type PropsType = IProps & WithStyles<ClassKey>

const MessageBox: React.FC<PropsType> = (props) => {
  const { classes, actions, title } = props

  return (
    <div className={classes.root}>
      <div className={classes.box}>
        <AlertIcon className={classes.icon} />
        {title && <div className={classes.content}>{title}</div>}
        <div className={classes.content} style={props.textStyle}>
          {props.children}
        </div>
        <div className={classes.buttons}>
          {actions.map((a, i) => (
            <div
              key={`action-${i}`}
              className={classes.button}
              onClick={a.onClick}
            >
              {a.label}
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default withStyles(styles)(MessageBox)
