import React from "react"
import { Icon } from "@material-ui/core"
import Close from "./svgr/Close"

function CloseIcon(props) {
  return (
    <Icon {...props}>
      <Close />
    </Icon>
  )
}

export default CloseIcon
