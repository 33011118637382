import React, { useEffect, useMemo, useRef } from "react"
import { withStyles, createStyles, Theme, WithStyles } from "@material-ui/core"
import { IInterview, IVideo } from "../../redux/interfaces"
import { useAppDispatch } from "../../redux/hooks"
import { refreshProgress } from "../../redux/library/LibrarySlice"
import TrailerStep from "../../experiential/components/ExperientialJourney/pathSteps/TrailerStep"
import InterviewStep from "../../experiential/components/ExperientialJourney/pathSteps/InterviewStep"
import NextStep from "../../experiential/components/ExperientialJourney/pathSteps/NextStep"
import FutureStep from "../../experiential/components/ExperientialJourney/pathSteps/FutureStep"
import ModalBox from "./ModalBox"
import SelectInterview from "../../experiential/components/ExperientialJourney/SelectInterview"
import BurstPlayer from "../../experiential/components/Player/BurstPlayer"
import isNoInterviewSelected from "../../experiential/utils/isNoInterviewSelected"

const styles = (theme: Theme) =>
  createStyles<ClassKey, {}>({
    root: {
      height: "100%",
      overflow: "hidden",
      display: "flex",
      flexDirection: "column",
    },
    path: {
      overflow: "auto",
      flex: 1,
    },
    icon: {
      color: "black",
      fontSize: "3rem",
    },
  })

type ClassKey = "root" | "path" | "icon"
type IProps = {
  interviews: IInterview[]
  overlay: any
  setOverlay: React.Dispatch<React.SetStateAction<any>>
  playInterview: (interviewId: string) => void
  playTrailer: () => void
  trailer?: IVideo
  colorBubble: string
  colorProgress: string
  progressIdShouldInclude: string
}
type PropsType = WithStyles<ClassKey> & IProps

const Journey: React.FC<PropsType> = (props: PropsType) => {
  const dispatch = useAppDispatch()
  const pathRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    dispatch(refreshProgress())
      .unwrap()
      .then((progress) => {
        if (isNoInterviewSelected(progress, props.progressIdShouldInclude)) {
          props.setOverlay({ type: "trailer" })
        }
      })
    // eslint-disable-next-line
  }, [dispatch])

  const sortedInterviews = useMemo(() => {
    const sInterviews = props.interviews.slice().sort((t1, t2) => {
      if (t1.order === undefined && t2.order === undefined) return 0
      if (t1.order === undefined) return 1
      if (t2.order === undefined) return -1
      return t1.order - t2.order
    })
    const nextIdx = sInterviews.findIndex((t) => t.order === undefined)
    if (nextIdx < 0) return sInterviews
    return sInterviews.slice(0, nextIdx + 5)
  }, [props.interviews])

  const selectedInterviews = sortedInterviews.filter(
    (i) => i.order !== undefined,
  )

  useEffect(() => {
    if (pathRef.current !== null) {
      pathRef.current.scrollBy({
        top: pathRef.current.scrollHeight,
        behavior: "smooth",
      })
    }
  }, [])

  return (
    <>
      <div className={props.classes.root}>
        <div className={props.classes.path} ref={pathRef}>
          {props.trailer && (
            <TrailerStep
              idx={0}
              watched={
                !!props.interviews.filter((i) => i.order !== undefined).length
              }
              onClick={props.playTrailer}
              title={props.trailer.title}
              author={props.trailer.author}
            />
          )}
          {sortedInterviews
            .filter((i) => i.order !== undefined)
            .map((interview, idx) => (
              <InterviewStep
                key={`step${idx}`}
                idx={idx + (props.trailer ? 1 : 0)}
                interview={interview}
                onClick={props.playInterview}
                colorBubble={props.colorBubble}
                colorProgress={props.colorProgress}
              />
            ))}
          <NextStep
            idx={selectedInterviews.length + (props.trailer ? 1 : 0)}
            onClick={() => props.setOverlay({ type: "select" })}
          />
          <FutureStep
            idx={selectedInterviews.length + (props.trailer ? 2 : 1)}
          />
          <FutureStep
            idx={selectedInterviews.length + (props.trailer ? 3 : 2)}
          />
        </div>
      </div>
      <ModalBox
        title={"Select your next listening"}
        open={props.overlay.type === "select"}
        onClose={() => props.setOverlay({ type: "hidden" })}
      >
        <SelectInterview
          onSelect={props.playInterview}
          interviews={props.interviews}
        />
      </ModalBox>
      {props.trailer && (
        <ModalBox
          title={props.trailer.title}
          subtitle={props.trailer.author}
          open={props.overlay.type === "trailer"}
          onClose={() => props.setOverlay({ type: "hidden" })}
        >
          <BurstPlayer content={props.trailer} />
        </ModalBox>
      )}
    </>
  )
}

export default withStyles(styles)(Journey)
