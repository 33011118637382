import React from "react"
import {
  withStyles,
  WithStyles,
  createStyles,
  Theme,
  Box,
} from "@material-ui/core"
import { useHistory } from "react-router"

const styles = (theme: Theme) =>
  createStyles<ClassKey, {}>({
    root: {
      cursor: "pointer",
      "&:hover": {
        transform: "scale(1.1)",
      },
    },
    thumbnail: {
      width: "100%",
    },
  })

type ClassKey = "root" | "thumbnail"
interface IProps {
  game: "tetris" | "slotMachine"
}
type PropsType = IProps & WithStyles<ClassKey>

const GameCard: React.FC<PropsType> = (props) => {
  const { game, classes } = props
  const history = useHistory()
  return (
    <Box
      className={classes.root}
      onClick={() => history.push(`/fast-track/${game}`)}
    >
      <img
        className={classes.thumbnail}
        alt={game}
        src={`/images/thumbnail/${game}.png`}
      ></img>
    </Box>
  )
}

export default withStyles(styles)(GameCard)
