import React, { useEffect } from "react"
import { withStyles, WithStyles, createStyles, Theme } from "@material-ui/core"
import { IVideo, IInterview } from "../../../redux/interfaces"
import ReactPlayer from "react-player"

let styles = (theme: Theme) =>
  createStyles<ClassKey, {}>({
    root: {
      color: theme.palette.primary.main,
      background: "none",
      display: "flex",
      flexDirection: "column",
      justifyContent: "start",
      flex: "1 1",
      height: "100%",
      "& video": {
        display: "flex",
        flex: "1 1",
      },
    },
  })

type ClassKey = "root"

type IProps = { content: IInterview | IVideo }

type PropsType = IProps & WithStyles<ClassKey>

const BurstPlayer: React.FC<PropsType> = (props: PropsType) => {
  const { classes, content } = props
  const [isPlaying, setIsPlaying] = React.useState(true)

  useEffect(() => {
    document.title = `AGAM21 | Experiential | Video | ${content.id}`
  }, [content.id])

  return (
    <div className={classes.root}>
      <ReactPlayer
        // light={
        //   "thumbnailSrc" in content ? content.thumbnailSrc : content.thumbnail
        // }
        playsinline={true}
        width="100%"
        height="100%"
        url={"burstSrc" in content ? content.burstSrc : content.src}
        controls={true}
        playing={isPlaying}
        config={{
          file: {
            hlsOptions: {
              xhrSetup: function (xhr, url) {
                xhr.setRequestHeader(
                  "Authorization",
                  `Bearer ${localStorage.getItem("accessToken")}`,
                )
              },
            },
          },
        }}
        onPause={() => setIsPlaying(false)}
        onEnded={() => setIsPlaying(false)}
        onPlay={() => setIsPlaying(true)}
        onClick={() => setIsPlaying(!isPlaying)}
      ></ReactPlayer>
    </div>
  )
}

export default withStyles(styles)(BurstPlayer)
