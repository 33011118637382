import { IVideo } from "../interfaces"

const trailer: IVideo = {
  id: "trailer",
  title: "Welcome to the AGAM 21 Experiential Portal",
  author: "Developed in partnership with Arup University",
  thumbnail:
    "https://" +
    document.location.hostname +
    "/ff9e2a43-5c0b-43ac-b952-6e02e529b5b5/thumbnails/introduction_thumb.0000010.jpg",
  src:
    "https://" +
    document.location.hostname +
    "/out/v1/36a5335070e74e6aa2197f84dd366d0f/edc109de588040c897f881ef0f45784b/a3e52c84444e4aa6a2101a1114f86873/index.m3u8",
  progress: 0,
}

export default trailer
