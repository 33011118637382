import React, { useEffect } from "react"
import { withStyles, WithStyles, createStyles, Theme } from "@material-ui/core"
import { IInterview } from "../../../redux/interfaces"
import clsx from "clsx"

const styles = (theme: Theme) =>
  createStyles<ClassKey, {}>({
    track: {
      padding: ".7rem 2rem",
      cursor: "pointer",
      "&:hover": {
        background: "#ccc",
      },
    },
    greyedOut: {
      color: `#bbb !important`,
      cursor: "default",
    },
    title: {
      fontFamily: "Roboto Mono, Consolas, Courier New",
      fontWeight: "bold",
      color: theme.palette.secondary.dark,
      margin: 0,
    },
    author: {
      fontFamily: "Roboto Mono, Consolas, Courier New",
      color: theme.palette.secondary.light,
      margin: 0,
    },
  })

type ClassKey = "title" | "author" | "track" | "greyedOut"

type IProps = {
  onSelect: (interview: string) => void
  interviews: IInterview[]
}
type PropsType = IProps & WithStyles<ClassKey>

const SelectInterview: React.FC<PropsType> = (props: PropsType) => {
  const { onSelect, classes } = props

  const isPending = (interview: IInterview) => interview.order === undefined

  useEffect(() => {
    document.title = "AGAM21 | Select Track"
  })

  return (
    <>
      {props.interviews.map((interview) => (
        <div
          key={interview.id}
          className={clsx(
            classes.track,
            !isPending(interview) && classes.greyedOut,
          )}
          onClick={() => {
            if (isPending(interview)) {
              onSelect(interview.id)
            }
          }}
        >
          <p
            className={clsx(
              classes.title,
              !isPending(interview) && classes.greyedOut,
            )}
          >
            {interview.title}
          </p>
          <p
            className={clsx(
              classes.author,
              !isPending(interview) && classes.greyedOut,
            )}
          >
            {interview.author}
          </p>
        </div>
      ))}
    </>
  )
}

export default withStyles(styles)(SelectInterview)
