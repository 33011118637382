import { IAmbient } from "../interfaces"

const ambients: IAmbient[] = [
  {
    id: "1",
    backgroundSrc: "1.jpg",
    audioSrc:
      "https://" + document.location.hostname + "/hls/ambient/ambient1.m3u8",
    volume: 1,
  },
  {
    id: "2",
    backgroundSrc: "2.jpg",
    audioSrc:
      "https://" + document.location.hostname + "/hls/ambient/ambient2.m3u8",
    volume: 1,
  },
  {
    id: "3",
    backgroundSrc: "3.jpg",
    audioSrc:
      "https://" + document.location.hostname + "/hls/ambient/ambient3.m3u8",
    volume: 1,
  },
  {
    id: "4",
    backgroundSrc: "4.jpg",
    audioSrc:
      "https://" + document.location.hostname + "/hls/ambient/ambient4.m3u8",
    volume: 1,
  },
  {
    id: "5",
    backgroundSrc: "5.jpg",
    audioSrc:
      "https://" + document.location.hostname + "/hls/ambient/ambient5.m3u8",
    volume: 1,
  },
  {
    id: "6",
    backgroundSrc: "6.jpg",
    audioSrc:
      "https://" + document.location.hostname + "/hls/ambient/ambient6.m3u8",
    volume: 1,
  },
  {
    id: "7",
    backgroundSrc: "7.jpg",
    audioSrc:
      "https://" + document.location.hostname + "/hls/ambient/ambient7.m3u8",
    volume: 1,
  },
  {
    id: "8",
    backgroundSrc: "8.jpg",
    audioSrc:
      "https://" + document.location.hostname + "/hls/ambient/ambient8.m3u8",
    volume: 1,
  },
  {
    id: "9",
    backgroundSrc: "9.jpg",
    audioSrc:
      "https://" + document.location.hostname + "/hls/ambient/ambient9.m3u8",
    volume: 1,
  },
  {
    id: "10",
    backgroundSrc: "10.jpg",
    audioSrc:
      "https://" + document.location.hostname + "/hls/ambient/ambient10.m3u8",
    volume: 1,
  },
  {
    id: "11",
    backgroundSrc: "11.jpg",
    audioSrc:
      "https://" + document.location.hostname + "/hls/ambient/ambient11.m3u8",
    volume: 1,
  },
  {
    id: "12",
    backgroundSrc: "12.jpg",
    audioSrc:
      "https://" + document.location.hostname + "/hls/ambient/ambient12.m3u8",
    volume: 1,
  },
  {
    id: "13",
    backgroundSrc: "13.jpg",
    audioSrc:
      "https://" + document.location.hostname + "/hls/ambient/ambient13.m3u8",
    volume: 1,
  },
  {
    id: "14",
    backgroundSrc: "14.jpg",
    audioSrc:
      "https://" + document.location.hostname + "/hls/ambient/ambient14.m3u8",
    volume: 1,
  },
  {
    id: "15",
    backgroundSrc: "15.jpg",
    audioSrc:
      "https://" + document.location.hostname + "/hls/ambient/ambient15.m3u8",
    volume: 1,
  },
  {
    id: "16",
    backgroundSrc: "16.jpg",
    audioSrc:
      "https://" + document.location.hostname + "/hls/ambient/ambient16.m3u8",
    volume: 1,
  },
  {
    id: "17",
    backgroundSrc: "17.jpg",
    audioSrc:
      "https://" + document.location.hostname + "/hls/ambient/ambient17.m3u8",
    volume: 1,
  },
  {
    id: "18",
    backgroundSrc: "18.jpg",
    audioSrc:
      "https://" + document.location.hostname + "/hls/ambient/ambient18.m3u8",
    volume: 1,
  },
  {
    id: "19",
    backgroundSrc: "19.jpg",
    audioSrc:
      "https://" + document.location.hostname + "/hls/ambient/ambient19.m3u8",
    volume: 1,
  },
]

export default ambients
