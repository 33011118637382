import React from "react"
import { Icon } from "@material-ui/core"
import PlayBox from "./svgr/PlayBox"

function PlayIcon(props) {
  return (
    <Icon {...props}>
      <PlayBox />
    </Icon>
  )
}

export default PlayIcon
