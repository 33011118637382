import React from "react"
import {
  withStyles,
  WithStyles,
  createStyles,
  Theme,
  Button,
} from "@material-ui/core"

const styles = (theme: Theme) =>
  createStyles<ClassKey, {}>({
    footer: {},
    info: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      fontFamily: "Tourney",
      color: theme.palette.primary.main,
    },
    title: {
      margin: "0",
      color: theme.palette.primary.main,
      fontSize: "3.5rem",
      marginRight: "1.5rem",
    },
    container: {
      width: "100%",
      height: "1rem",
      background: "rgba(255,255,255, 0.1)",
    },
    flex: {
      display: "flex",
      flexDirection: "row",
      alignItems: "flex-end",
    },
    inline: {
      fontFamily: "Tourney, Courier",
      fontWeight: "bold",
      fontSize: "1rem",
      alignSelf: "center",
    },
    filled: {
      height: "100%",
      background: theme.palette.primary.main,
      opacity: 1,
    },
  })

type ClassKey =
  | "footer"
  | "title"
  | "info"
  | "container"
  | "flex"
  | "inline"
  | "filled"

type IProps = {
  progress: number
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  restart: () => void
}
type PropsType = IProps & WithStyles<ClassKey>

const Progress: React.FC<PropsType> = (props: PropsType) => {
  const { footer, info, title, container, flex, inline, filled } = props.classes
  const { open, setOpen, restart } = props

  const progressPc = Math.ceil(props.progress * 100)

  return (
    <div className={footer}>
      <div className={info}>
        <div className={flex}>
          <h1 className={title}> {progressPc}% </h1>
          <span className={inline}>
            Total Progress <br /> of the videogame
          </span>
        </div>
        <div>
          {progressPc === 100 && (
            <Button
              variant="outlined"
              color="primary"
              onClick={restart}
              style={{ marginRight: "1rem" }}
            >
              start again
            </Button>
          )}
          <Button
            variant="outlined"
            color="primary"
            onClick={() => setOpen(!open)}
          >
            {"feedback"}
          </Button>
        </div>
      </div>
      <div className={container}>
        <div
          className={filled}
          style={{
            width: `${progressPc}%`,
          }}
        ></div>
      </div>
    </div>
  )
}

export default withStyles(styles)(Progress)
