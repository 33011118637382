import React, { useCallback, useEffect, useRef } from "react"
import {
  withStyles,
  WithStyles,
  createStyles,
  Theme,
  IconButton,
} from "@material-ui/core"
import ReactPlayer from "react-player"

// Interfaces
import { IVideo } from "../../redux/interfaces"
import IEvent from "../entities/Event"

// State
import { RouteComponentProps, useHistory } from "react-router"
import { useAppDispatch, useAppSelector } from "../../redux/hooks"
import { progressVideo } from "../../redux/library/LibrarySlice"

// Components
import Frame from "../../common/components/Frame/Frame"
import CloseIcon from "../../common/components/icons/CloseIcon"
import PlayBoxIcon from "../../common/components/icons/PlayBoxIcon"
import PauseIcon from "../../common/components/icons/PauseIcon"
import MessageBox from "./MessageBox"
import useEvents from "../hooks/useEvents"

let styles = (theme: Theme) =>
  createStyles<ClassKey, {}>({
    root: {
      color: theme.palette.primary.main,
      display: "flex",
      flexDirection: "column",
      justifyContent: "start",
      maxWidth: 800,
      margin: "auto",
      "& video": {
        display: "flex",
        width: "100%",
      },
      padding: "1rem 2rem",
    },
    icon: {
      paddingLeft: 0,
      color: theme.palette.primary.main,
      fontSize: "3rem",
    },
    header: {
      fontFamily: "Digitek, Tourney",
      fontSize: "1.5rem",
      lineHeight: "1.5rem",
      display: "flex",
      alignItems: "center",
      flexGrow: 1,
      justifyContent: "space-between",
    },
    controls: {
      display: "flex",
      alignItems: "center",
      flexGrow: 1,
      margin: "0 1rem",
    },
    timer: {
      fontFamily: "Tourney",
      fontSize: "2.5rem",
      fontWeight: "bold",
      display: "block",
      height: 0,
      width: "10rem",
      position: "relative",
      left: "calc(100% - 8rem)",
      top: "-2rem",
      zIndex: 100,
    },
    title: {
      fontFamily: "Roboto Mono, Consolas, Courier New",
      color: theme.palette.primary.main,
      marginBottom: 0,
    },
    author: {
      fontFamily: "Roboto Mono, Consolas, Courier New",
      color: theme.palette.secondary.light,
      marginTop: 0,
    },
    track: {
      width: "100%",
      height: "5px",
      background: theme.palette.secondary.main,
    },
    progress: {
      height: "5px",
      background: theme.palette.primary.main,
    },
  })

type ClassKey =
  | "root"
  | "header"
  | "controls"
  | "timer"
  | "icon"
  | "title"
  | "author"
  | "track"
  | "progress"

type IMatchProps = {
  videoId: string
}

interface IProgress {
  played: number
  playedSeconds?: number
}

type IProps = {}

type PropsType = RouteComponentProps<IMatchProps> &
  IProps &
  WithStyles<ClassKey>

const FastTrackPlayer: React.FC<PropsType> = (props: PropsType) => {
  const { classes } = props
  const player = useRef<ReactPlayer | null>(null)
  const [event, setEvent] = React.useState<IEvent | null>(null)
  const [isPlaying, setIsPlaying] = React.useState(false)
  const [eventAtHistory, setEventAtHistory] = React.useState<number[]>([])
  const [progress, setProgress] = React.useState<IProgress>({
    played: 0,
    playedSeconds: 0,
  })
  const history = useHistory()
  const dispatch = useAppDispatch()

  const video: IVideo = useAppSelector(
    (store) =>
      store.libraryState.videos.find(
        (v) => v.id === props.match.params.videoId,
      ) || store.libraryState.videos[0],
  )

  const formatProgress = useCallback((progress: number) => {
    const minutes = Math.floor(progress / 60).toLocaleString(undefined, {
      maximumFractionDigits: 0,
      minimumIntegerDigits: 2,
    })
    const seconds = Math.floor(progress % 60).toLocaleString(undefined, {
      maximumFractionDigits: 0,
      minimumIntegerDigits: 2,
    })
    return `${minutes}:${seconds}`
  }, [])

  const scheduleDelayedEvent = useCallback(
    (event: IEvent | (() => IEvent), delay: number) => {
      const askQuestion = () => {
        setIsPlaying(false)
        setEvent(typeof event === "function" ? event() : event)
      }
      setTimeout(() => {
        askQuestion()
      }, delay)
    },
    [],
  )

  const eventLibrary = useEvents(video.id, {
    resume: () => {
      setEvent(null)
      setIsPlaying(true)
    },
    schedule: scheduleDelayedEvent,
  })

  const renderMessageBox = (event: IEvent) => {
    return (
      event !== undefined && (
        <MessageBox
          actions={event.actions}
          textStyle={event.textStyle}
          title={event.title}
        >
          {event.text}
        </MessageBox>
      )
    )
  }

  useEffect(() => {
    document.title = `AGAM21 | Fast Track | Video | ${video.id}`
  }, [video.id])

  return (
    <>
      <Frame>
        <div className={classes.root}>
          <div className={classes.header}>
            <h1> fast track </h1>
            <IconButton
              aria-label="close"
              style={{ justifySelf: "end" }}
              color="primary"
              onClick={() => history.push("/fast-track/")}
            >
              <CloseIcon className={classes.icon} />
            </IconButton>
          </div>
          <div className={classes.controls}>
            <IconButton
              aria-label="play"
              color="primary"
              onClick={() => setIsPlaying(!isPlaying)}
            >
              {isPlaying ? (
                <PauseIcon className={classes.icon} />
              ) : (
                <PlayBoxIcon className={classes.icon} />
              )}
            </IconButton>
            <div>
              <h2 className={classes.title}> {video?.title} </h2>
              <h3 className={classes.author}> {video?.author} </h3>
            </div>
          </div>
          <div onClick={() => setIsPlaying(!isPlaying)}>
            <div className={classes.timer}>
              {formatProgress(progress.playedSeconds || 0)}
            </div>
            <ReactPlayer
              ref={player}
              url={video.src}
              height="100%"
              width="100%"
              muted={false}
              controls={window.location.hostname === "localhost"}
              playing={isPlaying}
              progressInterval={1e3}
              config={{
                file: {
                  hlsOptions: {
                    xhrSetup: function (xhr, url) {
                      xhr.setRequestHeader(
                        "Authorization",
                        `Bearer ${localStorage.getItem("accessToken")}`,
                      )
                    },
                  },
                },
              }}
              onDuration={(duration) => {
                if (player.current) {
                  player.current.seekTo(video.progress * duration, "seconds")
                  setIsPlaying(true)
                }
              }}
              onPause={() => setIsPlaying(false)}
              onPlay={() => setIsPlaying(true)}
              onProgress={(p: IProgress) => {
                setProgress({
                  played: p.played,
                  playedSeconds: p.playedSeconds || 0,
                })
                const eventLibItem = eventLibrary[video.id].find(
                  (e) => e.at === Math.floor(p.playedSeconds || 0),
                )
                const eventItem = eventLibItem
                if (eventItem && !eventAtHistory.includes(eventItem.at!)) {
                  setIsPlaying(false)
                  setEvent(eventItem.event)
                  setEventAtHistory((h) => [...h, eventItem.at!])
                }
              }}
              onEnded={() => {
                setIsPlaying(false)
                dispatch(
                  progressVideo({
                    mediaId: video.id,
                    progress: 1,
                  }),
                ).then((_) => history.push("/fast-track/"))
              }}
            />
          </div>
          <div className={classes.track}>
            <div
              className={classes.progress}
              style={{
                width: `${progress.played * 100}%`,
              }}
            ></div>
          </div>
          {event !== null && renderMessageBox(event)}
        </div>
      </Frame>
    </>
  )
}

export default withStyles(styles)(FastTrackPlayer)
