import {
  createStyles,
  IconButton,
  Theme,
  WithStyles,
  withStyles,
} from "@material-ui/core"
import React from "react"
import { Carousel } from "react-responsive-carousel"
import "react-responsive-carousel/lib/styles/carousel.min.css" // requires a loader
import { useHistory } from "react-router"
import CloseIcon from "../common/components/icons/CloseIcon"
import "./carousel-override.css"

const styles = () =>
  createStyles<ClassKey, {}>({
    root: {
      height: "100%",
    },
    body: {
      padding: "0rem 1rem",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      width: "100%",
      overflowX: "hidden",
    },
    paragraph: {
      maxWidth: "40rem",
      color: "black",
      textAlign: "justify",
    },
    image: {
      width: "100%",
      maxWidth: "40rem",
    },
    titleWrapper: {
      width: "100%",
      backgroundColor: "white",
      display: "flex",
      justifyContent: "center",
      padding: "1rem 1rem",
    },
    title: {
      fontSize: "3rem",
      fontFamily: "Cooper Black, Tourney",
      color: "black",
      width: "35.5rem",
    },
    subtitle: {
      fontSize: "1rem",
      fontWeight: "bold",
      color: "black",
      padding: "1rem 0rem",
      width: "100%",
      maxWidth: "40rem",
    },
    icon: {
      color: "black",
      fontSize: "3rem",
      heigth: "3rem",
      width: "3rem",
    },
  })

type ClassKey =
  | "root"
  | "body"
  | "paragraph"
  | "image"
  | "title"
  | "subtitle"
  | "icon"
  | "titleWrapper"
type PropsType = WithStyles<ClassKey>

const ProjectCharlotteRoot: React.FC<PropsType> = (props: PropsType) => {
  const history = useHistory()

  return (
    <div className={props.classes.root}>
      <div className={props.classes.titleWrapper}>
        <div className={props.classes.title}>Charlotte</div>
        <IconButton
          aria-label="close"
          style={{ justifySelf: "end" }}
          color="secondary"
          onClick={() => history.push("/")}
        >
          <CloseIcon className={props.classes.icon} />
        </IconButton>
      </div>
      <div className={props.classes.body}>
        <div className={props.classes.subtitle}>
          Synthetic agency for user emphatic engagement, through live data
        </div>
        <p className={props.classes.paragraph}>
          Powered by Arup’s Property Insight, Project Charlotte is the first of
          its kind. It is a new concept of digital twin, where IoT, control
          systems and building performance data, are transformed, through a
          creative and imaginative design, into an alternative virtual world.
        </p>
        <p className={props.classes.paragraph}>
          At the strike of the hour, the 80 Charlotte Street auditorium screen,
          located in the new Arup London office, attracts surrounding users by
          opening a portal, introducing Charlotte, a “super cute” fictional
          character, our new office mascot.
        </p>
        <p className={props.classes.paragraph}>
          This installation is a technical feast, including anamorphic
          projections, real time data acquisition and processing, generative
          graphics, particle systems. But there is more to it.
        </p>
        <p className={props.classes.paragraph}>
          The building telemetry, including data such as temperature, humidity,
          noise levels, number of people present, to name a few, influences the
          environment and the emotions that are displayed on the screen: low air
          pressure affects the weather, while temperature, noise levels and
          relative humidity modify Charlotte phenotype, the number of people in
          the office determines Charlotte’s mood, happy, sad. It is a digital
          twin of sort. Distorted, but overcharged with empathy, designed to
          establish an emotional connection between users and Charlotte.
        </p>
        <p className={props.classes.paragraph}>
          It is easy to imagine how tracking the data over time could be used to
          change the virtual world progressively, demonstrating the impact of
          users behaviours on the environment through the metaphorical world of
          Charlotte, and thus driving change, by leveraging users’ emotional
          connection.
        </p>
        <p className={props.classes.paragraph}>
          As we are set to design for a sustainable development, it is
          lapalissian how the paradigm underpinning Project Charlotte could be
          reconfigured within the most appropriate format, to give our built
          environment a digital soul. To nudge users in doing the right thing.
        </p>
        <p className={props.classes.paragraph}>
          If you are interested and would like to know more on this, explore the
          underlying technical elements, from the UX design to the OSC
          messaging, or simply would like to explore the adoption of Charlotte
          in your office{" "}
          <a href="mailto:creative.influence@Arup.com">please contact us</a>.
        </p>
        <Carousel className={props.classes.image}>
          <div>
            <img src="./images/project-charlotte/main.png" alt="" />
          </div>
          <div>
            <img
              src="./images/project-charlotte/80CS update november 2022.023.png"
              alt=""
            />
          </div>
          <div>
            <img
              src="./images/project-charlotte/80CS update november 2022.027.png"
              alt=""
            />
          </div>
          <div>
            <img
              src="./images/project-charlotte/80CS update november 2022.031.png"
              alt=""
            />
          </div>
          <div>
            <img
              src="./images/project-charlotte/80CS update november 2022.034.png"
              alt=""
            />
          </div>
          <div>
            <img
              src="./images/project-charlotte/80CS update november 2022.037.png"
              alt=""
            />
          </div>
          <div>
            <img
              src="./images/project-charlotte/80CS update november 2022.039.png"
              alt=""
            />
          </div>
        </Carousel>
      </div>
    </div>
  )
}

export default withStyles(styles)(ProjectCharlotteRoot)
