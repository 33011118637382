import React, { useCallback, useEffect, useState } from "react"
import {
  withStyles,
  WithStyles,
  createStyles,
  Theme,
  IconButton,
  Modal,
} from "@material-ui/core"
import clsx from "clsx"
import CloseIcon from "../../common/components/icons/CloseIcon"
import GameMessageBox from "../components/GameMessageBox"

// Hooks
import { useHistory } from "react-router"
import useRoll from "./hooks/useRoll"

// App state
import { RootState } from "../../redux/store"
import { useAppDispatch, useAppSelector } from "../../redux/hooks"
import { progressGame, refreshGameProgress } from "../../redux/games/GameSlice"
import useOutcome from "../hooks/useOutcome"

const ICON_MAPPING = {
  ARUP: "/games/icons/slotMachine/arup.png",
  PENGUIN: "/games/icons/slotMachine/penguin.png",
  PROJECT: "/games/icons/slotMachine/project.png",
  OVE: "/games/icons/slotMachine/ove.png",
  MUSIC: "/games/icons/slotMachine/music.png",
  ENGAGE: "/games/icons/slotMachine/engage.png",
  LEVERAGE: "/games/icons/slotMachine/leverage.png",
  DISRUPT: "/games/icons/slotMachine/disrupt.png",
}

const LOSE_MESSAGES = [
  "DON'T BLAME THE CODE",
  "STOP GAMBLING",
  "HEY, YOU LOST",
  "OUCH! I FELT THAT",
  "THERE GOES THE BUDGET",
  "I HAVE A CAT. YOU HAVE A LOSS",
  "CODING IS HARD",
]

const COLUMNS = [
  ["ARUP", "PENGUIN", "PROJECT", "ENGAGE", "OVE", "MUSIC"],
  ["OVE", "LEVERAGE", "PENGUIN", "ARUP", "PROJECT", "MUSIC"],
  ["PROJECT", "DISRUPT", "MUSIC", "PENGUIN", "ARUP", "OVE"],
]

// const COLUMNS = [
//   ["ARUP", "PENGUIN"],
//   ["PENGUIN", "ARUP"],
//   ["PENGUIN", "ARUP"],
// ]

const ANIMATION_DURATION = 200 // in miliseconds

const styles = (theme: Theme) =>
  createStyles<ClassKey, {}>({
    root: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      width: "100%",
      height: "100%",
      fontFamily: "'Press Start 2P'",
      padding: "3rem",
      "& span, & button": {
        fontFamily: "'Press Start 2P'",
      },
      backgroundImage: "url(/games/images/slotMachine/SlotMachineBG.PNG)",
      fontSize: "2rem",
      [theme.breakpoints.down("md")]: {
        fontSize: "1rem",
        padding: "0.5rem",
      },
    },
    grid: {
      display: "grid",
      gridTemplateRows: "75px 300px 75px",
      rowGap: "20px",
      width: "100%",
    },
    header: {
      width: "100%",
      color: theme.palette.secondary.main,
      backgroundColor: "rgb(194, 197, 187)",
      border: "3px solid black",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      "& > span": {
        display: "flex",
        justifyContent: "center",
        flex: 1,
      },
    },
    content: {
      backgroundColor: "rgb(194, 197, 187)",
      border: "3px solid black",
      display: "grid",
      gridTemplateColumns: "repeat(3, 1fr)",
      padding: "10px",
      columnGap: "10px",
    },
    column: {
      border: "3px solid black",
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "center",
      overflow: "hidden",
    },
    slot: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      minWidth: "100%",
      minHeight: "100%",
      height: "100%",
      "& > img": {
        width: "auto",
        // width: "90%",
        height: "30%",
        position: "relative",
      },
    },
    spin: {
      position: "relative",
      animation: `$spin ${ANIMATION_DURATION}ms linear infinite`,
    },
    close: {
      "& span": {
        color: theme.palette.secondary.main,
        fontSize: "3rem",
      },
    },
    button: {
      color: theme.palette.secondary.main,
      background: "rgb(194, 197, 187)",
      border: `3px solid ${theme.palette.secondary.main}`,
      fontSize: "inherit",
      "&:hover": {
        color: "rgb(194, 197, 187)",
        background: theme.palette.secondary.main,
        border: "1px solid rgb(194, 197, 187)",
        cursor: "pointer",
      },
      "&:disabled": {
        color: theme.palette.secondary.light,
        background: "rgb(194, 197, 187)",
        border: `3px solid ${theme.palette.secondary.main}`,
        cursor: "default",
      },
    },
    modal: {
      display: "flex",
      width: "80vw",
      margin: "0 10vw",
      height: "100%",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      background: "transparent",
    },
    bgWin: {
      animation: "$bgWin 0.3s linear infinite",
    },
    "@keyframes spin": {
      "0%": {
        top: "100%",
      },
      "100%": {
        top: "-100%",
      },
    },
    "@keyframes bgWin": {
      "0%": {
        filter: "invert(0%)",
      },
      "100%": {
        filter: "invert(100%)",
      },
    },
  })

type ClassKey =
  | "root"
  | "grid"
  | "header"
  | "content"
  | "column"
  | "slot"
  | "spin"
  | "close"
  | "button"
  | "modal"
  | "bgWin"
  | "@keyframes spin"
  | "@keyframes bgWin"

interface IProps {}

type PropsType = IProps & WithStyles<ClassKey>

type IStatus = "won" | "lost" | "playing"

const SlotMachine: React.FC<PropsType> = (props) => {
  const COST = 100
  const REWARD = 5000

  const { classes } = props

  const history = useHistory()
  const dispatch = useAppDispatch()
  const { credits, outcomeId } = useAppSelector(
    (state: RootState) => state.gamesState.slotMachine,
  )
  const outcome = useOutcome(outcomeId)

  const [popup, setPopup] = useState<string | undefined>()
  const [message, setMessage] = useState("I FEEL LUCKY")
  const [status, setStatus] = useState<IStatus>("playing")
  const [spins, setSpins] = useState([false, false, false])
  const [results, resultSetters] = useRoll()
  const [isFirstGame, setIsFirstGame] = useState(true)

  const hasWon = useCallback((results) => {
    const allEqual =
      COLUMNS[0][results[0]] === COLUMNS[1][results[1]] &&
      COLUMNS[1][results[1]] === COLUMNS[2][results[2]]
    const trio =
      COLUMNS[0][results[0]] === "ENGAGE" &&
      COLUMNS[1][results[1]] === "LEVERAGE" &&
      COLUMNS[2][results[2]] === "DISRUPT"
    return allEqual || trio
  }, [])

  const isSpinning = useCallback((spins) => spins.some((s) => s), [])

  const spin = () => {
    if (!isSpinning(spins) && status === "playing" && credits >= COST) {
      // Note: state won't be updated until the end of this function's execution!
      dispatch(progressGame({ game: "slotMachine", score: credits - COST }))
      setSpins([true, true, true])

      const intervals = new Array(3).fill(0).map((_, i) =>
        setInterval(() => {
          resultSetters[i]((res) => (res + 1) % COLUMNS[i].length)
        }, ANIMATION_DURATION * 0.5),
      )

      const randBetween = (i, j) => i + Math.random() * (j - i)

      const durations = intervals.map(
        (_, i) => ANIMATION_DURATION * randBetween(3, 6) * (1 + i),
      )

      intervals.map((interval, i) =>
        setTimeout(() => {
          clearInterval(interval)
          setSpins((s) => {
            const snext = [...s]
            snext[i] = false
            return snext
          })
        }, durations[i]),
      )
    }
  }

  const onWin = () => {
    dispatch(progressGame({ game: "slotMachine", score: credits + REWARD }))
    setMessage(`YOU WON ${REWARD} CREDITS`)
    setStatus("won")
    setTimeout(() => {
      setMessage("I FEEL LUCKY")
      setStatus("playing")
      if (outcome) {
        setPopup(
          "You were lucky to win, but this is gambling, and gambling is bad. Therefore you are only allowed to continue, no extras",
        )
      }
    }, 3e3)
  }

  const onLoose = () => {
    setMessage(LOSE_MESSAGES[Math.floor(Math.random() * Math.floor(7))])
    setStatus("lost")

    setTimeout(() => {
      setMessage("I FEEL LUCKY")
      setStatus("playing")
      if (outcome && credits <= 0) {
        setPopup(
          "Gambling was a bad idea, you need to start from the beginning. All progress is lost. Next time please be careful",
        )
      }
    }, 0.75e3)
  }

  useEffect(() => {
    dispatch(refreshGameProgress("slotMachine"))
  }, [dispatch, outcomeId])

  useEffect(() => {
    if (spins.every((s) => !s) && !isFirstGame) {
      hasWon(results) ? onWin() : onLoose()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [spins, hasWon])

  useEffect(() => {
    setIsFirstGame(false)
  }, [])

  const onClose = () => {
    history.push("/fast-track/")
  }

  return (
    <div className={clsx(classes.root, status === "won" && classes.bgWin)}>
      <div className={classes.grid}>
        <div className={classes.header}>
          <span>YOUR CREDITS: {credits}</span>
          <IconButton
            aria-label="close"
            onClick={() => {
              if (outcome !== undefined) {
                outcome.onLoose()
              }
              onClose()
            }}
            className={classes.close}
          >
            <CloseIcon />
          </IconButton>
        </div>
        <div className={classes.content}>
          {results.map((ri, i) => (
            <div className={classes.column} key={`slot${i}`}>
              <div className={clsx(classes.slot, spins[i] && classes.spin)}>
                <img src={ICON_MAPPING[COLUMNS[i][ri]]} alt={COLUMNS[i][ri]} />
              </div>
            </div>
          ))}
        </div>
        <button
          className={classes.button}
          onClick={spin}
          disabled={isSpinning(spins) || status !== "playing"}
        >
          {credits < 100 ? "NOT ENOUGH CREDITS" : message}
        </button>
      </div>
      {outcome !== undefined && popup !== undefined && (
        <Modal open={true} className={classes.modal}>
          <GameMessageBox
            message={popup!}
            actions={[
              {
                label: "OK",
                onClick: () => {
                  if (credits <= 0) {
                    outcome!.onLoose()
                  } else {
                    outcome!.onWin()
                  }
                },
              },
            ]}
          />
        </Modal>
      )}
    </div>
  )
}

export default withStyles(styles)(SlotMachine)
