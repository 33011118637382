import React from "react"
import { Icon } from "@material-ui/core"
import Alert from "./svgr/Alert"

function AlertIcon(props) {
  return (
    <Icon {...props}>
      <Alert />
    </Icon>
  )
}

export default AlertIcon
