import React, { useState, useCallback, useEffect } from "react"
import {
  withStyles,
  WithStyles,
  createStyles,
  Theme,
  IconButton,
  Grid,
  Modal,
} from "@material-ui/core"

// State
import { setEnd } from "../../usecases/CountDown"
import { useAppDispatch, useAppSelector } from "../../../redux/hooks"
import {
  refreshProgress,
  progressAllVideos,
} from "../../../redux/library/LibrarySlice"
import { gameActions } from "../../../redux/games/GameSlice"

// Components
import VideoCard from "./VideoCard"
import GameCard from "./GameCard"
import CountDown from "./CountDown"
import Progress from "./Progress"
import HomeIcon from "../../../common/components/icons/HomeIcon"
import Feedback from "./Feedback"
import MessageBox from "../MessageBox"
import { useHistory } from "react-router"

const styles = (theme: Theme) =>
  createStyles<ClassKey, {}>({
    root: {
      height: "100%",
      display: "flex",
      flexDirection: "column",
      padding: "0rem 4rem 1rem 4rem",
      background: "rgba(30, 30, 30)",
      backgroundImage: 'url("/images/background/fast_track_background.jpg")',
      backgroundSize: "cover",
      color: theme.palette.primary.main,
    },
    header: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      fontFamily: "Tourney",
      marginBottom: "1rem",
    },
    title: {
      margin: "0",
      fontSize: "2.5rem",
      fontFamily: "Digitek, Tourney",
      marginRight: "1.5rem",
    },
    container: {
      width: "100%",
      height: 0,
      flex: 1,
      display: "flex",
      justifyContent: "space-between",
    },
    flexes: {
      height: "100%",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    inline: {
      marginTop: 0,
      fontSize: "1rem",
      fontWeight: 600,
    },
    icon: {
      color: theme.palette.primary.main,
      fontSize: "3rem",
    },
    grid: {
      flex: 3,
      overflow: "auto",
      alignItems: "center",
      height: "fit-content",
      maxHeight: "100%",
      flexBasis: "1rem",
    },
    feedback: {
      flex: 1,
      height: "100%",
      float: "right",
    },
  })

type ClassKey =
  | "root"
  | "title"
  | "header"
  | "container"
  | "flexes"
  | "grid"
  | "inline"
  | "icon"
  | "feedback"

type IStatus = "fail" | "success" | "ongoing"
type PropsType = WithStyles<ClassKey>

const FastTrackCatalogue: React.FC<PropsType> = (props: PropsType) => {
  const {
    root,
    header,
    title,
    container,
    flexes,
    grid,
    inline,
    feedback,
  } = props.classes

  const dispatch = useAppDispatch()
  const history = useHistory()
  const videos = useAppSelector((state) => state.libraryState.videos)
  const [open, setOpen] = useState(false)
  const [status, setStatus] = useState<IStatus>("ongoing")

  useEffect(() => {
    dispatch(refreshProgress())
  }, [dispatch])

  useEffect(() => {
    if (videos.every((v) => v.progress >= 1)) {
      setStatus("success")
      dispatch(
        gameActions.setTetrisChallenge({
          outcomeId: undefined,
          target: 0,
        }),
      )
      dispatch(
        gameActions.setSlotMachineChallenge({
          outcomeId: undefined,
          target: 0,
        }),
      )
    }
  }, [videos, dispatch])

  const restart = useCallback(() => {
    dispatch(progressAllVideos(0))
    setStatus("ongoing")
    setEnd()
  }, [dispatch])

  return (
    <div className={root}>
      <div className={header}>
        <div className={flexes}>
          <h1 className={title}> FAST TRACK </h1>
          <span className={inline}>
            Watch all videos <br />
            before time expires
          </span>
        </div>
        <div className={flexes}>
          {status === "ongoing" && (
            <CountDown
              onTimeOut={() => {
                setStatus("fail")
              }}
            />
          )}
          <IconButton
            aria-label="home"
            href="/landing"
            color="primary"
            style={{ paddingRight: 0 }}
          >
            <HomeIcon className={props.classes.icon} />
          </IconButton>
        </div>
      </div>
      <div className={container}>
        <Grid container spacing={3} className={grid}>
          {videos.map((v, idx) => (
            <Grid key={v.id} item md={open ? 4 : 4} lg={open ? 4 : 4}>
              <VideoCard
                current={
                  // If the prev. video is complete but this is not complete
                  (videos[idx - 1]?.progress === 1 && v.progress < 1) ||
                  // Or, if this is the first video and it's not complete
                  (idx === 0 && v.progress < 1)
                }
                video={v}
              />
            </Grid>
          ))}
          {status === "success" && (
            <>
              <Grid key="tetris" item md={open ? 4 : 4} lg={open ? 4 : 4}>
                <GameCard game="tetris" />
              </Grid>
              <Grid key="tetris" item md={open ? 4 : 4} lg={open ? 4 : 4}>
                <GameCard game="slotMachine" />
              </Grid>
            </>
          )}
        </Grid>
        {open && (
          <div className={feedback}>
            <Feedback setOpen={setOpen} />
          </div>
        )}
      </div>
      <Progress
        open={open}
        setOpen={setOpen}
        restart={restart}
        progress={
          videos.map((v) => v.progress).reduce((a, b) => a + b) / videos.length
        }
      />
      <Modal open={status === "fail"}>
        <MessageBox
          actions={[
            { label: "Try again", onClick: restart },
            {
              label: "Go elsewhere",
              onClick: () => {
                history.push("/experimental")
              },
            },
          ]}
        >
          {"Time's out! Game over!"}
        </MessageBox>
      </Modal>
    </div>
  )
}

export default withStyles(styles)(FastTrackCatalogue)
