import React, { useCallback, useEffect, useState } from "react"
import {
  withStyles,
  WithStyles,
  createStyles,
  Theme,
  Button,
  TextField,
} from "@material-ui/core"
import EmojiPicker from "./EmojiPicker"
import { Emoji } from "../../../common/entities/Emoji"
import { PostFeedback } from "../../../common/usecases/PostFeedback"

let styles = (theme: Theme) =>
  createStyles<ClassKey, {}>({
    root: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      overflow: "auto",
      height: "100%",
      fontFamily: "Roboto Mono, Consolas, Courier New",
    },
    form: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-end",
      padding: "1.5rem",
      flex: 1,
      "& > *": {
        marginBottom: "2rem",
      },
    },
    preview: {
      flex: 1,
      color: theme.palette.secondary.main,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      "& > img": {
        width: "3rem",
        paddingTop: "1.5rem",
      },
    },
  })

type ClassKey = "root" | "form" | "preview"

type IProps = {
  onClose: () => void
  mediaId: string
}

type PropsType = IProps & WithStyles<ClassKey>

const InterviewFeedback: React.FC<PropsType> = (props: PropsType) => {
  const { classes, mediaId, onClose } = props
  const [message, setMessage] = useState<string>("")
  const [selectedEmoji, setSelectedEmoji] = useState<Emoji | null>(null)
  const [validated, setValidated] = useState(false)

  useEffect(() => {
    setValidated(message !== "" && selectedEmoji !== null)
  }, [message, selectedEmoji])

  const submit = useCallback(
    (message, selectedEmoji) =>
      PostFeedback({
        message: message,
        emoji: selectedEmoji!!.name,
        mediaId: mediaId,
      })
        .catch((err) => alert(err))
        .then(() => onClose()),
    [mediaId, onClose],
  )

  return (
    <div className={classes.root}>
      <form className={classes.form} autoComplete="off">
        <div className={classes.preview}>
          <span>{message}</span>
          {selectedEmoji && (
            <img
              alt={selectedEmoji?.name}
              src={`/images/emoji/${selectedEmoji?.fileName}`}
            ></img>
          )}
        </div>
        <TextField
          id="feedback-message"
          label="Leave us some feedback!"
          variant="outlined"
          multiline={true}
          value={message}
          onChange={(event) => setMessage(event.target.value)}
          autoFocus={true}
        />

        <EmojiPicker
          onEmojiSelected={(emoji) => setSelectedEmoji(emoji)}
        ></EmojiPicker>

        <Button
          variant="outlined"
          color="secondary"
          disabled={!validated}
          onClick={() => submit(message, selectedEmoji)}
        >
          Submit
        </Button>
      </form>
    </div>
  )
}

export default withStyles(styles)(InterviewFeedback)
