import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import jwt from "jsonwebtoken"

type idToken = string

interface IUser {
  idToken: idToken
  email: string
  name: string
}
interface ISlice {
  currentUser?: IUser
}

const sliceInitialState: ISlice = {
  currentUser: undefined,
}

const customSlice = createSlice({
  name: "user",
  initialState: sliceInitialState,
  reducers: {
    login: (state, action: PayloadAction<idToken>) => {
      const decoded: { [key: string]: string } = jwt.decode(action.payload)
      const user: IUser = {
        idToken: action.payload,
        email: decoded?.email,
        name: decoded?.name,
      }
      return {
        ...state,
        currentUser: user,
      }
    },
  },
})

export const userActions = customSlice.actions
export default customSlice.reducer
