import React, { useState, useEffect, useCallback } from "react"
import { withStyles, WithStyles, createStyles, Theme } from "@material-ui/core"
import { setEnd, getEnd, clearEnd, ALLOWANCE } from "../../usecases/CountDown"

const styles = (theme: Theme) =>
  createStyles<ClassKey, {}>({
    root: {
      fontFamily: "Tourney",
      fontSize: "3rem",
      marginRight: "6rem",
      fontWeight: "bold",
      fontVariantNumeric: "tabular-nums",
    },
  })

type ClassKey = "root"

type IProps = {
  onTimeOut: () => void
}
type PropsType = IProps & WithStyles<ClassKey>

const CountDown: React.FC<PropsType> = (props: PropsType) => {
  const { classes } = props

  useEffect(() => {
    if (getEnd() === undefined) {
      setEnd()
    }
  }, [])

  const [delta, setDelta] = useState(
    getEnd() ? getEnd() - new Date().getTime() : ALLOWANCE * 1e3,
  )

  useEffect(() => {
    const interval = setInterval(() => {
      if (getEnd() < new Date().getTime()) {
        clearEnd()
        props.onTimeOut()
      }
      if (delta > 0) {
        setDelta(() => getEnd() - new Date().getTime())
      }
    }, 1e3)
    return () => clearInterval(interval)
  })

  const renderDelta = useCallback(() => {
    return new Date(delta).toISOString().substr(11, 8)
  }, [delta])

  return <div className={classes.root}>{renderDelta()}</div>
}

export default withStyles(styles)(CountDown)
