import React from "react"
import { TETROMINOS } from "../utils/tetrominos"
import { withStyles, WithStyles, createStyles, Theme } from "@material-ui/core"

const styles = (theme: Theme) =>
  createStyles<ClassKey, {}>({
    root: {
      border: `0.1px solid rgba(255, 255, 255, 0.308)`,
    },
  })

type ClassKey = "root"
interface IProps {
  type: "string"
}
type PropsType = IProps & WithStyles<ClassKey>

const Cell: React.FC<PropsType> = (props) => {
  const { type, classes } = props
  return (
    <div
      className={classes.root}
      style={{ backgroundColor: TETROMINOS[type].color }}
    />
  )
}

export default withStyles(styles)(Cell)
