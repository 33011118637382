import * as React from "react"

export function SvgNothingIcon(props) {
  return (
    <svg
      id="closeIcon_svg__Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 48 48"
      xmlSpace="preserve"
      width="1em"
      height="1em"
      {...props}
    ></svg>
  )
}

