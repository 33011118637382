import React, { useEffect, useState } from "react"
import { withStyles, WithStyles, createStyles, Theme } from "@material-ui/core"
import emojis from "../../../common/entities/EmojiList"
import cslx from "clsx"
import { Emoji } from "../../../common/entities/Emoji"

let styles = (theme: Theme) =>
  createStyles<ClassKey, {}>({
    root: {
      color: "#bbb",
      background: "none",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      fontFamily: "Sans-Serif",
      fontSize: "0.8rem",
    },
    image: {
      width: "75%",
      cursor: "pointer",
      transitionProperty: "width, max-width, min-width, margin",
      transitionDuration: "0.1s",
      transitionTimingFunction: "ease",
      filter: "grayscale(0.9)",
    },
    imageChecked: {
      width: "100%",
      background: "transparent",
      filter: "none",
    },
    fieldset: {
      margin: "0px",
      border: `1px solid  #bbb`,
      display: "flex",
      flexWrap: "wrap",
    },
    radio: {
      position: "absolute",
      width: "1px",
      height: "1px",
      padding: "0",
      margin: "-1px",
      overflow: "hidden",
      clip: "rect(0,0,0,0)",
      border: "0",
    },
    wrapper: {
      flex: "1 1",
      aspectRatio: "1",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    label: {
      width: "100%",
      maxWidth: "5rem",
      height: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  })

type ClassKey =
  | "root"
  | "image"
  | "fieldset"
  | "radio"
  | "imageChecked"
  | "wrapper"
  | "label"

type IProps = {}

type PropsType = IProps &
  WithStyles<ClassKey> & { onEmojiSelected: (emoji: Emoji) => void }

const EmojiPicker: React.FC<PropsType> = (props: PropsType) => {
  const { classes, onEmojiSelected } = props

  const emojiOptions = emojis

  const [selectedEmoji, setSelectedEmoji] = useState<Emoji | undefined>(
    undefined,
  )

  useEffect(() => {
    if (selectedEmoji !== undefined) {
      onEmojiSelected(selectedEmoji)
    }
  }, [selectedEmoji, onEmojiSelected])

  return (
    <div className={classes.root}>
      <fieldset className={classes.fieldset}>
        <legend>Pick a feeling to send</legend>
        {emojiOptions
          .filter((emoji) => emoji.name.includes("positive"))
          .map((it, index) => {
            const isSelected: boolean = selectedEmoji === it
            const strIndex = JSON.stringify(index)
            const path = "/images/emoji/" + it.fileName
            return (
              <div key={"div" + strIndex} className={classes.wrapper}>
                <input
                  key={"input" + strIndex}
                  type="radio"
                  name="emoji-name"
                  className={classes.radio}
                  id={it.name}
                  value={it.name}
                ></input>
                <label
                  key={"label" + strIndex}
                  htmlFor={it.name}
                  onClick={() => setSelectedEmoji(it)}
                  className={classes.label}
                >
                  <img
                    key={"img" + strIndex}
                    src={path}
                    alt={it.name}
                    className={cslx(
                      classes.image,
                      isSelected && classes.imageChecked,
                    )}
                  ></img>
                </label>
              </div>
            )
          })}
      </fieldset>
    </div>
  )
}

export default withStyles(styles)(EmojiPicker)
