interface IConfig {
  activeDirectory: {
    appId: string
    redirectUri: string
  }
  tokenStorage: string
  api: string
}

export const config: IConfig = {
  activeDirectory: {
    appId: "556b30fe-1c8e-4b39-937a-f09fa3f00bd8",
    redirectUri: window.origin,
  },
  tokenStorage: "agam21",
  api: "https://b8ol1nvrk5.execute-api.eu-west-1.amazonaws.com",
}

export default config
