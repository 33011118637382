import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { updateProgress } from "../../common/usecases/UpdateProgress"
import { getGameProgress } from "../../games/usecases/getGameProgress"
import { GameOutcomeIdType } from "../../games/hooks/useOutcome"
import { IGamesSlice } from "../interfaces"

export type gameKey = "tetris" | "slotMachine"

const sliceInitialState: IGamesSlice = {
  tetris: {
    outcomeId: undefined,
    target: 100,
    best: 0,
  },
  slotMachine: {
    outcomeId: undefined,
    target: 3001,
    credits: 3000,
  },
}

export const refreshGameProgress = createAsyncThunk(
  "games/refreshProgress",
  async (game: gameKey) => {
    return await getGameProgress(game)
  },
)

export const progressGame = createAsyncThunk(
  "games/progress",
  async (payload: { game: gameKey; score: number }) => {
    return await updateProgress({
      mediaId: payload.game,
      progress: payload.score,
    })
  },
)

const customSlice = createSlice({
  name: "games",
  initialState: sliceInitialState,
  reducers: {
    setTetrisChallenge(
      state,
      action: PayloadAction<{
        outcomeId: GameOutcomeIdType | undefined
        target: number
      }>,
    ) {
      console.log(action.payload)
      state.tetris = { ...state.tetris, ...action.payload }
      return state
    },
    setSlotMachineChallenge(
      state,
      action: PayloadAction<{
        outcomeId: GameOutcomeIdType | undefined
        target: number
      }>,
    ) {
      state.slotMachine = { ...state.slotMachine, ...action.payload }
      return state
    },
  },
  extraReducers: (builder) => {
    builder.addCase(refreshGameProgress.fulfilled, (state, action) => {
      if (action.payload.mediaId === "tetris")
        state.tetris.best = action.payload.progress
      if (action.payload.mediaId === "slotMachine")
        state.slotMachine.credits = action.payload.progress
      return state
    })
    builder.addCase(progressGame.fulfilled, (state, action) => {
      if (action.payload.mediaId === "tetris")
        state.tetris.best = action.payload.progress
      else if (action.payload.mediaId === "slotMachine")
        state.slotMachine.credits = action.payload.progress
      return state
    })
  },
})

export const gameActions = customSlice.actions
export default customSlice.reducer
