import { useHistory } from "react-router"
import { useAppDispatch } from "../../redux/hooks"
import IEvent from "../entities/Event"
import IAction from "../entities/Action"
import { clearEnd } from "../usecases/CountDown"
import { gameActions, progressGame } from "../../redux/games/GameSlice"
import { progressAllVideos } from "../../redux/library/LibrarySlice"

type EventLibrary = {
  [mediaId: string]: {
    delay?: number
    at?: number
    event: IEvent | (() => IEvent)
  }[]
}

const useEvents = (
  videoId: string,
  componentActions: {
    resume: () => void
    schedule: (event: IEvent | (() => IEvent), delay: number) => void
  },
) => {
  const history = useHistory()
  const dispatch = useAppDispatch()

  const withTimeDown = (
    secondsLeft: number,
    event: IEvent,
    onTimeOut: () => void,
    first = true,
  ): IEvent => {
    const timeout = setTimeout(
      () => {
        if (secondsLeft > 0) {
          componentActions.schedule(
            withTimeDown(secondsLeft - 1, event, onTimeOut, false),
            0,
          )
        } else {
          onTimeOut()
        }
      },
      first ? 0 : 1e3,
    )
    const text = event.text + `\n (${secondsLeft} seconds)`
    const actions = [...event.actions].map((a) => ({
      label: a.label,
      onClick: () => {
        clearTimeout(timeout)
        a.onClick()
      },
    }))
    return { text, actions }
  }

  // const gameOver: IEvent = {
  //   text: "Game Over",
  //   actions: [
  //     {
  //       label: "Play some more",
  //       onClick: () => {
  //         history.push("/fast-track")
  //       },
  //     },
  //     {
  //       label: "Try experiential",
  //       onClick: () => {
  //         history.push("/")
  //       },
  //     },
  //   ],
  // }

  const tooMuchPressure: IEvent = {
    text: "We got you in the end.\n A little pressure goes a long way.\n\nBye bye.",
    actions: [
      // TODO: Do this with a screenshot so they are not actually sent out

      {
        label: "Continue",
        onClick: () =>
          window.location.replace("https://html.spec.whatwg.org/multipage/"),
      },
    ],
  }

  const wasteTimeAndComeBack = (next) =>
    withTimeDown(
      10,
      {
        text: "You are wasting precious time...",
        actions: [],
      },
      () => componentActions.schedule(next, 0),
    )

  const distractionEvents: IEvent[] = [
    {
      text: "Would you like to be called by our advisors regarding a holiday in your favourite location? We can dial you in right now! (1/4)",
      actions: [
        {
          label: "YES",
          onClick: () => wasteTimeAndComeBack(distractionEvents[0]),
        },
        {
          label: "NO",
          onClick: () => {
            componentActions.schedule(distractionEvents[1], 0)
          },
        },
      ],
    },
    {
      text: "Would you like to use a VIP fast track route to the silver bar? It should take only 10 years, ready to discuss this in our special team? (2/4)",
      actions: [
        {
          label: "NO",
          onClick: () => {
            componentActions.schedule(distractionEvents[2], 0)
          },
        },
        {
          label: "YES",
          onClick: () => wasteTimeAndComeBack(distractionEvents[1]),
        },
      ],
    },
    {
      text: "Would you like not to book a window seat in the office for the rest of your life? Should I not open the booking app right now? It will only take 2 days to fill in the schedule. (3/4)",
      actions: [
        {
          label: "NO",
          onClick: () => wasteTimeAndComeBack(distractionEvents[2]),
        },
        {
          label: "YES",
          onClick: () => {
            componentActions.schedule(distractionEvents[3], 0)
          },
        },
      ],
    },
    {
      text: "Would you like to be preselected to present a burst at a prestigious conference for the next 20 years? This is a coordination call starting now. Shall we dial in? There is plenty of time... (4/4)",
      actions: [
        {
          label: "NO",
          onClick: componentActions.resume,
        },
        {
          label: "YES",
          onClick: () => wasteTimeAndComeBack(distractionEvents[3]),
        },
      ],
    },
  ]

  const playSlotMachine = () => {
    dispatch(progressGame({ game: "slotMachine", score: 500 }))
      .then(() =>
        dispatch(
          gameActions.setSlotMachineChallenge({
            target: 501,
            outcomeId: "v3",
          }),
        ),
      )
      .then(() => history.push("/fast-track/slotmachine"))
  }

  const eventLibrary: EventLibrary = {
    v1: [
      {
        at: 40,
        event: {
          text: "Are you ready for this?",
          actions: [
            {
              label: "Yes",
              onClick: () => componentActions.resume(),
            },
            {
              label: "No",
              onClick: () => history.push("/experimental/"),
            },
          ],
        },
      },
      {
        at: 60 + 12,
        event: {
          title: "Terms and Conditions",
          text: "I am happy to spend the next year hand-editing html to get a chance to pay this game",
          textStyle: {
            fontFamily: "Wingdings",
          },
          actions: [
            {
              label: "yes",
              onClick: () => {
                const next: IEvent = {
                  text: "Let me clarify the Terms and Conditions: Do you agree to spend the next year hand-editing html to pay for this game?",
                  actions: [
                    {
                      label: "yes",
                      // TODO: Do this with a screenshot so they are not actually sent out
                      onClick: () =>
                        window.location.replace(
                          "https://html.spec.whatwg.org/multipage/",
                        ),
                    },
                    {
                      label: "no",
                      onClick: componentActions.resume,
                    },
                  ],
                }
                componentActions.schedule(next, 0)
              },
            },
            {
              label: "no",
              onClick: () => {
                const next: IEvent = {
                  text: "Well spotted! This a classic technique: deception",
                  actions: [
                    {
                      label: "Continue",
                      onClick: componentActions.resume,
                    },
                  ],
                }
                componentActions.schedule(next, 0)
              },
            },
          ],
        },
      },
    ],
    v2: [],
    v3: [
      {
        at: 38,
        event: {
          text: `Warning! You must accept the Terms and Conditions in order to proceed.\n
            This is your last chance to accept. And time is running out. Do you accept?`,
          actions: [
            {
              label: "OK",
              onClick: () => componentActions.schedule(tooMuchPressure, 0),
            },
            {
              label: "NO",
              onClick: () => {
                const dontAccept: IAction = {
                  label: "NO",
                  onClick: () => {
                    const next: IEvent = {
                      text: "Well done!\n As odd as it may seem, everybody using a computer has accepted some of the most unfriendly and obscure Terms and Conditions, even without reading them!",
                      actions: [
                        {
                          label: "Continue",
                          onClick: componentActions.resume,
                        },
                      ],
                    }
                    componentActions.schedule(next, 0)
                  },
                }
                componentActions.schedule(
                  withTimeDown(
                    30,
                    {
                      text: "We have given you more time to reflect. Do you accept the Terms and Conditions? Remember you have a limited time for the game... hurry up!",
                      actions: [
                        {
                          label: "OK",
                          onClick: () =>
                            componentActions.schedule(tooMuchPressure, 0),
                        },
                        dontAccept,
                      ],
                    },
                    dontAccept.onClick,
                  ),
                  0,
                )
              },
            },
          ],
        },
      },
      {
        at: 49,
        event: () =>
          withTimeDown(
            20,
            {
              text: "You have just been selected to unlock the whole game by gambling all your progress at the slot machines.\nYou have 20 seconds to make up your mind. Otherwise, we assume that you whish to go and try.",
              actions: [
                {
                  label: "GO",
                  onClick: playSlotMachine,
                },
                {
                  label: "CONTINUE",
                  onClick: componentActions.resume,
                },
              ],
            },
            playSlotMachine,
          ),
      },
    ],
    v4: [
      {
        at: 47,
        event: distractionEvents[0],
      },
      {
        at: 54,
        event: {
          text: "Would you like to learn more about the Dark UX or play Tetris?",
          actions: [
            {
              label: "Learn",
              onClick: componentActions.resume,
            },
            {
              label: "Play",
              onClick: () => {
                dispatch(
                  gameActions.setTetrisChallenge({
                    target: 100,
                    outcomeId: "v4",
                  }),
                )
                history.push("/fast-track/tetris")
              },
            },
          ],
        },
      },
    ],
    v5: [
      {
        at: 24,
        event: {
          text: "Think carefully, which one is the Right Choice?",
          actions: [
            {
              label: "Use public transport",
              onClick: () => {
                clearEnd()
                dispatch(progressAllVideos(0))
                componentActions.schedule(
                  {
                    text: "Good choice but you lost the game. There are more interesting things awaiting you.",
                    actions: [
                      {
                        label: "Back to course",
                        onClick: componentActions.resume,
                      },
                      {
                        label: "Go elsewhere",
                        onClick: () => history.push("/"),
                      },
                    ],
                  },
                  0,
                )
              },
            },
            {
              label: "Go vegan",
              onClick: () => {
                clearEnd()
                dispatch(progressAllVideos(0))
                componentActions.schedule(
                  {
                    text: "Good choice but you lost the game. There are more interesting things awaiting you.",
                    actions: [
                      {
                        label: "Back to course",
                        onClick: componentActions.resume,
                      },
                      {
                        label: "Go elsewhere",
                        onClick: () => history.push("/"),
                      },
                    ],
                  },
                  0,
                )
              },
            },
            {
              label: "Go to next video",
              onClick: () => {
                componentActions.schedule(
                  {
                    text: "Great choice for you, but worst for the world",
                    actions: [
                      {
                        label: "Continue",
                        onClick: componentActions.resume,
                      },
                    ],
                  },
                  0,
                )
              },
            },
          ],
        },
      },
    ],
    v6: [
      {
        at: 60 + 43,
        event: {
          text: "Did you get a chance to play these games before? Would you like to try now?",
          actions: [
            {
              label: "Tetris",
              onClick: () => {
                dispatch(
                  gameActions.setTetrisChallenge({
                    target: 200,
                    outcomeId: "v6",
                  }),
                )
                history.push("/fast-track/tetris")
              },
            },
            {
              label: "Slot Machine",
              onClick: () =>
                dispatch(progressGame({ game: "slotMachine", score: 500 }))
                  .then(() =>
                    dispatch(
                      gameActions.setSlotMachineChallenge({
                        target: 501,
                        outcomeId: "v6",
                      }),
                    ),
                  )
                  .then(() => history.push("/fast-track/slotmachine")),
            },
            {
              label: "No thanks",
              onClick: componentActions.resume,
            },
          ],
        },
      },
      {
        at: 149,
        event: {
          text: "Well done!",
          actions: [
            {
              label: "Play some more or leave feedback",
              onClick: () => {
                dispatch(
                  progressGame({ game: "slotMachine", score: 3000 }),
                ).then(() => componentActions.resume())
              },
            },
            {
              label: "Go elsewhere",
              onClick: () => {
                dispatch(
                  progressGame({ game: "slotMachine", score: 3000 }),
                ).then(() =>
                  dispatch(progressAllVideos(1)).then(() =>
                    history.push("/experimental/"),
                  ),
                )
              },
            },
          ],
        },
      },
    ],
  }
  return eventLibrary
}

export default useEvents
