import React, { useRef } from "react"
import {
  withStyles,
  WithStyles,
  createStyles,
  Theme,
  useTheme,
  Button,
} from "@material-ui/core"
import { useState } from "react"
import { useEffect } from "react"
import { getFeedback } from "../../common/usecases/GetFeedback"
import { Feedback } from "../../common/entities/Feedback"
import FeedbackItem, { maxFeedbackItemWidth } from "./FeedbackItem"

const styles = (theme: Theme) =>
  createStyles<ClassKey, {}>({
    root: {},
    header: {
      fontFamily: "Tourney",
      fontSize: "4rem",
      color: theme.palette.primary.main,
      zIndex: 10,
      margin: "5%",
      [theme.breakpoints.down("sm")]: {
        fontSize: "2.6rem",
      },
    },
    feedbackContainer: {
      position: "relative",
      bottom: 0,
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "space-around",
    },
    gradient: {
      overflow: "hidden",
      height: "80vh",
      "&-webkit-mask-image":
        "linear-gradient(to bottom, #3f87a600, white, white, #f69d3c00)",
      maskImage:
        "linear-gradient(to bottom, #3f87a600, white, white, #f69d3c00)",
    },
    ui: {
      position: "absolute",
      left: 0,
      top: 0,
      width: "100vw",
      height: "100%",
      padding: `5% calc(3rem + (100vw - ${theme.breakpoints.values.md}px) / 2)`,
      display: "flex",
      justifyContent: "space-evenly",
      alignItems: "flex-end",
      "& > *": {
        margin: "0 0.5rem",
        flex: "1 1",
      },
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "flex-end",
        "& > *": {
          alignText: "center",
          maxHeight: "3rem",
          minWidth: "80%",
          marginTop: "1rem",
        },
      },
    },
    ribbon: {
      position: "relative",
      width: "100%",
      minWidth: "500px",
      background: "#ff0",
      color: "#000",
      textAlign: "start",
      fontSize: "0.9rem",
      height: "2rem",
      fontWeight: "bold",
      fontFamily: "Roboto, Sans-Serif",
      lineHeight: "2rem",
      top: "-2rem",
      left: "calc(70% - 2rem)",
      letterSpacing: "1px",
      transformOrigin: "0 50%",
      transform: "rotate(45deg)",
      overflowX: "hidden",
      "-webkit-transform-origin": "0 50%",
      "-webkit-transform": "rotate(45deg)",
    },
  })

type ClassKey =
  | "root"
  | "header"
  | "feedbackContainer"
  | "gradient"
  | "ui"
  | "ribbon"
type PropsType = WithStyles<ClassKey>

const AgamLanding: React.FC<PropsType> = (props: PropsType) => {
  const { classes } = props
  const [feedbackList, setFeedbackList] = useState([] as Feedback[])
  const [style, setStyle] = useState<React.CSSProperties>()

  // Seconds that the animation loop lasts. If insuficient items are available,
  // they will be repeated to create enough feedback items
  const animationTime = 60
  // Average "rows" shown per second
  const animationSpeed = 0.2

  const containerEl = useRef<HTMLDivElement>(null)
  const theme = useTheme()

  useEffect(() => {
    // Every animationTime seconds, feedback is asked for
    const fetchFeedbackAndSetInitialPosition = () => {
      getFeedback().then((data) => setFeedbackList(data.Items))
      setStyle({ bottom: 0 })
    }
    fetchFeedbackAndSetInitialPosition()
    const interval = setInterval(
      fetchFeedbackAndSetInitialPosition,
      animationTime * 1e3,
    )
    return () => clearInterval(interval)
  }, [])

  useEffect(() => {
    // Animate flow everytime there's new feedback
    const height = containerEl.current?.offsetHeight
    if (height) {
      setStyle({
        transition: `bottom ${animationTime}s linear`,
        bottom: height,
      })
    }
    console.log(feedbackList)
  }, [containerEl, feedbackList])

  const randomBetween = (a: number, b: number) => {
    return Math.min(a, b) + Math.random() * (Math.max(a, b) - Math.min(a, b))
  }

  const randomizeFeedbackItems = (feedback: Feedback[]) => {
    const nCols = Math.floor(
      Math.min(window.screen.width, theme.breakpoints.values.md) /
        maxFeedbackItemWidth,
    )
    const targetLength = animationSpeed * nCols * animationTime

    if (!feedback.length) return []
    feedback = []
      .concat(
        ...Array(Math.ceil(targetLength / feedback.length)).fill(feedbackList),
      )
      .slice(0, targetLength)
    return feedback.map((fb, i) => (
      <div
        key={i}
        style={{
          margin: `${randomBetween(0, 10)}% ${randomBetween(0, 5)}%`,
        }}
      >
        <FeedbackItem feedback={fb} />
      </div>
    ))
  }

  useEffect(() => {
    document.title = `AGAM21 | Experimental`
  })

  return (
    <div>
      <div className={classes.ribbon}>
        EXPERIMENTAL! WARNING! EXPERIMIENTAL! WARNING! EXPERIMIENTAL! WARNING!
      </div>
      <h1 className={classes.header}>Experimental</h1>
      <div className={classes.gradient}>
        <div
          ref={containerEl}
          className={classes.feedbackContainer}
          style={style}
        >
          {randomizeFeedbackItems(feedbackList)}
        </div>
      </div>
      <div className={classes.ui}>
        <Button
          variant="outlined"
          color="primary"
          size="large"
          href="/fast-track"
        >
          <span
            style={{
              fontFamily: "Digitek",
              lineHeight: "4rem",
              fontSize: "1.4rem",
            }}
          >
            fast track
          </span>
        </Button>
        <Button
          variant="outlined"
          color="primary"
          size="large"
          href="/experiential"
        >
          <span style={{ fontFamily: "Cooper Black", lineHeight: "4rem" }}>
            experiential
          </span>
        </Button>
      </div>
    </div>
  )
}

export default withStyles(styles)(AgamLanding)
