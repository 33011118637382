import { IBook, IVideo } from "../interfaces"
import { shuffle } from "./shuffle"

import ambients from "./ambients"

const sanitizedAmbients = [
  ambients[0],
  ambients[1],
  ambients[2],
  ambients[3],
  ambients[4],
  ambients[6],
  ambients[7],
  ambients[8],
  ambients[13],
  ambients[15],
  ambients[16],
  ambients[17],
]
const shuffledAmbients = shuffle([...sanitizedAmbients])
const placeholder =
  "https://" +
  document.location.hostname +
  "/out/v1/f7e1b2d675d745078682311477cb6647/edc109de588040c897f881ef0f45784b/a3e52c84444e4aa6a2101a1114f86873/index.m3u8"

export const bookClubTrailer: IVideo = {
  id: "trailer",
  title: "Welcome to the Book Club",
  author: "Start your journey here",
  thumbnail:
    "https://" +
    document.location.hostname +
    "/8c2e0e1a-7fdf-406d-a2ed-cbeab630a866/thumbnails/bookclub_intro_video-3_thumb.0000000.jpg",
  src:
    "https://" +
    document.location.hostname +
    "/out/v1/66076bf67b2f4a0e8cb29120c2715ad5/edc109de588040c897f881ef0f45784b/a3e52c84444e4aa6a2101a1114f86873/index.m3u8",
  progress: 0,
}

export const books: IBook[] = [
  {
    id: "b1",
    title: "Design As Art",
    author: "Bruno Munari",
    src: "https://" + document.location.hostname + "/audio/F1.m3u8",
    thumbnailSrc:
      "https://" +
      document.location.hostname +
      "/13595bf8-e4ee-493e-bd2e-2b873ec60706/thumbnails/video_thumb.0000002.jpg",
    burstSrc:
      new Date() < new Date(Date.UTC(2021, 8, 22, 15, 0, 0))
        ? placeholder
        : "https://" +
          document.location.hostname +
          "/out/v1/8f2b22e7d5f1451fb6e49e9f3fc1743a/edc109de588040c897f881ef0f45784b/a3e52c84444e4aa6a2101a1114f86873/index.m3u8",
    ambient: shuffledAmbients[0 % shuffledAmbients.length],
    progress: 0,
    volume: 1,
    libraryPath:
      "mailto:arup.library@arup.com?subject=Design As Art&subject=Book request: Design As Art by Bruno Munari",
  },
  {
    id: "b2",
    title: "Wabi-Sabi: art of imperfections",
    author: "Osami Nishimura",
    src: "https://" + document.location.hostname + "/audio/F2.m3u8",
    thumbnailSrc:
      "https://" +
      document.location.hostname +
      "/13595bf8-e4ee-493e-bd2e-2b873ec60706/thumbnails/video_thumb.0000002.jpg",
    burstSrc:
      new Date() < new Date(Date.UTC(2021, 8, 22, 15, 0, 0))
        ? placeholder
        : "https://" +
          document.location.hostname +
          "/out/v1/8f2b22e7d5f1451fb6e49e9f3fc1743a/edc109de588040c897f881ef0f45784b/a3e52c84444e4aa6a2101a1114f86873/index.m3u8",
    ambient: shuffledAmbients[1 % shuffledAmbients.length],
    progress: 0,
    volume: 1,
    libraryPath:
      "mailto:arup.library@arup.com?subject=Wabi-Sabi: art of imperfections&subject=Book request: Wabi-Sabi: art of imperfections by Osami Nishimura",
  },
  {
    id: "b3",
    title: "Theory and Design: the First Machine Age",
    author: "Reyner Banham",
    src: "https://" + document.location.hostname + "/audio/F3.m3u8",
    thumbnailSrc:
      "https://" +
      document.location.hostname +
      "/13595bf8-e4ee-493e-bd2e-2b873ec60706/thumbnails/video_thumb.0000002.jpg",
    burstSrc:
      new Date() < new Date(Date.UTC(2021, 8, 22, 15, 0, 0))
        ? placeholder
        : "https://" +
          document.location.hostname +
          "/out/v1/8f2b22e7d5f1451fb6e49e9f3fc1743a/edc109de588040c897f881ef0f45784b/a3e52c84444e4aa6a2101a1114f86873/index.m3u8",
    ambient: shuffledAmbients[2 % shuffledAmbients.length],
    progress: 0,
    volume: 1,
    libraryPath:
      "mailto:arup.library@arup.com?subject=Theory and Design: the First Machine Age&subject=Book request: Theory and Design: the First Machine Age by Reyner Banham",
  },
  {
    id: "b4",
    title: "Towns and Buildings",
    author: "Steen Eiler Rasmussen",
    src: "https://" + document.location.hostname + "/audio/F4.m3u8",
    thumbnailSrc:
      "https://" +
      document.location.hostname +
      "/13595bf8-e4ee-493e-bd2e-2b873ec60706/thumbnails/video_thumb.0000002.jpg",
    burstSrc:
      new Date() < new Date(Date.UTC(2021, 8, 22, 15, 0, 0))
        ? placeholder
        : "https://" +
          document.location.hostname +
          "/out/v1/8f2b22e7d5f1451fb6e49e9f3fc1743a/edc109de588040c897f881ef0f45784b/a3e52c84444e4aa6a2101a1114f86873/index.m3u8",
    ambient: shuffledAmbients[3 % shuffledAmbients.length],
    progress: 0,
    volume: 1,
    libraryPath:
      "mailto:arup.library@arup.com?subject=Towns and Buildings&subject=Book request: Towns and Buildings by Steen Eiler Rasmussen",
  },
  {
    id: "b5",
    title: "Programs and Manifestoes on 20th-Century Architecture",
    author: "Ulrich Conrad",
    src: "https://" + document.location.hostname + "/audio/F5.m3u8",
    thumbnailSrc:
      "https://" +
      document.location.hostname +
      "/13595bf8-e4ee-493e-bd2e-2b873ec60706/thumbnails/video_thumb.0000002.jpg",
    burstSrc:
      new Date() < new Date(Date.UTC(2021, 8, 22, 15, 0, 0))
        ? placeholder
        : "https://" +
          document.location.hostname +
          "/out/v1/8f2b22e7d5f1451fb6e49e9f3fc1743a/edc109de588040c897f881ef0f45784b/a3e52c84444e4aa6a2101a1114f86873/index.m3u8",
    ambient: shuffledAmbients[4 % shuffledAmbients.length],
    progress: 0,
    volume: 1,
    libraryPath:
      "mailto:arup.library@arup.com?subject=Programs and Manifestoes on 20th-Century Architecture&subject=Book request: Programs and Manifestoes on 20th-Century Architecture by Ulrich Conrad",
  },
  {
    id: "b6",
    title: "The Three Box Solution",
    author: "Vijay Govindarajan",
    src: "https://" + document.location.hostname + "/audio/F6.m3u8",
    thumbnailSrc:
      "https://" +
      document.location.hostname +
      "/13595bf8-e4ee-493e-bd2e-2b873ec60706/thumbnails/video_thumb.0000002.jpg",
    burstSrc:
      new Date() < new Date(Date.UTC(2021, 8, 22, 15, 0, 0))
        ? placeholder
        : "https://" +
          document.location.hostname +
          "/out/v1/8f2b22e7d5f1451fb6e49e9f3fc1743a/edc109de588040c897f881ef0f45784b/a3e52c84444e4aa6a2101a1114f86873/index.m3u8",
    ambient: shuffledAmbients[5 % shuffledAmbients.length],
    progress: 0,
    volume: 1,
    libraryPath:
      "mailto:arup.library@arup.com?subject=The Three Box Solution&subject=Book request: The Three Box Solution by Vijay Govindarajan",
  },
  {
    id: "b7",
    title: "Design is storytelling",
    author: "Ellen Lupton",
    src: "https://" + document.location.hostname + "/audio/F7.m3u8",
    thumbnailSrc:
      "https://" +
      document.location.hostname +
      "/13595bf8-e4ee-493e-bd2e-2b873ec60706/thumbnails/video_thumb.0000002.jpg",
    burstSrc:
      new Date() < new Date(Date.UTC(2021, 8, 22, 15, 0, 0))
        ? placeholder
        : "https://" +
          document.location.hostname +
          "/out/v1/8f2b22e7d5f1451fb6e49e9f3fc1743a/edc109de588040c897f881ef0f45784b/a3e52c84444e4aa6a2101a1114f86873/index.m3u8",
    ambient: shuffledAmbients[6 % shuffledAmbients.length],
    progress: 0,
    volume: 1,
    libraryPath:
      "mailto:arup.library@arup.com?subject=Design is storytelling&subject=Book request: Design is storytelling by Ellen Lupton",
  },
  {
    id: "b8",
    title: "Habit-Forming Products",
    author: "Nir Eyal",
    src: "https://" + document.location.hostname + "/audio/F8.m3u8",
    thumbnailSrc:
      "https://" +
      document.location.hostname +
      "/13595bf8-e4ee-493e-bd2e-2b873ec60706/thumbnails/video_thumb.0000002.jpg",
    burstSrc:
      new Date() < new Date(Date.UTC(2021, 8, 22, 15, 0, 0))
        ? placeholder
        : "https://" +
          document.location.hostname +
          "/out/v1/8f2b22e7d5f1451fb6e49e9f3fc1743a/edc109de588040c897f881ef0f45784b/a3e52c84444e4aa6a2101a1114f86873/index.m3u8",
    ambient: shuffledAmbients[7 % shuffledAmbients.length],
    progress: 0,
    volume: 1,
    libraryPath:
      "mailto:arup.library@arup.com?subject=Habit-Forming Products&subject=Book request: Habit-Forming Products by Nir Eyal",
  },
  {
    id: "b9",
    title: "The Lean Startup",
    author: "Eric Ries",
    src: "https://" + document.location.hostname + "/audio/F9.m3u8",
    thumbnailSrc:
      "https://" +
      document.location.hostname +
      "/13595bf8-e4ee-493e-bd2e-2b873ec60706/thumbnails/video_thumb.0000002.jpg",
    burstSrc:
      new Date() < new Date(Date.UTC(2021, 8, 22, 15, 0, 0))
        ? placeholder
        : "https://" +
          document.location.hostname +
          "/out/v1/8f2b22e7d5f1451fb6e49e9f3fc1743a/edc109de588040c897f881ef0f45784b/a3e52c84444e4aa6a2101a1114f86873/index.m3u8",
    ambient: shuffledAmbients[8 % shuffledAmbients.length],
    progress: 0,
    volume: 1,
    libraryPath:
      "mailto:arup.library@arup.com?subject=The Lean Startup&subject=Book request: The Lean Startup by Eric Ries",
  },
  {
    id: "b10",
    title: "Zero to One: Notes on Startups",
    author: "Peter Thiel",
    src: "https://" + document.location.hostname + "/audio/F10.m3u8",
    thumbnailSrc:
      "https://" +
      document.location.hostname +
      "/13595bf8-e4ee-493e-bd2e-2b873ec60706/thumbnails/video_thumb.0000002.jpg",
    burstSrc:
      new Date() < new Date(Date.UTC(2021, 8, 22, 15, 0, 0))
        ? placeholder
        : "https://" +
          document.location.hostname +
          "/out/v1/8f2b22e7d5f1451fb6e49e9f3fc1743a/edc109de588040c897f881ef0f45784b/a3e52c84444e4aa6a2101a1114f86873/index.m3u8",
    ambient: shuffledAmbients[9 % shuffledAmbients.length],
    progress: 0,
    volume: 1,
    libraryPath:
      "mailto:arup.library@arup.com?subject=Zero to One: Notes on Startups&subject=Book request: Zero to One: Notes on Startups by Peter Thiel",
  },
  {
    id: "b11",
    title: "The innovator’s dilemma",
    author: "Clayton Christensen",
    src: "https://" + document.location.hostname + "/audio/F11.m3u8",
    thumbnailSrc:
      "https://" +
      document.location.hostname +
      "/13595bf8-e4ee-493e-bd2e-2b873ec60706/thumbnails/video_thumb.0000002.jpg",
    burstSrc:
      new Date() < new Date(Date.UTC(2021, 8, 22, 15, 0, 0))
        ? placeholder
        : "https://" +
          document.location.hostname +
          "/out/v1/8f2b22e7d5f1451fb6e49e9f3fc1743a/edc109de588040c897f881ef0f45784b/a3e52c84444e4aa6a2101a1114f86873/index.m3u8",
    ambient: shuffledAmbients[10 % shuffledAmbients.length],
    progress: 0,
    volume: 1,
    libraryPath:
      "mailto:arup.library@arup.com?subject=The innovator’s dilemma&subject=Book request: The innovator’s dilemma by Clayton Christensen",
  },
  {
    id: "b12",
    title: "Range",
    author: "David Epstein",
    src: "https://" + document.location.hostname + "/audio/F12.m3u8",
    thumbnailSrc:
      "https://" +
      document.location.hostname +
      "/13595bf8-e4ee-493e-bd2e-2b873ec60706/thumbnails/video_thumb.0000002.jpg",
    burstSrc:
      new Date() < new Date(Date.UTC(2021, 8, 22, 15, 0, 0))
        ? placeholder
        : "https://" +
          document.location.hostname +
          "/out/v1/8f2b22e7d5f1451fb6e49e9f3fc1743a/edc109de588040c897f881ef0f45784b/a3e52c84444e4aa6a2101a1114f86873/index.m3u8",
    ambient: shuffledAmbients[11 % shuffledAmbients.length],
    progress: 0,
    volume: 1,
    libraryPath:
      "mailto:arup.library@arup.com?subject=Range&subject=Book request: Range by David Epstein",
  },
  {
    id: "b13",
    title: "The Other Side of Innovation",
    author: "Vijay Govindarajan, Chris Trimble",
    src: "https://" + document.location.hostname + "/audio/F13.m3u8",
    thumbnailSrc:
      "https://" +
      document.location.hostname +
      "/13595bf8-e4ee-493e-bd2e-2b873ec60706/thumbnails/video_thumb.0000002.jpg",
    burstSrc:
      new Date() < new Date(Date.UTC(2021, 8, 22, 15, 0, 0))
        ? placeholder
        : "https://" +
          document.location.hostname +
          "/out/v1/8f2b22e7d5f1451fb6e49e9f3fc1743a/edc109de588040c897f881ef0f45784b/a3e52c84444e4aa6a2101a1114f86873/index.m3u8",
    ambient: shuffledAmbients[12 % shuffledAmbients.length],
    progress: 0,
    volume: 1,
    libraryPath:
      "mailto:arup.library@arup.com?subject=The Other Side of Innovation&subject=Book request: The Other Side of Innovation by Vijay Govindarajan, Chris Trimble",
  },
  {
    id: "b14",
    title: "Data Feminism",
    author: "Catherine D`ignazio, Lauren F. Klein",
    src: "https://" + document.location.hostname + "/audio/F14.m3u8",
    thumbnailSrc:
      "https://" +
      document.location.hostname +
      "/13595bf8-e4ee-493e-bd2e-2b873ec60706/thumbnails/video_thumb.0000002.jpg",
    burstSrc:
      new Date() < new Date(Date.UTC(2021, 8, 22, 15, 0, 0))
        ? placeholder
        : "https://" +
          document.location.hostname +
          "/out/v1/8f2b22e7d5f1451fb6e49e9f3fc1743a/edc109de588040c897f881ef0f45784b/a3e52c84444e4aa6a2101a1114f86873/index.m3u8",
    ambient: shuffledAmbients[13 % shuffledAmbients.length],
    progress: 0,
    volume: 1,
    libraryPath:
      "mailto:arup.library@arup.com?subject=Data Feminism&subject=Book request: Data Feminism by Catherine D`ignazio, Lauren F. Klein",
  },
  {
    id: "b15",
    title: "The Alphabet and the Algorithm",
    author: "Mario Carpo",
    src: "https://" + document.location.hostname + "/audio/F15.m3u8",
    thumbnailSrc:
      "https://" +
      document.location.hostname +
      "/13595bf8-e4ee-493e-bd2e-2b873ec60706/thumbnails/video_thumb.0000002.jpg",
    burstSrc:
      new Date() < new Date(Date.UTC(2021, 8, 22, 15, 0, 0))
        ? placeholder
        : "https://" +
          document.location.hostname +
          "/out/v1/8f2b22e7d5f1451fb6e49e9f3fc1743a/edc109de588040c897f881ef0f45784b/a3e52c84444e4aa6a2101a1114f86873/index.m3u8",
    ambient: shuffledAmbients[14 % shuffledAmbients.length],
    progress: 0,
    volume: 1,
    libraryPath:
      "mailto:arup.library@arup.com?subject=The Alphabet and the Algorithm&subject=Book request: The Alphabet and the Algorithm by Mario Carpo",
  },
  {
    id: "b16",
    title: "Competing in the Age of AI: Strategy and Leadership",
    author: "Marco Iansiti and Karim R. Lakhani",
    src: "https://" + document.location.hostname + "/audio/F16.m3u8",
    thumbnailSrc:
      "https://" +
      document.location.hostname +
      "/13595bf8-e4ee-493e-bd2e-2b873ec60706/thumbnails/video_thumb.0000002.jpg",
    burstSrc:
      new Date() < new Date(Date.UTC(2021, 8, 22, 15, 0, 0))
        ? placeholder
        : "https://" +
          document.location.hostname +
          "/out/v1/8f2b22e7d5f1451fb6e49e9f3fc1743a/edc109de588040c897f881ef0f45784b/a3e52c84444e4aa6a2101a1114f86873/index.m3u8",
    ambient: shuffledAmbients[15 % shuffledAmbients.length],
    progress: 0,
    volume: 1,
    libraryPath:
      "mailto:arup.library@arup.com?subject=Competing in the Age of AI: Strategy and Leadership&subject=Book request: Competing in the Age of AI: Strategy and Leadership by Marco Iansiti and Karim R. Lakhani",
  },
  {
    id: "b17",
    title: "Do Androids Dream of Electric Sheep?",
    author: "Philip K. Dick",
    src: "https://" + document.location.hostname + "/audio/F17.m3u8",
    thumbnailSrc:
      "https://" +
      document.location.hostname +
      "/13595bf8-e4ee-493e-bd2e-2b873ec60706/thumbnails/video_thumb.0000002.jpg",
    burstSrc:
      new Date() < new Date(Date.UTC(2021, 8, 22, 15, 0, 0))
        ? placeholder
        : "https://" +
          document.location.hostname +
          "/out/v1/8f2b22e7d5f1451fb6e49e9f3fc1743a/edc109de588040c897f881ef0f45784b/a3e52c84444e4aa6a2101a1114f86873/index.m3u8",
    ambient: shuffledAmbients[16 % shuffledAmbients.length],
    progress: 0,
    volume: 1,
    libraryPath:
      "mailto:arup.library@arup.com?subject=Do Androids Dream of Electric Sheep?&subject=Book request: Do Androids Dream of Electric Sheep? by Philip K. Dick",
  },
  {
    id: "b18",
    title: "The Mind's I",
    author: "Daniel Dennett and Douglas Hofstadter",
    src: "https://" + document.location.hostname + "/audio/F18.m3u8",
    thumbnailSrc:
      "https://" +
      document.location.hostname +
      "/13595bf8-e4ee-493e-bd2e-2b873ec60706/thumbnails/video_thumb.0000002.jpg",
    burstSrc:
      new Date() < new Date(Date.UTC(2021, 8, 22, 15, 0, 0))
        ? placeholder
        : "https://" +
          document.location.hostname +
          "/out/v1/8f2b22e7d5f1451fb6e49e9f3fc1743a/edc109de588040c897f881ef0f45784b/a3e52c84444e4aa6a2101a1114f86873/index.m3u8",
    ambient: shuffledAmbients[17 % shuffledAmbients.length],
    progress: 0,
    volume: 1,
    libraryPath:
      "mailto:arup.library@arup.com?subject=The Mind's I&subject=Book request: The Mind's I by Daniel Dennett and Douglas Hofstadter",
  },
  {
    id: "b19",
    title: "The Power of Experiments",
    author: "Michael Luca and Max H. Bazerman",
    src: "https://" + document.location.hostname + "/audio/F19.m3u8",
    thumbnailSrc:
      "https://" +
      document.location.hostname +
      "/13595bf8-e4ee-493e-bd2e-2b873ec60706/thumbnails/video_thumb.0000002.jpg",
    burstSrc:
      new Date() < new Date(Date.UTC(2021, 8, 22, 15, 0, 0))
        ? placeholder
        : "https://" +
          document.location.hostname +
          "/out/v1/8f2b22e7d5f1451fb6e49e9f3fc1743a/edc109de588040c897f881ef0f45784b/a3e52c84444e4aa6a2101a1114f86873/index.m3u8",
    ambient: shuffledAmbients[18 % shuffledAmbients.length],
    progress: 0,
    volume: 1,
    libraryPath:
      "mailto:arup.library@arup.com?subject=The Power of Experiments&subject=Book request: The Power of Experiments by Michael Luca and Max H. Bazerman",
  },
  {
    id: "b20",
    title: "Thinking, Fast and Slow",
    author: "Daniel Kahneman",
    src: "https://" + document.location.hostname + "/audio/F20.m3u8",
    thumbnailSrc:
      "https://" +
      document.location.hostname +
      "/13595bf8-e4ee-493e-bd2e-2b873ec60706/thumbnails/video_thumb.0000002.jpg",
    burstSrc:
      new Date() < new Date(Date.UTC(2021, 8, 22, 15, 0, 0))
        ? placeholder
        : "https://" +
          document.location.hostname +
          "/out/v1/8f2b22e7d5f1451fb6e49e9f3fc1743a/edc109de588040c897f881ef0f45784b/a3e52c84444e4aa6a2101a1114f86873/index.m3u8",
    ambient: shuffledAmbients[19 % shuffledAmbients.length],
    progress: 0,
    volume: 1,
    libraryPath:
      "mailto:arup.library@arup.com?subject=Thinking, Fast and Slow&subject=Book request: Thinking, Fast and Slow by Daniel Kahneman",
  },
  {
    id: "b21",
    title: "Innovation Lab Excellence",
    author: "Richard Turrin",
    src: "https://" + document.location.hostname + "/audio/F21.m3u8",
    thumbnailSrc:
      "https://" +
      document.location.hostname +
      "/13595bf8-e4ee-493e-bd2e-2b873ec60706/thumbnails/video_thumb.0000002.jpg",
    burstSrc:
      new Date() < new Date(Date.UTC(2021, 8, 22, 15, 0, 0))
        ? placeholder
        : "https://" +
          document.location.hostname +
          "/out/v1/8f2b22e7d5f1451fb6e49e9f3fc1743a/edc109de588040c897f881ef0f45784b/a3e52c84444e4aa6a2101a1114f86873/index.m3u8",
    ambient: shuffledAmbients[20 % shuffledAmbients.length],
    progress: 0,
    volume: 1,
    libraryPath:
      "mailto:arup.library@arup.com?subject=Innovation Lab Excellence&subject=Book request: Innovation Lab Excellence by Richard Turrin",
  },
  {
    id: "b22",
    title: "Reality is Broken: Why Games Make Us Better",
    author: "Jane McGonigal",
    src: "https://" + document.location.hostname + "/audio/F22.m3u8",
    thumbnailSrc:
      "https://" +
      document.location.hostname +
      "/13595bf8-e4ee-493e-bd2e-2b873ec60706/thumbnails/video_thumb.0000002.jpg",
    burstSrc:
      new Date() < new Date(Date.UTC(2021, 8, 22, 15, 0, 0))
        ? placeholder
        : "https://" +
          document.location.hostname +
          "/out/v1/8f2b22e7d5f1451fb6e49e9f3fc1743a/edc109de588040c897f881ef0f45784b/a3e52c84444e4aa6a2101a1114f86873/index.m3u8",
    ambient: shuffledAmbients[21 % shuffledAmbients.length],
    progress: 0,
    volume: 1,
    libraryPath:
      "mailto:arup.library@arup.com?subject=Reality is Broken: Why Games Make Us Better&subject=Book request: Reality is Broken: Why Games Make Us Better by Jane McGonigal",
  },
  {
    id: "b23",
    title: "Artificial Life",
    author: "Steven Levy",
    src: "https://" + document.location.hostname + "/audio/F23.m3u8",
    thumbnailSrc:
      "https://" +
      document.location.hostname +
      "/13595bf8-e4ee-493e-bd2e-2b873ec60706/thumbnails/video_thumb.0000002.jpg",
    burstSrc:
      new Date() < new Date(Date.UTC(2021, 8, 22, 15, 0, 0))
        ? placeholder
        : "https://" +
          document.location.hostname +
          "/out/v1/8f2b22e7d5f1451fb6e49e9f3fc1743a/edc109de588040c897f881ef0f45784b/a3e52c84444e4aa6a2101a1114f86873/index.m3u8",
    ambient: shuffledAmbients[22 % shuffledAmbients.length],
    progress: 0,
    volume: 1,
    libraryPath:
      "mailto:arup.library@arup.com?subject=Artificial Life&subject=Book request: Artificial Life by Steven Levy",
  },
  {
    id: "b24",
    title: "Chaos: Making a New Science",
    author: "James Gleick",
    src: "https://" + document.location.hostname + "/audio/F24.m3u8",
    thumbnailSrc:
      "https://" +
      document.location.hostname +
      "/13595bf8-e4ee-493e-bd2e-2b873ec60706/thumbnails/video_thumb.0000002.jpg",
    burstSrc:
      new Date() < new Date(Date.UTC(2021, 8, 22, 15, 0, 0))
        ? placeholder
        : "https://" +
          document.location.hostname +
          "/out/v1/8f2b22e7d5f1451fb6e49e9f3fc1743a/edc109de588040c897f881ef0f45784b/a3e52c84444e4aa6a2101a1114f86873/index.m3u8",
    ambient: shuffledAmbients[23 % shuffledAmbients.length],
    progress: 0,
    volume: 1,
    libraryPath:
      "mailto:arup.library@arup.com?subject=Chaos: Making a New Science&subject=Book request: Chaos: Making a New Science by James Gleick",
  },
  {
    id: "b25",
    title: "The Signal and the Noise",
    author: "Nate Silver",
    src: "https://" + document.location.hostname + "/audio/F25.m3u8",
    thumbnailSrc:
      "https://" +
      document.location.hostname +
      "/13595bf8-e4ee-493e-bd2e-2b873ec60706/thumbnails/video_thumb.0000002.jpg",
    burstSrc:
      new Date() < new Date(Date.UTC(2021, 8, 22, 15, 0, 0))
        ? placeholder
        : "https://" +
          document.location.hostname +
          "/out/v1/8f2b22e7d5f1451fb6e49e9f3fc1743a/edc109de588040c897f881ef0f45784b/a3e52c84444e4aa6a2101a1114f86873/index.m3u8",
    ambient: shuffledAmbients[24 % shuffledAmbients.length],
    progress: 0,
    volume: 1,
    libraryPath:
      "mailto:arup.library@arup.com?subject=The Signal and the Noise&subject=Book request: The Signal and the Noise by Nate Silver",
  },
  {
    id: "b26",
    title: "The Quark and The Jaguar",
    author: "Murray Gell-Mann",
    src: "https://" + document.location.hostname + "/audio/F26.m3u8",
    thumbnailSrc:
      "https://" +
      document.location.hostname +
      "/13595bf8-e4ee-493e-bd2e-2b873ec60706/thumbnails/video_thumb.0000002.jpg",
    burstSrc:
      new Date() < new Date(Date.UTC(2021, 8, 22, 15, 0, 0))
        ? placeholder
        : "https://" +
          document.location.hostname +
          "/out/v1/8f2b22e7d5f1451fb6e49e9f3fc1743a/edc109de588040c897f881ef0f45784b/a3e52c84444e4aa6a2101a1114f86873/index.m3u8",
    ambient: shuffledAmbients[25 % shuffledAmbients.length],
    progress: 0,
    volume: 1,
    libraryPath:
      "mailto:arup.library@arup.com?subject=The Quark and The Jaguar&subject=Book request: The Quark and The Jaguar by Murray Gell-Mann",
  },
]
